import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import Step from 'auth/components/Step';
import { Progress3Icon } from 'auth/icons';
import { FAB } from 'shared/buttons';
import { useBreakpoint } from 'shared/components/breakpoint';
import useAnalytics from 'shared/components/useAnalytics';
import { H1, H3 } from 'shared/typography';

const InfoAdded = () => {
  const bp = useBreakpoint();
  const navigate = useNavigate();
  useAnalytics({ name: 'your-part-is-complete' });

  const moveForward = () => navigate('/auth/personal/partner-info');

  return (
    <Layout dark height="100vh">
      {!bp.large && <Progress3Icon position="absolute" right={0} top={3} />}
      <Flex justify="space-between" align="center">
        <H1 color="shared">Nicely done! Your part is complete.</H1>
        {bp.large && <Progress3Icon />}
      </Flex>

      <Box h={10} />
      <H3 color="iceberg">Tell us about your partner to get them onboard.</H3>
      <Box h={15} />

      <Step checked>Secure your account</Step>
      <Box h={10} />
      <Step checked>Add your information</Step>
      <Box h={10} />
      <Step>Partner’s Information</Step>
      <Box h={10} />
      <Step>Invite your partner</Step>
      <Box h={10} />
      <Step>Submit your application</Step>
      <Box h={10} />
      <Step>Add funds</Step>
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB onClick={moveForward} autoFocus />
        </Flex>
      </Box>
    </Layout>
  );
};

export default InfoAdded;
