import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import { signIn } from 'auth/lib/authentication';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { pixelTrack } from 'shared/components/usePixel';

import { TextInput } from 'shared/inputs';
import { H1, H3, Legal } from 'shared/typography';

function ConfirmInviteForm() {
  const navigate = useNavigate();
  const location = useLocation();
  useAnalytics({ name: 'create-account' });

  const search = location.search || '';
  const { claimToken } = querystring.parse(search);

  const { checkingInvite, invite = {} } = useStoreState(state => state.auth);
  const { getInvite, setToken, checkEmail, sendCode, validateCode } = useStoreActions(
    actions => actions.auth,
  );

  const [code, setCode] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    const invitePresent = Object.keys(invite).length > 0;
    if (!invitePresent) {
      claimToken && setToken(claimToken);
      getInvite({
        callback: inv => {
          if (inv && Object.keys(inv).length > 0) {
            sendCode();
          } else {
            navigate('/go-mobile');
          }
        },
      });
    } else {
      checkEmail({ variables: { email: invite.email } });
    }
  }, [invite, checkEmail, setToken, getInvite, sendCode, claimToken, navigate]);

  const handleCodeChange = value => {
    if (error !== {}) setError({});
    setCode(value);
  };

  const resendCode = e => {
    e.preventDefault();
    setSent(true);
    sendCode();
  };

  const submit = () => {
    setSaving(true);
    validateCode({
      variables: { claimToken, verificationCode: code },
      callback: ({ authToken, tempPass }) => {
        pixelTrack({ event: 'P2Claimed', custom: true });
        setSaving(false);
        if (tempPass) {
          signIn(authToken, true);
          navigate('/auth/invited/welcome/create');
        } else {
          navigate('/auth/invited/welcome/pfm-login');
        }
      },
      onError: err => {
        setSaving(false);
        setError(err);
      },
    });
  };

  return (
    invite &&
    Object.keys(invite).length > 0 &&
    !checkingInvite && (
      <Layout>
        <H1>Let's link you to {invite?.senderFirstName}</H1>
        <Box h={10} />
        <H3>
          We just texted you a code to the number {invite?.senderFirstName} gave us (**
          {invite?.phoneNumberDigits}).
        </H3>
        <Box h={15} />
        <TextInput
          id="secretCode"
          type="password"
          label="Top secret code"
          autoComplete="one-time-code"
          value={code}
          error={getErrorMsg(error)}
          onChange={handleCodeChange}
          onEnter={submit}
          placeholder="Your secret code"
        />
        <Box h={26} />
        <Box position="absolute" right={0} top={[80, 78]}>
          <Flex justify="flex-end">
            <FAB
              isLoading={saving}
              isDisabled={(code.length || '') < 6}
              onClick={submit}
            />
          </Flex>
          <Box h={8} />
          {!sent && (
            <Flex justify="flex-end">
              <button type="button" style={buttonStyle} onClick={resendCode}>
                <Legal as="i">Resend verification code.</Legal>
              </button>
            </Flex>
          )}
        </Box>
        <Box h={20} />
      </Layout>
    )
  );
}

const getErrorMsg = error => {
  if (error?.verificationCode) return "This code isn't correct";
  if (error?.claimToken)
    return 'Looks like you already claimed your invitation. Please sign in instead';

  return '';
};

const buttonStyle = { outline: 'none' };

export default ConfirmInviteForm;
