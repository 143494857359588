import { gql } from '@apollo/client';

export default gql`
  mutation sendLoveNote($input: SendLoveNoteInput!) {
    sendLoveNote(input: $input) {
      ok
      errors {
        message
        path
      }
    }
  }
`;
