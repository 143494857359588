import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';

import { differentAddress } from 'auth/lib/utils';
import { FAB } from 'shared/buttons';
import { Checkbox } from 'shared/inputs';
import { formatToPhone } from 'shared/lib/inputHelpers';
import { Caption, H2, H3, ExternalLink, InternalLink, Label } from 'shared/typography';
import { pixelTrack } from 'shared/components/usePixel';

function Review() {
  const navigate = useNavigate();
  const { user, lastFour } = useStoreState(state => state.auth);
  const { submitIndividualApp, checkEmail } = useStoreActions(actions => actions.auth);

  const [firstAgree, setFirstAgree] = useState(false);
  const [secondAgree, setSecondAgree] = useState(false);
  const [thirdAgree, setThirdAgree] = useState(false);
  const [fourthAgree, setFourthAgree] = useState(false);
  const [saving, setSaving] = useState(false);

  const toggleFirst = () => setFirstAgree(agree => !agree);
  const toggleSecond = () => setSecondAgree(agree => !agree);
  const toggleThird = () => setThirdAgree(agree => !agree);
  const toggleFourth = () => setFourthAgree(agree => !agree);
  const submitApp = () => {
    setSaving(true);
    checkEmail({
      variables: { email: user?.email },
      onInvited: () => {
        setSaving(false);
        navigate('/auth/primary/verify');
      },
      onInvitedAdditionalOwner: token => {
        setSaving(false);
        window.location.href = `${process.env.REACT_APP_NEXT_URL}/welcome/additional?token=${token}`;
      },
      onNewUser: () => submitUserApp(),
      onPfmUser: () => submitUserApp(),
      onExistingUser: () => submitUserApp(),
    });
  };

  const submitUserApp = () => {
    setSaving(false);
    submitIndividualApp({
      onComplete: () => {
        pixelTrack('CompleteRegistration');
        setTimeout(() => {
          navigate('/auth/submit-application');
        }, 200);
      },
      onPartnerNeeded: () => navigate('/auth/steps/part-done'),
    });
  };

  const allAgree = firstAgree && secondAgree && thirdAgree && fourthAgree;

  return (
    <>
      <H3 maxWidth={80}>
        We don’t want to make any mistakes so let’s double-check that we’ve got the
        information right.
      </H3>
      <Box h={10} />
      <Box maxWidth={74}>
        <Flex justify="space-between">
          <Label>Contact</Label>
          <InternalLink to="/auth/personal/review/contact">Edit</InternalLink>
        </Flex>

        <H2>{`${user?.firstName} ${user?.lastName}`}</H2>
        <H2>{user?.email}</H2>
        <H2>{formatToPhone(user?.phoneNumber)}</H2>
        <H2>{`${user?.profile?.streetAddress1} ${
          user?.profile?.streetAddress2 || ''
        }`}</H2>
        <H2>{`${user?.profile?.city}, ${user?.profile?.state} ${user?.profile?.zipcode}`}</H2>
        <Box h={6} />

        <Flex justify="space-between">
          <Label>Date of Birth</Label>
          <InternalLink to="/auth/personal/review/dob">Edit</InternalLink>
        </Flex>
        <H2>{moment(user?.profile?.dateOfBirth).format('MMMM DD, YYYY')}</H2>
        <Box h={6} />

        <Flex justify="space-between">
          <Label>Social Security</Label>
          <InternalLink to="/auth/personal/review/ssn">Edit</InternalLink>
        </Flex>
        <H2>{`*** ** ${lastFour}`}</H2>
        {differentAddress(user) && (
          <>
            <Box h={6} />

            <Flex justify="space-between">
              <Label>Mailing Address</Label>
              <InternalLink to="/auth/personal/review/mailing">Edit</InternalLink>
            </Flex>
            <H2>{`${user?.profile?.mailingStreetAddress1} ${
              user?.profile?.mailingStreetAddress2 || ''
            }`}</H2>
            <H2>{`${user?.profile?.mailingCity}, ${user?.profile?.mailingState} ${user?.profile?.mailingZipcode}`}</H2>
          </>
        )}
        <Box h={8} />

        <Checkbox
          id="firstAgree"
          color="greenPea"
          value={firstAgree}
          onChange={toggleFirst}
        >
          <Caption>
            I have read & agree to the Zeta’s{' '}
            <ExternalLink
              fontSize={['xxs', 'xs']}
              href="https://www.askzeta.com/privacy-policy"
            >
              Privacy Policy
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink
              fontSize={['xxs', 'xs']}
              href="https://www.askzeta.com/terms-of-use"
            >
              Terms of Use
            </ExternalLink>
            .
          </Caption>
        </Checkbox>
        <Box h={5} />
        <Checkbox
          id="secondAgree"
          color="greenPea"
          value={secondAgree}
          onChange={toggleSecond}
        >
          <Caption>
            I have read & agree to the{' '}
            <ExternalLink
              fontSize={['xxs', 'xs']}
              href="https://www.askzeta.com/e-sign-consent"
            >
              Zeta E-Signature Agreement
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink
              fontSize={['xxs', 'xs']}
              href="https://www.askzeta.com/zeta-piermont-account-agreement"
            >
              Zeta-Piermont Joint Account Agreement
            </ExternalLink>
            .
          </Caption>
        </Checkbox>
        <Box h={5} />
        <Checkbox
          id="thirdAgree"
          color="greenPea"
          value={thirdAgree}
          onChange={toggleThird}
        >
          <Caption w={70}>
            I understand that my partner and I will legally co-own this account and have
            equal access to the funds.
          </Caption>
        </Checkbox>
        <Box h={3} />
        <Checkbox
          id="fourthAgree"
          color="greenPea"
          value={fourthAgree}
          onChange={toggleFourth}
        >
          <Caption w={70}>
            I confirm that I am a U.S. citizen or a Resident Alien and that the
            information I have provided is correct.
          </Caption>
        </Checkbox>
        <Box h={15} />
      </Box>
      <Box position="absolute" right={0} top={['4xl', 78]}>
        <Flex justify="flex-end">
          <FAB isLoading={saving} isDisabled={!allAgree} onClick={submitApp} />
        </Flex>
      </Box>
    </>
  );
}

export default Review;
