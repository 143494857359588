import React from 'react';
import { PropTypes } from 'prop-types';
import { FormControl, FormLabel, Select } from '@chakra-ui/react';

import { Paragraph } from 'shared/typography';

const TextInput = ({
  id,
  label,
  dark,
  options,
  value,
  onChange,
  showPlaceholder,
  error,
  ...rest
}) => {
  const handleChange = event => onChange(event.target.value);
  const selected = options?.find(item => item.value === value);

  return (
    <FormControl>
      <FormLabel
        htmlFor={id}
        fontSize="sm"
        fontWeight="semibold"
        color={dark ? 'greenLight' : 'gray'}
        pb={0}
      >
        {label}
      </FormLabel>
      <Select
        id={id}
        borderColor={error ? 'danger' : 'boulderWTrans'}
        borderBottom="1px solid"
        variant="flushed"
        w={67}
        color={dark ? 'iceberg' : 'black'}
        onChange={handleChange}
        value={selected?.value}
        placeholder={showPlaceholder ? ' ' : null}
        _placeholder={placeholderColor}
        {...rest}
      >
        {options.map(opt => {
          return (
            <option key={opt.value} value={opt.value}>
              {opt.name}
            </option>
          );
        })}
      </Select>
      {error && <Paragraph color="danger">{error}</Paragraph>}
    </FormControl>
  );
};

const placeholderColor = { color: 'gray' };

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  showPlaceholder: PropTypes.bool,
  value: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.array.isRequired,
};

TextInput.defaultProps = { value: '', error: '', dark: false, showPlaceholder: true };

export default TextInput;
