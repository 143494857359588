import { cardFields } from 'accounts/graphql/CardFields';

export const AccountFields = `
id
apy
bankClient
billReserveWindowDays
balances {
  id
  totalBalance
  totalCurrentBalance
  checkingBalance
  availableCheckingBalance
  savingsBalance
  billReserveBalance
  billReserveTargetBalance
  lastUpdatedAt
}
card {
  ${cardFields}
}
closeRequestedAt
closedAt
createdAt
features
fundingTransferCompleted
hasFundingTransfer
initiallyFundedAt
mask
overAgeThreshold
possibleCloseDate
productName
routingNumber
rawAccountNumber
transferHoldDays
transferrableType
users {
  id
}
virtualCards {
  ${cardFields}
}
`;
