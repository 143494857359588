import { v4 as uuidv4 } from 'uuid';

const LOGIN_KEY = '__zeta--token';

// eslint-disable-next-line no-unused-vars
export const signIn = (token, rememberMe = false) => {
  // rememberMe
  //   ? localStorage.setItem(LOGIN_KEY, token)
  sessionStorage.setItem(LOGIN_KEY, token);
};

export const signOut = () => {
  sessionStorage.removeItem(LOGIN_KEY);
  // localStorage.removeItem(LOGIN_KEY);
};

export const getLoginToken = () => sessionStorage.getItem(LOGIN_KEY);
// || localStorage.getItem(LOGIN_KEY);

export const getDuuid = () => {
  const duuid = sessionStorage.getItem('zeta-duuid');
  if (duuid?.length) return duuid;
  const newDuuid = uuidv4();
  sessionStorage.setItem('zeta-duuid', newDuuid);
  return newDuuid;
};
