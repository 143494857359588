/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex, Text } from '@chakra-ui/react';

import { Button } from 'shared/buttons';
import { useBreakpoint } from 'shared/components/breakpoint';
import useAnalytics from 'shared/components/useAnalytics';
import { H3 } from 'shared/typography';
import { pixelTrack } from 'shared/components/usePixel';

const Invite = () => {
  const bp = useBreakpoint();
  const navigate = useNavigate();
  useAnalytics({ name: 'invite-partner-choice' });

  const { user, partnerName, pfmUser } = useStoreState(state => state.auth);
  const { sendReminder, passPhone, getUser } = useStoreActions(actions => actions.auth);

  const [saving, setSaving] = useState(false);

  const onPassTheComp = () => {
    setSaving(true);
    passPhone({
      callback: () => {
        setSaving(false);
        pixelTrack({ event: 'P2Invited', custom: true });
        navigate(pfmUser ? '/auth/partner/create-pfm' : '/auth/partner/create');
      },
      onError: () => setSaving(false),
    });
  };

  const onTextLink = () => {
    setSaving(true);
    sendReminder({
      variables: { sendNow: true },
      callback: () => {
        getUser();
        setSaving(false);
        pixelTrack({ event: 'P2Invited', custom: true });
        navigate('/auth/sent');
      },
    });
  };

  const claimed =
    user?.invitations &&
    user?.invitations.length !== 0 &&
    user?.invitations[0]?.claimedAt;

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg="greenLight"
      justify="center"
      align="center"
    >
      <Text fontSize="8xl">
        <span role="img" aria-label="phone">
          📱
        </span>
      </Text>
      <Box h={10} />
      <Text fontSize={['4xl', '8xl']}>Invite {partnerName}</Text>
      <Box h={10} />
      <H3 fontWeight="semibold" textAlign="center" maxW={70}>
        {partnerName} can either continue the application here or we can email them a link
        to complete it on their own.
      </H3>
      <Box h={40} />
      <Flex
        direction={!bp.large ? 'column' : 'row'}
        justify={claimed ? 'center' : 'space-between'}
        align="center"
        w="3xl"
      >
        {!claimed && (
          <Button w={68} isLoading={saving} onClick={onPassTheComp}>
            Pass the computer
          </Button>
        )}
        {!bp.large && <Box h={10} />}
        <Button outlined={!claimed} w={68} isLoading={saving} onClick={onTextLink}>
          Email {partnerName} a secure link
        </Button>
      </Flex>
    </Flex>
  );
};

export default Invite;
