import { cardFields } from 'accounts/graphql/CardFields';

export const TeamFields = `
id
      name
      featureLimited
      bankClient
      accounts {
        id
        routingNumber
        rawAccountNumber
        card {
          ${cardFields}
        }
      }
`;
