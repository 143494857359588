import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import Step from 'auth/components/Step';
import { Progress4Icon } from 'auth/icons';
import { FAB } from 'shared/buttons';
import { useBreakpoint } from 'shared/components/breakpoint';
import { H1, H3 } from 'shared/typography';

const PartnerInfoAdded = () => {
  const bp = useBreakpoint();
  const navigate = useNavigate();

  const { partnerName } = useStoreState(state => state.auth);

  const moveForward = () => navigate('/auth/invitation');

  return (
    <Layout dark height="100vh">
      {!bp.large && <Progress4Icon position="absolute" right={0} top={3} />}
      <Flex justify="space-between" align="center">
        <H1 color="shared">Great, let's invite {partnerName}.</H1>
        {bp.large && <Progress4Icon />}
      </Flex>

      <Box h={10} />
      <H3 color="iceberg">
        Our lawyers would like {partnerName} to confirm their information.
      </H3>
      <Box h={15} />

      <Step checked>Secure your account</Step>
      <Box h={10} />
      <Step checked>Add your information</Step>
      <Box h={10} />
      <Step checked>Partner’s Information</Step>
      <Box h={10} />
      <Step>Invite {partnerName}</Step>
      <Box h={10} />
      <Step>Submit your application</Step>
      <Box h={10} />
      <Step>Add funds</Step>
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB onClick={moveForward} autoFocus />
        </Flex>
      </Box>
    </Layout>
  );
};

export default PartnerInfoAdded;
