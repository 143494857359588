import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import { signIn } from 'auth/lib/authentication';
import { getRoute } from 'auth/lib/utils';
import { FAB } from 'shared/buttons';
import { validEmail, validPassword } from 'shared/lib/inputHelpers';
import { Checkbox, PasswordInput, TextInput } from 'shared/inputs';
import { H1, H3, H5, Paragraph } from 'shared/typography';

const LogIn = () => {
  const navigate = useNavigate();

  const { appKeys } = useStoreState(state => state.app);
  const { trackAnalytics } = useStoreActions(actions => actions.app);
  const {
    login,
    setPartnerFlow,
    setLoginEmail,
    getInvite,
    setFirstName,
    setPartnerName,
  } = useStoreActions(actions => actions.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState({});

  const toggleRemember = () => setRememberMe(val => !val);

  const goToForgotPassword = e => {
    e.preventDefault();
    setLoginEmail(email);
    navigate('/auth/forgot-password');
  };

  const validate = () => {
    return validEmail(email) && validPassword(password);
  };

  const handlePasswordChange = val => {
    setPassword(val);
    setError({});
  };
  const handleEvent = e => e.preventDefault();

  const logInUser = () => {
    if (validate()) {
      setSaving(true);
      login({
        variables: { email, password },
        callback: afterLogin,
        onInvited: pendingInvitationToken => {
          setSaving(false);
          getInvite({
            callback: invite => {
              setFirstName(invite?.recipientFirstName);
              setPartnerName(invite?.senderFirstName);
              navigate(
                `/auth/invited/steps/intro-steps?claimtoken=${pendingInvitationToken}`,
              );
            },
          });
        },
        onInvitedAdditionalOwner: (token, ssoT) => {
          setSaving(false);
          window.location.href = `${process.env.REACT_APP_NEXT_URL}/welcome/additional/secret-code?ssoT=${ssoT}&token=${token}`;
        },
        newDevice: () => {
          navigate('/appstore');
        },
        needsOnboarding: () => {
          setSaving(false);
          appKeys?.globalWaitlistOn
            ? navigate('/auth/welcome/waitlist')
            : setError({ password: 'Incorrect email or password' });
        },
        onError: err => {
          setSaving(false);
          setError(err);
        },
      });
    }
  };

  const afterLogin = async (token, user) => {
    setSaving(false);
    setEmail('');
    setPassword('');
    trackAnalytics({ name: 'new-user-session', data: { platform: 'web' } });
    user?.userType === 'partner' && setPartnerFlow(true);
    signIn(token, rememberMe);
    const route = getRoute(user);
    if (route?.includes('https://')) {
      const claimToken = route.split('token=')[1];
      window.location.href = `${process.env.REACT_APP_NEXT_URL}/welcome/additional/secret-code?ssoT=${token}&token=${claimToken}`;
    } else {
      navigate(route);
    }
  };

  return (
    <Layout bg="iceberg" h="6xl">
      <H1>Welcome back to Zeta.</H1>
      <Box h={10} />
      <H3>Login to finish your Joint Account Application.</H3>
      <Box h={15} />
      <form onSubmit={handleEvent}>
        <TextInput
          id="loginEmail"
          type="email"
          label="Email"
          autoComplete="username"
          value={email}
          onChange={setEmail}
        />
        <Box h={15} />
        <PasswordInput
          id="loginPassword"
          label="Password"
          autoComplete="current-password"
          error={error?.password}
          value={password}
          onChange={handlePasswordChange}
          onEnter={logInUser}
        />
        <Box h={15} />
        <Flex>
          <Checkbox id="remember" value={rememberMe} onChange={toggleRemember}>
            <Paragraph color="primary">Remember me</Paragraph>
          </Checkbox>
          <Box w={16} />
          <button type="button" onClick={goToForgotPassword}>
            <Paragraph fontWeight="semibold" color="primary">
              Forgot password
            </Paragraph>
          </button>
        </Flex>
      </form>
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB
            isLoading={saving}
            isDisabled={!validPassword(password)}
            onClick={logInUser}
          />
        </Flex>
        <Box h={10} />
        <Flex justify="flex-end">
          <H5 as={Link} to="/auth/steps/intro" color="primary">
            New to Zeta? Sign Up
          </H5>
        </Flex>
      </Box>
    </Layout>
  );
};

export default LogIn;
