import { extendTheme } from '@chakra-ui/react';

import breakpoints from 'app/theme/foundations/breakpoints';
import colors from 'app/theme/foundations/colors';
import fonts from 'app/theme/foundations/fonts';
import fontSizes from 'app/theme/foundations/fontSizes';
import lineHeights from 'app/theme/foundations/lineHeights';
import sizes from 'app/theme/foundations/sizes';
import space from 'app/theme/foundations/space';

const overrides = {
  fonts,
  fontSizes,
  lineHeights,
  space,
  breakpoints,
  sizes,
  colors,
};

export default extendTheme(overrides);
