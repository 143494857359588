import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import { FAB } from 'shared/buttons';
import { TextInput } from 'shared/inputs';
import { H1, H3, Legal } from 'shared/typography';
import { pixelTrack } from 'shared/components/usePixel';

function VerifyInviteForm() {
  const navigate = useNavigate();
  const { partnerName, user, claimToken, checkingInvite, shortCircuit, invite } =
    useStoreState(state => state.auth);
  const { sendCode, getInvite, validateCode, submitIndividualApp } = useStoreActions(
    actions => actions.auth,
  );

  const [code, setCode] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState({});
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    getInvite({
      callback: inv => {
        if (Object.keys(inv).length > 0) {
          sendCode();
        } else {
          navigate('/go-mobile');
        }
      },
    });
  }, [getInvite, navigate, sendCode]);

  const handleCodeChange = value => {
    setError({});
    setCode(value);
  };

  const resendCode = e => {
    e.preventDefault();
    setSent(true);
    sendCode();
  };

  const submit = () => {
    if (code) {
      setSaving(true);
      validateCode({
        variables: { claimToken, verificationCode: code },
        callback: afterValidation,
        onError: err => {
          setSaving(false);
          setError(err);
        },
      });
    }
  };

  const afterValidation = () => {
    setSaving(false);
    pixelTrack({ event: 'P2Claimed', custom: true });
    if (!user?.tpId) {
      submitIndividualApp({
        onComplete: () => navigate('/auth/submit-application'),
        onPartnerNeeded: () =>
          navigate(shortCircuit ? '/auth/personal/partner-dob' : '/auth/steps/part-done'),
      });
    } else if (user?.tpId && user?.partner?.tpId) {
      navigate('/auth/submit-application');
    } else if (!user?.partner?.tpId) {
      navigate('/auth/final');
    }
  };

  return (
    !checkingInvite && (
      <Layout>
        <H1>Let's link you to {partnerName}</H1>
        <Box h={10} />
        <H3>
          We just texted you a code to the number {partnerName} gave us (**
          {invite?.phoneNumberDigits}).
        </H3>
        <Box h={15} />
        <TextInput
          id="secretCode"
          type="password"
          label="Top secret code"
          autoComplete="one-time-code"
          value={code}
          error={getErrorMsg(error)}
          onChange={handleCodeChange}
          onEnter={submit}
          placeholder="Your secret code"
        />
        <Box h={26} />
        <Box position="absolute" right={0} top={[80, 78]}>
          <Flex justify="flex-end">
            <FAB isLoading={saving} isDisabled={!code} onClick={submit} />
          </Flex>
          <Box h={8} />
          {!sent && (
            <Flex justify="flex-end">
              <button type="button" style={buttonStyle} onClick={resendCode}>
                <Legal as="i">Resend verification code.</Legal>
              </button>
            </Flex>
          )}
        </Box>
        <Box h={20} />
      </Layout>
    )
  );
}

const getErrorMsg = error => {
  if (error?.verificationCode) return "This code isn't correct";
  if (error?.claimToken)
    return 'Looks like you already claimed your invitation. Please sign in instead';

  return '';
};

const buttonStyle = { outline: 'none' };

export default VerifyInviteForm;
