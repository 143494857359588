import gql from 'graphql-tag';

export default gql`
  query appKeys($zetaAppKey: String!) {
    appKeys(zetaAppKey: $zetaAppKey) {
      id
      globalWaitlistOn
      tokenIdKey
    }
  }
`;
