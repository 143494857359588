import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useStoreActions } from 'easy-peasy';
import {
  Box,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  UnorderedList,
} from '@chakra-ui/react';

import { H1, H3 } from 'shared/typography';

const SSNModal = ({ isOpen, onClose }) => {
  const { trackAnalytics } = useStoreActions(actions => actions.app);

  useEffect(() => {
    if (isOpen) trackAnalytics({ name: 'us-patriot-modal' });
  }, [isOpen, trackAnalytics]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent bg="yellowLight" borderRadius="10px">
        <ModalHeader px={15} pt={24}>
          <H1>Why do we ask for your social security?</H1>
        </ModalHeader>
        <ModalCloseButton mt={8} mr={8} size="lg" color="primary" />
        <ModalBody px={15} pb={68} pt={10}>
          <H3>
            To help the government fight the funding of terrorism and money laundering
            activities, federal law requires all US financial institutions to obtain,
            verify, and record information that identifies each person who opens an
            account. This requirement is part of the US Patriot Act and may include your
            name, address, date of birth, and social security number. We may also ask to
            see your identifying documents.
          </H3>
          <Box h={10} />
          <H3 fontStyle="italic" fontWeight="700">
            What this means for you:
          </H3>
          <Box h={10} />
          <UnorderedList pl={5}>
            <ListItem>
              <H3>Your social security number may be used to verify your identity.</H3>
            </ListItem>
            <ListItem>
              <H3>
                We may perform a soft credit inquiry to provide insight into your banking
                history.
              </H3>
            </ListItem>
            <ListItem>
              <H3>Your overall credit score will not be impacted.</H3>
            </ListItem>
          </UnorderedList>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

SSNModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SSNModal;
