import React from 'react';
import { PropTypes } from 'prop-types';
import { Flex } from '@chakra-ui/react';

import { CheckMarkIcon, InvalidIcon } from 'shared/icons';
import { validNumbers, validCaps, validLength } from 'shared/lib/inputHelpers';
import { Caption } from 'shared/typography';

const PasswordValidations = ({ viewValidations, password }) => {
  return (
    viewValidations && (
      <Flex align="center" pt={3}>
        {validNumbers(password) ? (
          <CheckMarkIcon color="shared" />
        ) : (
          <InvalidIcon color="shared" />
        )}
        <Caption pl={1} pr={6}>
          Number
        </Caption>
        {validCaps(password) ? (
          <CheckMarkIcon color="shared" />
        ) : (
          <InvalidIcon color="shared" />
        )}
        <Caption pl={1} pr={6}>
          Uppercase
        </Caption>
        {validLength(password) ? (
          <CheckMarkIcon color="shared" />
        ) : (
          <InvalidIcon color="shared" />
        )}
        <Caption pl={1}>8+ Characters</Caption>
      </Flex>
    )
  );
};

PasswordValidations.propTypes = {
  viewValidations: PropTypes.bool.isRequired,
  password: PropTypes.string,
};
PasswordValidations.defaultProps = { password: '' };

export default PasswordValidations;
