import React, { useState, useEffect } from 'react';
import querystring from 'query-string';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useLocation } from 'react-router-dom';
import ScriptTag from 'react-script-tag';
import Skeleton from 'react-loading-skeleton';
import Helmet from 'react-helmet';

import { Flex } from '@chakra-ui/react';
import OldVirtualCard from 'cards/images/OldVirtualCard';
import VirtualCard from 'cards/images/VirtualCard';

const UNSCALED_WIDTH = 376;

const MobileShowCard = () => {
  const marqetaUrl =
    process.env.NODE_ENV === 'production'
      ? 'https://widgets.marqeta.com/marqetajs/1.1.0/marqeta.min.js'
      : 'https://widgets-sandbox.marqeta.com/marqetajs/1.1.0/marqeta.min.js';

  const [token, setToken] = useState();
  const { getMQToken } = useStoreActions(actions => actions.cards);
  const location = useLocation();
  const {
    id,
    cardColor,
    primaryColor,
    secondaryColor,
    iconTopColor,
    iconBottomColor,
    iconTextColor,
    width = UNSCALED_WIDTH,
  } = querystring.parse(location.search);
  const [showSkeleton, setShowSkeleton] = useState(true);
  useEffect(() => {
    if (!token) {
      getMQToken({
        variables: {
          id,
        },
        callback: t => {
          setToken(t);
        },
      });
    }
  }, [getMQToken, location, token, id]);

  const { user } = useStoreState(state => state.auth);
  const mq = window.marqeta;

  const scale = width / UNSCALED_WIDTH;

  const cardDataStyle = {
    span: {
      'font-size': '16px',
      'font-family': 'Helvetica',
      'font-weight': 'bold',
      color: secondaryColor ? `#${secondaryColor}` : '#0D342D',
    },
  };

  useEffect(() => {
    const style = {
      span: {
        'font-size': '16px',
        'font-family': 'Helvetica',
        'font-weight': 'bold',
        color: secondaryColor ? `#${secondaryColor}` : '#0D342D',
      },
    };
    if (token && mq) {
      mq.bootstrap({
        clientAccessToken: token,
        showPan: {
          cardPan: {
            domId: 'mq-card-pan',
            format: true,
            styles: style,
          },
          cardExp: {
            domId: 'mq-card-exp',
            format: true,
            styles: style,
          },
          cardCvv: {
            domId: 'mq-card-cvv',
            styles: style,
          },
        },
        callbackEvents: {
          onSuccess: () => {
            setShowSkeleton(false);
            return true;
          },
        },
      });
    }
  }, [token, mq, secondaryColor]);

  const CardDisplay = () => {
    return cardColor ? (
      <OldVirtualCard cardColor={cardColor} />
    ) : (
      <VirtualCard
        primaryColor={`#${primaryColor}`}
        secondaryColor={`#${secondaryColor}`}
        iconTopColor={`#${iconTopColor}`}
        iconBottomColor={`#${iconBottomColor}`}
        iconTextColor={`#${iconTextColor}`}
      />
    );
  };

  return (
    <>
      <Helmet>
        <meta name="viewport" content="initial-scale=1.0, maximum-scale=1.0" />
      </Helmet>
      <ScriptTag isHydrating={false} type="text/javascript" src={marqetaUrl} />
      <Flex direction="row" justify="center" width={width}>
        <div
          style={{
            transform: `scale(${scale})`,
            transformOrigin: 'top center',
            width: UNSCALED_WIDTH,
            height: 247,
          }}
        >
          {!showSkeleton && (
            <div style={{ position: 'absolute', top: 90, left: 36 }}>
              <span
                style={{
                  color: cardDataStyle.span.color,
                  fontSize: '16px',
                  fontFamily: 'Helvetica',
                  fontWeight: 600,
                }}
              >
                {(user.legalFirstName || user.firstName).toUpperCase()}{' '}
                {user.lastName.toUpperCase()}
              </span>
            </div>
          )}
          <div style={{ position: 'absolute', top: 120, left: 36 }}>
            <div id="mq-card-pan" />
          </div>
          <div style={{ position: 'absolute', top: 164, left: 88 }}>
            <div id="mq-card-exp" />
          </div>
          <div style={{ position: 'absolute', top: 164, left: 36 }}>
            <div id="mq-card-cvv" />
          </div>
          {showSkeleton ? (
            <Skeleton
              color="#ededed"
              highlightColor="#edf5f1"
              height={247}
              width="100%"
            />
          ) : (
            <CardDisplay />
          )}
        </div>
      </Flex>
    </>
  );
};

export default MobileShowCard;
