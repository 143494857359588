/* eslint-disable no-shadow */
import { action, thunk } from 'easy-peasy';

import ACCOUNTS_QUERY from 'accounts/graphql/queries/AccountsQuery';
import SINGLE_ACCOUNT_QUERY from 'accounts/graphql/queries/AccountQuery';
import ACCOUNTS_SUBSCRIPTION from 'accounts/graphql/subscriptions/Accounts';
import SINGLE_ACCOUNT_SUBSCRIPTION from 'accounts/graphql/subscriptions/Account';
import GET_TEAMS from 'accounts/graphql/queries/Teams';
import { deepOmitTypeName } from 'shared/lib/parseGraphql';

const accountsModel = {
  accounts: [],
  card: {},
  statements: [],
  teams: [],
  currentTeam: {},
  loadingStatement: false,
  loadingStatements: false,
  loadingAccounts: false,
  loadingSubscription: true,
  loadingTeams: false,
  accountsSubscription: null,
  featureAdoptionLevel: null,
  featureAdoptionLevelSubscription: null,

  setCurrentTeam: action((state, payload) => {
    state.currentTeam = payload;
  }),

  setCard: action((state, payload) => {
    state.card = payload;
  }),

  setLoadingSubscription: action((state, payload) => {
    state.loadingSubscription = payload;
  }),

  setLoadingTeams: action((state, payload) => {
    state.loadingTeams = payload;
  }),

  unsubscribe: action(state => {
    state.accountsSubscription?.unsubscribe && state.accountsSubscription.unsubscribe();
    state.featureAdoptionLevelSubscription?.unsubscribe &&
      state.featureAdoptionLevelSubscription.unsubscribe();
  }),

  setAccountsSubscription: action((state, payload) => {
    state.accountsSubscription = payload;
  }),

  setLoadingAccounts: action((state, payload) => {
    state.loadingAccounts = payload;
  }),

  setLoadingStatement: action((state, payload) => {
    state.loadingStatement = payload;
  }),

  setLoadingStatements: action((state, payload) => {
    state.loadingStatements = payload;
  }),

  setStatements: action((state, payload) => {
    state.statements = payload;
  }),

  setAccounts: action((state, payload) => {
    state.accounts = payload;
  }),

  setTeams: action((state, payload) => {
    state.teams = payload;
  }),
  setFeatureAdoptionLevel: action((state, payload) => {
    state.featureAdoptionLevel = payload;
  }),
  setFeatureAdoptionLevelSubscription: action((state, payload) => {
    state.featureAdoptionLevelSubscription = payload;
  }),
  unsubscribeFeatureAdoption: action(state => {
    state.featureAdoptionLevelSubscription?.unsubscribe &&
      state.featureAdoptionLevelSubscription.unsubscribe();
  }),
  updateAccount: action((state, payload) => {
    state.accounts = state.accounts.map(account =>
      account.id === payload.id ? payload : account,
    );
  }),

  subscribeAccounts: thunk(async (action, payload, { injections }) => {
    action.setLoadingSubscription(true);
    action.unsubscribe();
    const { client } = injections;
    const { setAccounts, setAccountsSubscription, setCard } = action;

    const observable = await client.subscribe({
      query: ACCOUNTS_SUBSCRIPTION,
      variables: payload?.variables,
    });

    const subscription = observable.subscribe(response => {
      const cleanResponse = deepOmitTypeName(response);
      setAccounts(cleanResponse.data?.accounts);
      setCard(cleanResponse.data?.accounts[0]?.card);

      payload?.callback && payload?.callback(cleanResponse.data?.accounts);
      action.setLoadingSubscription(false);
    });

    setAccountsSubscription(subscription);
  }),

  subscribeAccount: thunk(async (action, payload, { injections }) => {
    action.setLoadingSubscription(true);
    const { client } = injections;
    const { setAccounts, setAccountsSubscription, setCard } = action;

    const observable = await client.subscribe({
      query: SINGLE_ACCOUNT_SUBSCRIPTION,
      variables: payload?.variables,
    });
    const subscription = observable.subscribe(response => {
      const cleanResponse = deepOmitTypeName(response);
      setAccounts([cleanResponse.data?.account]);
      setCard(cleanResponse.data?.account?.card);

      payload?.callback && payload?.callback();
      action.setLoadingSubscription(false);
    });

    setAccountsSubscription(subscription);
  }),

  // This retrieves all accounts, both open and closed
  getAccounts: thunk(async (action, payload, { injections }) => {
    const { client } = injections;
    action.setLoadingAccounts(true);
    const response = await client.query({
      query: ACCOUNTS_QUERY,
      variables: payload?.variables || {},
      fetchPolicy: 'network-only',
    });
    const cleanResponse = deepOmitTypeName(response);

    if (!payload?.dontOverrideState || payload?.dontOverrideState === false) {
      action.setAccounts(cleanResponse.data?.accounts);
    }
    action.setLoadingAccounts(false);
    payload?.callback && payload?.callback(cleanResponse.data?.accounts);
  }),

  getAccount: thunk(async (action, payload, { injections }) => {
    const { client } = injections;
    action.setLoadingAccounts(true);

    const response = await client.query({
      query: SINGLE_ACCOUNT_QUERY,
      variables: payload?.variables,
    });
    const cleanResponse = deepOmitTypeName(response);

    action.setAccounts([cleanResponse.data?.account]);
    action.setLoadingAccounts(false);
    payload?.callback && payload?.callback(cleanResponse.data?.account);
  }),
  getTeams: thunk(async (action, payload, { injections, getStoreState }) => {
    const { client } = injections;
    const { auth } = getStoreState();

    action.setLoadingTeams(true);

    const response = await client.query({ query: GET_TEAMS });
    const cleanResponse = deepOmitTypeName(response);
    action.setTeams(cleanResponse.data?.teams);
    if (!payload?.skipCurrentTeam) {
      const preferences = auth?.user?.preferences
        ? JSON.parse(auth?.user?.preferences)
        : {};

      const defaultTeam =
        cleanResponse.data?.teams?.find?.(
          team => team.id === preferences?.defaultTeamId,
        ) ?? cleanResponse.data?.teams?.[0];

      action.setCurrentTeam(defaultTeam);
    }

    action.setLoadingTeams(false);

    payload?.callback && payload?.callback(cleanResponse.data?.teams);
  }),
};

export default accountsModel;
