import Intl from 'intl';
import 'intl/locale-data/jsonp/en.js';

const withCentsFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const noCentsFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const ceil = amount => Math.ceil(Math.abs(amount));

export const toDollars = (
  amount,
  {
    asIs = false,
    showCents = true,
    showMinus = true,
    showPlus = false,
    plusOnly = false,
  } = {},
) => {
  const safeAmount = amount || 0;
  const dollars = asIs ? safeAmount : safeAmount / 100;
  let prefix = '';
  if ((showPlus || plusOnly) && dollars > 0) {
    prefix = '+';
  } else if (showMinus && dollars < 0) {
    prefix = plusOnly ? '' : '-';
  }
  const formatter = showCents ? withCentsFormatter : noCentsFormatter;
  return `${prefix}${formatter.format(Math.abs(dollars))}`;
};

export const toRoundedDollars = (amount, opts = {}) => {
  let roundedAmount = ceil(amount / 100);
  roundedAmount = amount < 0 ? roundedAmount * -1 : roundedAmount;
  return toDollars(roundedAmount, { asIs: true, showCents: false, ...opts });
};
