import { gql } from '@apollo/client';

export default gql`
  mutation sendInvitationReminder($input: SendInvitationReminderInput!) {
    sendInvitationReminder(input: $input) {
      ok
      errors {
        message
        path
      }
    }
  }
`;
