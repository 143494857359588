import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import { signIn } from 'auth/lib/authentication';
import { FAB } from 'shared/buttons';
import { validPassword } from 'shared/lib/inputHelpers';
import { PasswordInput } from 'shared/inputs';
import { H1, H3 } from 'shared/typography';

const CreateAccountPFM = () => {
  const navigate = useNavigate();

  const { email, firstName } = useStoreState(state => state.auth);
  const { login } = useStoreActions(actions => actions.auth);

  const [password, setPassword] = useState('');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState({});

  const createAccount = () => {
    if (validPassword(password)) {
      setSaving(true);
      login({
        variables: { email, password },
        needsOnboarding: afterCreation,
        callback: afterCreation,
        onError: err => {
          setSaving(false);
          setError(err);
        },
      });
    }
  };
  const afterCreation = token => {
    setSaving(false);
    signIn(token, true);
    navigate('/auth/steps/secured');
  };

  const handleEvent = e => e.preventDefault();

  return (
    <Layout>
      <H1>Hi {firstName}, welcome to joint banking.</H1>
      <Box h={10} />
      <H3>Use your Zeta password to login.</H3>
      <Box h={15} />
      <form onSubmit={handleEvent}>
        <PasswordInput
          id="pfmPassword"
          label="Password"
          autoComplete="current-password"
          error={error.password}
          value={password}
          onChange={setPassword}
          onEnter={createAccount}
        />
      </form>
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB
            isLoading={saving}
            isDisabled={!validPassword(password)}
            onClick={createAccount}
          />
        </Flex>
      </Box>
      <Box h={20} />
    </Layout>
  );
};

export default CreateAccountPFM;
