import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import AuthRoute from 'auth/components/AuthRoute';

import WelcomePartner from 'auth/pages/WelcomePartner';

import PartnerIntro from 'auth/components/steps/PartnerIntro';
import ConfirmInviteForm from 'auth/components/secondary/ConfirmInviteForm';
import CreateNewPasswordForm from 'auth/components/secondary/CreateNewPasswordForm';
import PartnerAccountSecured from 'auth/components/steps/PartnerAccountSecured';
import PFMPasswordForm from 'auth/components/secondary/PFMPasswordForm';
import InvitedDOBForm from 'auth/components/secondary/InvitedDOBForm';
import InvitedAddressForm from 'auth/components/secondary/InvitedAddressForm';
import InvitedMailingForm from 'auth/components/secondary/InvitedMailingForm';
import InvitedSSNForm from 'auth/components/secondary/InvitedSSNForm';

import ForgotPasswordForm from 'auth/components/prelude/ForgotPasswordForm';
import ConfirmForgotPassForm from 'auth/components/prelude/ConfirmForgotPassForm';
import NewPasswordForm from 'auth/components/prelude/NewPasswordForm';

import PrimaryOnboarding from 'auth/pages/PrimaryOnboarding';
import SecondaryOnboarding from 'auth/pages/SecondaryOnboarding';

import InvitedPartnerReview from 'auth/pages/InvitedPartnerReview';
import Processing from 'auth/pages/Processing';
import Invite from 'auth/pages/Invite';
import ResendInviteOrPassComputer from 'auth/pages/ResendInviteOrPassComputer';
import RestartPassComputer from 'auth/pages/RestartPassComputer';
import SentInvite from 'auth/pages/SentInvite';
import AheadGame from 'auth/pages/AheadGame';
import Congrats from 'auth/pages/Congrats';
import CongratsTwo from 'auth/pages/CongratsTwo';
import NeedMoreTime from 'auth/pages/NeedMoreTime';
import NotApproved from 'auth/pages/NotApproved';
import SubmitApp from 'auth/pages/SubmitApp';
import SubmitError from 'auth/pages/SubmitError';
import Final from 'auth/pages/Final';
import WaitListComplete from 'auth/pages/WaitListComplete';
import PassComputerReview from 'auth/pages/PassComputerReview';
import ForgotPassword from 'auth/pages/ForgotPassword';

import Lobby from 'auth/lib/images/Intro.jpg';
import CreateAccount from 'auth/lib/images/CreateAccount.jpg';
import Name from 'auth/lib/images/Name.jpg';
import Partner from 'auth/lib/images/Partner.jpg';
import Transformer from 'auth/lib/images/Transformer.jpg';

import { useBreakpoint } from 'shared/components/breakpoint';

const AuthRouter = () => {
  const bp = useBreakpoint();
  if (process.env.REACT_APP_GTM_ID && process.env.REACT_APP_GTM_ID !== '') {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
    });
  }

  useEffect(() => {
    // Preloading images
    if (!bp.small) {
      new Image().src = Lobby;
      new Image().src = CreateAccount;
      new Image().src = Name;
      new Image().src = Partner;
      new Image().src = Transformer;
    }
  }, [bp]);

  return (
    <Routes>
      <Route path="/">
        <Navigate to="steps/intro" />
      </Route>

      <Route path="on-the-list" element={<WaitListComplete />} />

      <AuthRoute path="invitation" element={<Invite />} />
      <AuthRoute path="restart" element={<RestartPassComputer />} />
      <AuthRoute path="resend-invitation" element={<ResendInviteOrPassComputer />} />
      <AuthRoute path="sent" element={<SentInvite />} />
      <AuthRoute path="ahead" element={<AheadGame />} />
      <AuthRoute path="submit-application" element={<SubmitApp />} />
      <AuthRoute path="resubmit-application" element={<SubmitError />} />
      <AuthRoute path="processing" element={<Processing />} />
      <AuthRoute path="congrats" element={<Congrats />} />
      <AuthRoute path="congrats-two" element={<CongratsTwo />} />
      <AuthRoute path="need-more-time" element={<NeedMoreTime />} />
      <AuthRoute path="not-approved" element={<NotApproved />} />
      <AuthRoute path="final" element={<Final />} />

      <Route path="forgot-password" element={<ForgotPassword />}>
        <Route path="/" element={<ForgotPasswordForm />} />
        <Route path="confirm-forgot-password" element={<ConfirmForgotPassForm />} />
        <Route path="new-password" element={<NewPasswordForm />} />
      </Route>

      <AuthRoute
        checkInvited
        path="invited-review/*"
        element={<InvitedPartnerReview />}
      />

      <AuthRoute checkPartner path="partner/review/*" element={<PassComputerReview />} />

      {/* Partner Flow - Invited */}
      <Route path="invited" element={<SecondaryOnboarding />}>
        <Route path="steps">
          <Route path="intro" element={<WelcomePartner />} />
          <Route path="intro-steps" element={<PartnerIntro />} />
          <AuthRoute path="secured" element={<PartnerAccountSecured />} />
        </Route>

        <Route path="welcome">
          <Route path="confirm-invite" element={<ConfirmInviteForm />} />
          <Route path="pfm-login" element={<PFMPasswordForm />} />
          <Route path="create" element={<CreateNewPasswordForm />} />
        </Route>

        <Route path="personal">
          <AuthRoute checkInvited path="dob" element={<InvitedDOBForm />} />
          <AuthRoute checkUser path="address" element={<InvitedAddressForm />} />
          <AuthRoute checkUser path="mailing" element={<InvitedMailingForm />} />
          <AuthRoute checkInvited path="ssn" element={<InvitedSSNForm />} />
        </Route>
      </Route>
      {/* Primary User Flow w Pass Computer */}
      <Route path="*" element={<PrimaryOnboarding />} />
    </Routes>
  );
};

export default AuthRouter;
