import { gql } from '@apollo/client';

export default gql`
  mutation submitAccountApplication($input: SubmitAccountApplicationInput!) {
    submitAccountApplication(input: $input) {
      accountApplication {
        id
        status
      }
      errors {
        message
        path
      }
      onboardingStatus
    }
  }
`;
