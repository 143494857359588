import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';

import Layout from 'auth/components/Layout';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { DateInput } from 'shared/inputs';
import { H1, Legal } from 'shared/typography';
import { invalidAgeMessage, isValidAdultAge } from 'auth/lib/utils';

function DOBForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'partner-birthday' });

  const { user, partnerName } = useStoreState(state => state.auth);
  const { editUser, getUser } = useStoreActions(actions => actions.auth);

  const [dateOfBirth, setDateOfBirth] = useState(
    user?.profile?.pendingPartnerDateOfBirth
      ? moment(user?.profile?.pendingPartnerDateOfBirth)
      : null,
  );
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const moveForward = () => navigate('/auth/personal/partner-team');
  const validate = value => {
    if (!isValidAdultAge({ dateOfBirth: value })) {
      setErrors({ pendingPartnerDateOfBirth: invalidAgeMessage({ dateOfBirth: value }) });
    } else {
      setErrors({});
    }
  };
  const saveInfo = () => {
    if (!isValidAdultAge({ dateOfBirth })) {
      validate(dateOfBirth);
      return;
    }
    setSaving(true);
    editUser({
      variables: { pendingPartnerDateOfBirth: dateOfBirth },
      callback: () => {
        setSaving(false);
        moveForward();
      },
      onError: err => {
        setSaving(false);
        setErrors(err);
      },
    });
  };

  return (
    <Layout>
      <H1>Lastly, tell us {partnerName}’s birthday.</H1>
      <Box h={10} />
      <Box h={15} />
      <DateInput
        id="dob"
        label="Date of Birth"
        autoComplete="bday"
        error={errors.pendingPartnerDateOfBirth}
        selected={dateOfBirth}
        onChange={value => {
          setDateOfBirth(value);
          validate(value);
        }}
        onSelect={validate}
        onBlur={() => validate(dateOfBirth)}
        onEnter={saveInfo}
      />
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB
            isLoading={saving}
            isDisabled={!isValidAdultAge({ dateOfBirth })}
            onClick={saveInfo}
          />
        </Flex>
        <Box h={10} />
        <Flex justify="flex-end">
          <button type="button" style={buttonStyle} onClick={moveForward}>
            <Legal>Skip, and let {partnerName} fill this out.</Legal>
          </button>
        </Flex>
      </Box>
      <Box h={20} />
    </Layout>
  );
}

const buttonStyle = { outline: 'none' };

export default DOBForm;
