import isPresent from 'shared/lib/isPresent';

import moment from 'moment';

export const appleLocation = 'https://apps.apple.com/us/app/zeta-joint-card/id1493011848';
export const playLocation =
  'https://play.google.com/store/apps/details?id=com.askzeta.aZetaFi&hl=en_US&gl=US';

const AuthMap = {
  primary_onboarding_incomplete: '/auth/steps/secured',
  partner_onboarding_incomplete: '/auth/invited/steps/secured',
  primary_onboarding_submit: '/auth/submit-application',
  partner_onboarding_submit: '/auth/submit-application',
  pass_phone_interrupted: '/auth/restart',
  awaiting_partner_completion: '/auth/final',
  primary_invite_sent: '/auth/resend-invitation',
  primary_no_invite_sent: '/auth/steps/part-done',
  application_rejected: '/auth/not-approved',
  application_pending: '/auth/need-more-time',
  application_approved: '/auth/final',
};

export const getRoute = user => {
  if (!isPresent(user)) return '/auth/login';
  const {
    lockedAt,
    fullyClosed,
    zetaOnboardingStatus,
    additionalOwnerInvitations = [],
  } = user;

  const AOClaimUrl = additionalOwnerInvitations.reverse()[0]?.claimUrl;

  if (fullyClosed) {
    if (AOClaimUrl?.length) {
      return lockedAt ? '/auth/final' : AOClaimUrl;
    }
    return '/auth/final';
  }

  if (lockedAt) return '/auth/final';

  if (userHasAccount(user) && notFunded(user)) {
    return '/auth/add-money';
  }

  if (AOClaimUrl) return AOClaimUrl;

  return AuthMap[zetaOnboardingStatus] ?? '/auth/login';
};

export const userHasAccount = user => {
  return user?.accounts.length > 0;
};

export const notFunded = user => {
  const hasAFundedAccount = user.accounts?.some(
    account => account.initiallyFundedAt !== null,
  );
  return !(
    hasAFundedAccount ||
    user?.seenFtes?.includes('funding') ||
    user?.partner?.seenFtes?.includes('funding')
  );
};

export const differentAddress = partner => {
  if (!partner?.profile?.mailingStreetAddress1) return false;
  if (
    partner?.profile?.streetAddress1 === partner?.profile?.mailingStreetAddress1 &&
    partner?.profile?.streetAddress2 === partner?.profile?.mailingStreetAddress2 &&
    partner?.profile?.city === partner?.profile?.mailingCity &&
    partner?.profile?.state === partner?.profile?.mailingState &&
    partner?.profile?.zipcode === partner?.profile?.mailingZipcode
  )
    return false;

  return true;
};

const inTheFuture = birthMoment => moment().diff(birthMoment, 'years', true) <= 0;
const tooYoung = birthMoment => moment().diff(birthMoment, 'years', true) < 18;
const tooOld = birthMoment => moment().diff(birthMoment, 'years', true) > 122;

export const isValidAdultAge = ({ dateOfBirth }) => {
  const birthMoment = moment(dateOfBirth);
  if (!birthMoment.isValid()) {
    return false;
  }

  return !tooYoung(birthMoment) && !tooOld(birthMoment);
};

export const invalidAgeMessage = ({ dateOfBirth, forUser = true }) => {
  const birthMoment = moment(dateOfBirth);
  if (!birthMoment.isValid()) {
    return `${forUser ? 'Your' : "Your partner's"} birthday must be a valid date.`;
  }
  if (inTheFuture(birthMoment)) {
    return `${
      forUser ? 'Your' : "Your partner's"
    } birth date cannot be in the future (we don't yet support time travelers).`;
  }
  if (tooYoung(birthMoment)) {
    return `${
      forUser ? 'You need' : 'Your partner needs'
    } to be 18 years or older to open an account.`;
  }
  if (tooOld(birthMoment)) {
    return 'Did you know? The oldest known person on record lived 122 years.';
  }

  return null;
};
