/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { useStoreActions } from 'easy-peasy';
import { Box, Flex, Text } from '@chakra-ui/react';
import branch from 'branch-sdk';

import { H3 } from 'shared/typography';

const Processing = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const search = location.search || '';
  const { appId } = querystring.parse(search);
  const { getAccountApplicationStatus } = useStoreActions(actions => actions.auth);

  useEffect(() => {
    branch.logEvent('ApplicationSubmitted');
  }, []);

  useEffect(() => {
    const tracker = setInterval(() => {
      getAccountApplicationStatus({
        variables: { id: appId },
        callback: status => {
          if (status === 'approved') {
            clearInterval(tracker);
            navigate('/auth/congrats');
          } else if (status === 'rejected') {
            clearInterval(tracker);
            navigate('/auth/not-approved');
          } else if (status === 'manual_review') {
            clearInterval(tracker);
            navigate('/auth/need-more-time');
          } else if (status === 'delayed') {
            clearInterval(tracker);
            navigate('/auth/need-more-time');
          }
        },
      });
    }, 3000);

    return () => clearInterval(tracker);
  }, [getAccountApplicationStatus, navigate, appId]);

  return (
    <Flex position="fixed" h="100%" w={['sm', '100%']} align="center" justify="center">
      <Flex position="absolute" direction="column" align="center" justify="center">
        <Text fontSize={['4xl', '8xl']}>
          <span role="img" aria-label="timer">
            ⏲
          </span>
        </Text>
        <Box h={10} />
        <Text textAlign={['center', 'auto']} fontSize={['4xl', '8xl']}>
          Give us a minute.
        </Text>
        <Box h={10} />
        <H3 fontWeight="semibold" textAlign="center">
          We're processing your application.
        </H3>
      </Flex>
    </Flex>
  );
};

export default Processing;
