import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import { signIn } from 'auth/lib/authentication';
import { FAB } from 'shared/buttons';
import { validPassword } from 'shared/lib/inputHelpers';
import toTitleCase from 'shared/lib/toTitleCase';
import { Checkbox, PasswordInput } from 'shared/inputs';
import { H1, H3, Legal, ExternalLink, Paragraph } from 'shared/typography';

function PFMPasswordForm() {
  const navigate = useNavigate();

  const { email, partnerName, firstName } = useStoreState(state => state.auth);
  const { login } = useStoreActions(actions => actions.auth);

  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(true);
  const [saving, setSaving] = useState(false);

  const toggleRemember = () => setRememberMe(value => !value);

  const handleEvent = e => {
    e.preventDefault();
  };

  const createAccount = () => {
    if (password) {
      setSaving(true);
      login({
        variables: { email, password },
        needsOnboarding: afterCreation,
        callback: afterCreation,
        onError: () => setSaving(false),
      });
    }
  };

  const afterCreation = token => {
    setSaving(false);
    signIn(token, rememberMe);
    navigate('/auth/invited/steps/secured');
  };

  return (
    <Layout>
      <H1>
        Hi {toTitleCase(firstName)} welcome to joint banking with{' '}
        {toTitleCase(partnerName)}.
      </H1>
      <Box h={10} />
      <H3>Use your Zeta password to login.</H3>
      <Box h={15} />
      <form onSubmit={handleEvent}>
        <PasswordInput
          id="userPassword"
          label="Password"
          autoComplete="current-password"
          value={password}
          onChange={setPassword}
          onEnter={createAccount}
        />
        <Box h={15} />
        <Checkbox id="remember" value={rememberMe} onChange={toggleRemember}>
          <Paragraph color="primary">Remember me</Paragraph>
        </Checkbox>
        <Box h={26} />
        <Box position="absolute" right={0} top={[80, 78]}>
          <Flex justify="flex-end">
            <FAB
              isLoading={saving}
              isDisabled={!validPassword(password)}
              onClick={createAccount}
            />
          </Flex>
          <Box h={8} />
          <Flex justify="flex-end">
            <Legal>
              By creating an account, you agree to Zeta’s{' '}
              <ExternalLink as="i" href="https://www.askzeta.com/privacy-policy">
                Privacy Policy
              </ExternalLink>{' '}
              &{' '}
              <ExternalLink as="i" href="https://www.askzeta.com/terms-of-use">
                Terms of Use
              </ExternalLink>
              .
            </Legal>
          </Flex>
        </Box>
        <Box h={20} />
      </form>
    </Layout>
  );
}

export default PFMPasswordForm;
