import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Stack } from '@chakra-ui/react';

import { STATES } from 'auth/lib/constants';
import { Button } from 'shared/buttons';
import { validPhone, validEmail } from 'shared/lib/inputHelpers';
import { PhoneInput, Select, TextInput } from 'shared/inputs';

const ReviewContactForm = () => {
  const navigate = useNavigate();
  const { user } = useStoreState(state => state.auth);
  const { editUser, zipLookup } = useStoreActions(actions => actions.auth);

  const [firstName, setFirstName] = useState(user?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.lastName ?? '');
  const [email, setEmail] = useState(user?.email ?? '');
  const [phone, setPhone] = useState(user?.phoneNumber ?? '');

  const [addy, setAddy] = useState(user?.profile?.streetAddress1 ?? '');
  const [apt, setApt] = useState(user?.profile?.streetAddress2 ?? '');
  const [zip, setZip] = useState(user?.profile?.zipcode ?? '');
  const [city, setCity] = useState(user?.profile?.city ?? '');
  const [state, setState] = useState(user?.profile?.state ?? '');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState({});

  const afterZip = () => addy && zip && zip.toString().length === 5;

  const handlePhoneChange = value => {
    setError({});
    setPhone(value);
  };

  const handleEmailChange = value => {
    setEmail(value.toLowerCase());
    setError({});
  };

  const handleEvent = e => e.preventDefault();

  const validate = () => {
    const valZip = zip && zip.toString().length === 5;
    return (
      !!firstName &&
      !!lastName &&
      validPhone(phone) &&
      addy &&
      city &&
      state &&
      valZip &&
      validEmail(email)
    );
  };

  const saveInfo = () => {
    if (validate) {
      setSaving(true);
      editUser({
        variables: {
          firstName,
          lastName,
          email,
          phoneNumber: phone,
          streetAddress1: addy,
          streetAddress2: apt,
          zipcode: zip,
          city,
          state,
          skipAddressVerify: true,
        },
        callback: () => {
          setSaving(false);
          navigate(-1);
        },
        onError: err => {
          setSaving(false);
          setError(err);
        },
      });
    }
  };

  const lookUpZip = () => {
    if (afterZip())
      zipLookup({
        variables: { zipcode: zip, addressPrefix: addy },
        callback: zipInfo => {
          setCity(zipInfo.city);
          setState(zipInfo.stateAbbr);
        },
      });
  };

  return (
    <form onSubmit={handleEvent}>
      <Stack direction={['column', 'column', 'column', 'row']} spacing={10}>
        <TextInput
          id="userFirst"
          label="First Name"
          autoComplete="given-name"
          value={firstName}
          error={error?.firstName}
          onChange={setFirstName}
          placeholder="Chrissy"
        />

        <TextInput
          id="userLast"
          label="Last Name"
          autoComplete="family-name"
          value={lastName}
          error={error?.lastName}
          onChange={setLastName}
          placeholder="Cooks"
        />
      </Stack>
      <Box h={10} />
      <Stack direction={['column', 'column', 'column', 'row']} spacing={10}>
        <TextInput
          id="userEmail"
          type="email"
          label="Email"
          autoComplete="email"
          error={error?.email}
          value={email}
          onChange={handleEmailChange}
          placeholder="chrissy@cooks.com"
        />
        <PhoneInput
          id="userPhone"
          label="Phone Number"
          error={error?.phoneNumber}
          value={phone}
          onChange={handlePhoneChange}
        />
      </Stack>
      <Box h={10} />
      <Stack direction={['column', 'column', 'column', 'row']} spacing={10}>
        <TextInput
          id="street"
          label="Street"
          autoComplete="address-line1"
          value={addy}
          onChange={setAddy}
          error={error?.streetAddress1}
          placeholder="1234 Happy Ave"
        />
        <TextInput
          id="apt"
          label="Apt/Suite"
          autoComplete="address-line2"
          value={apt}
          onChange={setApt}
          error={error?.streetAddress2}
          placeholder="L"
        />
      </Stack>
      <Box h={10} />
      <Stack direction={['column', 'column', 'column', 'row']} spacing={10}>
        <TextInput
          id="city"
          label="City"
          autoComplete="address-level2"
          value={city}
          error={error?.city}
          onChange={setCity}
        />
        <Select
          id="state"
          label="State"
          autoComplete="address-level1"
          options={STATES}
          value={state}
          error={error?.state}
          onChange={setState}
        />
      </Stack>
      <Box h={7} />
      <TextInput
        id="zip"
        label="Zip"
        maxLength={5}
        pattern="[0-9]*"
        autoComplete="postal-code"
        value={zip}
        onChange={val => setZip(val.replace(/\D/g, '').substring(0, 5))}
        onBlur={lookUpZip}
        onEnter={lookUpZip}
      />
      <Box h={15} />
      <Button maxW={67} isLoading={saving} isDisabled={!validate()} onClick={saveInfo}>
        Save changes
      </Button>
    </form>
  );
};

export default ReviewContactForm;
