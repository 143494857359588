import React from 'react';
import { Icon } from '@chakra-ui/react';

const CheckMarkIcon = props => (
  <Icon viewBox="0 0 18 18" {...props}>
    <g fill="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
        fill="currentColor"
      />
      <path
        d="M5 9.45664L7.54908 12.1753L12.4817 6"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </Icon>
);

export default CheckMarkIcon;
