import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import PasswordValidations from 'auth/components/PasswordValidations';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { validPassword } from 'shared/lib/inputHelpers';
import { PasswordInput } from 'shared/inputs';
import { H1, Legal, ExternalLink } from 'shared/typography';

function CreateNewPasswordForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'create-password' });

  const { editPassword } = useStoreActions(actions => actions.auth);

  const [password, setPassword] = useState('');
  const [viewValidations, showValidations] = useState(false);
  const [saving, setSaving] = useState(false);

  const showVals = () => showValidations(true);

  const submit = () => {
    if (validPassword(password)) {
      setSaving(true);
      editPassword({
        variables: { newPassword: password },
        callback: afterCreation,
        onError: () => setSaving(false),
      });
    }
  };
  const afterCreation = () => {
    setSaving(false);
    navigate('/auth/invited/steps/secured');
  };

  const handleEvent = e => e.preventDefault();

  return (
    <Layout>
      <H1>And set a strong password.</H1>
      <Box h={15} />
      <form onSubmit={handleEvent}>
        <PasswordInput
          id="userPassword"
          label="Password"
          autoComplete="new-password"
          value={password}
          onFocus={showVals}
          onChange={setPassword}
          onEnter={submit}
        />
        <PasswordValidations viewValidations={viewValidations} password={password} />
        <Box h={26} />
        <Box position="absolute" right={0} top={[80, 78]}>
          <Flex justify="flex-end">
            <FAB
              isLoading={saving}
              isDisabled={!validPassword(password)}
              onClick={submit}
            />
          </Flex>
          <Box h={8} />
          <Flex justify="flex-end">
            <Legal>
              By creating an account, you agree to Zeta’s{' '}
              <ExternalLink as="i" href="https://www.askzeta.com/privacy-policy">
                Privacy Policy
              </ExternalLink>{' '}
              &{' '}
              <ExternalLink as="i" href="https://www.askzeta.com/terms-of-use">
                Terms of Use
              </ExternalLink>
              .
            </Legal>
          </Flex>
        </Box>
        <Box h={20} />
      </form>
    </Layout>
  );
}

export default CreateNewPasswordForm;
