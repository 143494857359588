import React, { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import { Box, Button as CkButton, Flex, Image, Text } from '@chakra-ui/react';

import AppleBadge from 'auth/lib/images/AppleBadge.png';
import GooglePlayBadge from 'auth/lib/images/GooglePlayBadge.png';
import { appleLocation, playLocation } from 'auth/lib/utils';
import { Button } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { H3 } from 'shared/typography';

const Final = () => {
  useAnalytics({ name: 'download-app' });

  const { sendSms } = useStoreActions(actions => actions.auth);

  useEffect(() => {
    sendSms();
  }, [sendSms]);

  const handleEmail = () => {
    window.location = 'mailto: vip@askzeta.com';
  };

  const onAppleClick = e => {
    e.preventDefault();
    window.location.href = appleLocation;
  };

  const onGoogleClick = e => {
    e.preventDefault();
    window.location.href = playLocation;
  };

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg="greenLight"
      justify="center"
      align="center"
    >
      <Text fontSize="8xl">
        <span role="img" aria-label="phone">
          📱
        </span>
      </Text>
      <Box h={10} />
      <Text textAlign={['center', 'auto']} fontSize={['4xl', '8xl']}>
        Joint Accounts are only available on mobile.
      </Text>
      <Box h={15} />
      <H3 fontWeight="semibold" textAlign="center" maxW={70} px={5}>
        We've texted you a link to the Zeta app.
      </H3>
      <H3 fontWeight="semibold" textAlign="center" maxW={70} px={5}>
        Download the app to enjoy the full experience.
      </H3>

      <Box h={20} />

      <Flex justify="center" wrap>
        <CkButton variant="unstyled" onClick={onGoogleClick} mr={8}>
          <Image src={GooglePlayBadge} w="130px" h="42px" />
        </CkButton>
        <CkButton variant="unstyled" onClick={onAppleClick}>
          <Image src={AppleBadge} w="130px" h="42px" />
        </CkButton>
      </Flex>

      <Box h={20} />
      <Button outlined w={68} onClick={handleEmail}>
        Need Help?
      </Button>
    </Flex>
  );
};

export default Final;
