import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Stack } from '@chakra-ui/react';

import { STATES } from 'auth/lib/constants';
import { Button } from 'shared/buttons';
import ErrorBox from 'shared/components/ErrorBox';
import { Select, TextInput } from 'shared/inputs';

const ReviewMailingForm = () => {
  const navigate = useNavigate();
  const { partner } = useStoreState(state => state.auth);
  const { editPartner, zipLookup } = useStoreActions(actions => actions.auth);

  const [addy, setAddy] = useState(partner?.profile?.mailingStreetAddress1 ?? '');
  const [apt, setApt] = useState(partner?.profile?.mailingStreetAddress2 ?? '');
  const [zip, setZip] = useState(partner?.profile?.mailingZipcode ?? '');
  const [city, setCity] = useState(partner?.profile?.mailingCity ?? '');
  const [state, setState] = useState(partner?.profile?.mailingState ?? '');
  const [saving, setSaving] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const afterZip = () => addy && apt && zip.toString().length === 5;

  const validate = () => {
    const valZip = zip && zip.toString().length === 5;
    return addy && city && state && valZip;
  };

  const handleEvent = e => {
    e.preventDefault();
  };

  const saveInfo = () => {
    if (validate()) {
      setSaving(true);
      editPartner({
        variables: {
          mailingStreetAddress1: addy,
          mailingStreetAddress2: apt,
          mailingZipcode: zip,
          mailingCity: city,
          mailingState: state,
          skipAddressVerify: true,
          mailingSkipAddressVerify: true,
        },
        callback: () => {
          setSaving(false);
          navigate(-1);
        },
        onError: err => {
          setSaving(false);
          err?.mailingStreetAddress1 && setInvalid(true);
        },
      });
    }
  };

  const lookUpZip = () => {
    if (afterZip())
      zipLookup({
        variables: { zipcode: zip, addressPrefix: addy },
        callback: zipInfo => {
          setCity(zipInfo.city);
          setState(zipInfo.stateAbbr);
        },
      });
  };

  return (
    <form onSubmit={handleEvent}>
      {invalid && <ErrorBox>Hmm, we couldn't verify that address.</ErrorBox>}
      <Stack direction={['column', 'column', 'column', 'row']} spacing={10}>
        <TextInput
          id="street "
          label="Street"
          autoComplete="address-line1"
          value={addy}
          onChange={setAddy}
          placeholder="1234 Happy Ave"
        />
        <TextInput
          id="apt"
          label="Apt/Suite"
          autoComplete="address-line2"
          value={apt}
          onChange={setApt}
          placeholder="L"
        />
      </Stack>
      <Box h={10} />
      <Stack direction={['column', 'column', 'column', 'row']} spacing={10}>
        <TextInput
          id="city"
          label="City"
          autoComplete="address-level2"
          value={city}
          onChange={setCity}
        />
        <Select
          id="state"
          label="State"
          autoComplete="address-level1"
          options={STATES}
          value={state}
          onChange={setState}
        />
      </Stack>
      <Box h={7} />
      <TextInput
        id="zip"
        label="Zip"
        maxLength={5}
        pattern="[0-9]*"
        autoComplete="postal-code"
        value={zip}
        onChange={val => setZip(val.replace(/\D/g, '').substring(0, 5))}
        onBlur={lookUpZip}
      />
      <Box h={15} />
      <Button maxW={67} isDisabled={!validate()} isLoading={saving} onClick={saveInfo}>
        Save changes
      </Button>
    </form>
  );
};

export default ReviewMailingForm;
