import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import PatriotActModal from 'auth/components/PatriotActModal';
import SSNModal from 'auth/components/SSNModal';
import { LockIcon } from 'auth/icons';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import ShowError from 'shared/components/ShowError';
import isPresent from 'shared/lib/isPresent';
import { SSNInput } from 'shared/inputs';
import { H1, H3, H6, Legal } from 'shared/typography';

function PartnerSSNForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'pass-SSN' });

  const { editPartner, getToken, setPartnerLastFour } = useStoreActions(
    actions => actions.auth,
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isSSNOpen, onOpen: onSSNOpen, onClose: onSSNClose } = useDisclosure();

  const [ssn, setSSN] = useState('');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState({});

  const validateSsnFormat = value => {
    const uniqueIntegers = value
      .split('')
      .filter((int, index, arr) => arr.indexOf(int) === index);
    return uniqueIntegers.length > 1;
  };
  const validate = () => ssn && ssn.toString().length === 9 && validateSsnFormat(ssn);

  const handleEvent = e => {
    e.stopPropagation();
    e.preventDefault();
  };
  const handleSsnChange = value => {
    setSSN(value);
    setError({});

    if (value.toString().length === 9 && !validateSsnFormat(value)) {
      setError({ message: 'Sorry, we require a valid SSN to sign up.' });
    }
  };
  const saveInfo = () => {
    if (validate()) {
      setSaving(true);
      getToken({
        forPartner: true,
        variables: { type: 'ssn', data: ssn },
        callback: token => {
          editPartner({
            variables: { ssnToken: token, ssnLast4: ssn.slice(ssn.length - 4) },
            callback: () => {
              setSaving(false);
              setPartnerLastFour(ssn.toString().substring(5));
              navigate('/auth/partner/review');
            },
          });
        },
        onError: ({ error: message }) => {
          setSaving(false);
          setError({ message });
        },
      });
    }
  };

  return (
    <>
      <PatriotActModal isOpen={isOpen} onClose={onClose} />
      <SSNModal isOpen={isSSNOpen} onClose={onSSNClose} />
      <Layout>
        <H1>Let’s confirm your identity.</H1>
        <Box h={10} />
        {isPresent(error) ? (
          <ShowError error={error} />
        ) : (
          <>
            <H3 maxW={80}>
              The{' '}
              <button type="button" style={buttonStyle} onClick={onOpen}>
                <H3 color="greenPea" textDecoration="underline">
                  US Patriot Act
                </H3>
              </button>{' '}
              requires that we collect your SSN to verify your identity.
            </H3>
            <H3>This won’t affect your credit score.</H3>
          </>
        )}
        <Box h={15} />
        <form onSubmit={handleEvent}>
          <SSNInput
            id="ssn"
            label="Enter your SSN"
            autoComplete="off"
            value={ssn}
            onChange={handleSsnChange}
            onEnter={saveInfo}
          />
          <Flex bg="icebergWTrans" w={67} rounded="md" p={2} mt={2} align="center">
            <H6>This information is encrypted using SSL.</H6>
            <LockIcon />
          </Flex>
          <Box h={36} />
          <Box position="absolute" right={0} top={[80, 78]}>
            <Flex justify="flex-end">
              <FAB isLoading={saving} isDisabled={!validate()} onClick={saveInfo} />
            </Flex>
            <Box h={10} />
            <Flex justify="flex-end">
              <button type="button" style={buttonStyle} onClick={onSSNOpen}>
                <Legal>Why do you ask for my social security number?</Legal>
              </button>
            </Flex>
          </Box>
          <Box h={20} />
        </form>
      </Layout>
    </>
  );
}

const buttonStyle = { outline: 'none' };

export default PartnerSSNForm;
