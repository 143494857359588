import gql from 'graphql-tag';

export default gql`
  mutation deleteCounterparty($input: DeleteCounterpartyInput!) {
    deleteCounterparty(input: $input) {
      errors {
        path
        message
      }
      ok
    }
  }
`;
