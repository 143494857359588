import { useEffect } from 'react';
import querystring from 'query-string';
import { useStoreActions } from 'easy-peasy';
import { signIn } from 'auth/lib/authentication';
import { useNavigate, useLocation } from 'react-router-dom';

const useMagicLink = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { getUser } = useStoreActions(actions => actions.auth);

  useEffect(() => {
    const { authToken, ...queryParams } = querystring.parse(location.search || '');

    if (authToken) {
      signIn(authToken);

      getUser({
        callback: () => {
          navigate(`${location.pathname}?${querystring.stringify(queryParams)}`);
        },
      });
    }
  }, [location, getUser, navigate]);

  return null;
};

export default useMagicLink;
