import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';

import { differentAddress } from 'auth/lib/utils';
import { Button } from 'shared/buttons';
import { Checkbox } from 'shared/inputs';
import { formatToPhone } from 'shared/lib/inputHelpers';
import ErrorBox from 'shared/components/ErrorBox';
import {
  Caption,
  H2,
  H3,
  ExternalLink,
  InternalLink,
  Label,
  Legal,
} from 'shared/typography';

function Review() {
  const navigate = useNavigate();
  const { partner, partnerLastFour } = useStoreState(state => state.auth);
  const { submitPartnerApp } = useStoreActions(actions => actions.auth);

  const [firstAgree, setFirstAgree] = useState(false);
  const [secondAgree, setSecondAgree] = useState(false);
  const [thirdAgree, setThirdAgree] = useState(false);
  const [fourthAgree, setFourthAgree] = useState(false);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const toggleFirst = () => setFirstAgree(agree => !agree);
  const toggleSecond = () => setSecondAgree(agree => !agree);
  const toggleThird = () => setThirdAgree(agree => !agree);
  const toggleFourth = () => setFourthAgree(agree => !agree);
  const submitApp = () => {
    setSaving(true);
    setError(null);
    submitPartnerApp({
      onComplete: () => {
        setSaving(false);
        navigate('/auth/submit-application');
      },
      onPartnerNeeded: () => {
        setSaving(false);
        navigate('/auth/final');
      },
      onError: () => {
        setError(
          'Sorry, something went wrong submitting your application. Please try again.',
        );
        setSaving(false);
      },
    });
  };

  const allAgree = firstAgree && secondAgree && thirdAgree && fourthAgree;

  return (
    <>
      {error && <ErrorBox>{error}</ErrorBox>}
      <H3 color="iceberg" maxW={74}>
        We’ll need you to confirm that this info matches your government-issued ID (eg.
        Driver's License or Passport).
      </H3>
      <Box h={12} />
      <Box maxW="lg">
        <Flex justify="space-between">
          <Label color="greenLight">Contact</Label>
          <InternalLink color="greenLight" to="/auth/partner/review/contact">
            Edit
          </InternalLink>
        </Flex>

        <H2 color="iceberg">{`${partner?.firstName} ${partner?.lastName}`}</H2>
        <H2 color="iceberg">{partner?.email}</H2>
        <H2 color="iceberg">{formatToPhone(partner?.phoneNumber)}</H2>
        <H2 color="iceberg">{`${partner?.profile?.streetAddress1} ${
          partner?.profile?.streetAddress2 || ''
        }`}</H2>
        <H2 color="iceberg">{`${partner?.profile?.city}, ${partner?.profile?.state} ${partner?.profile?.zipcode}`}</H2>
        <Box h={8} />

        <Flex justify="space-between">
          <Label color="greenLight">Date of Birth</Label>
          <InternalLink color="greenLight" to="/auth/partner/review/dob">
            Edit
          </InternalLink>
        </Flex>
        <H2 color="iceberg">
          {partner?.profile?.dateOfBirth
            ? moment(partner?.profile?.dateOfBirth).format('MMMM DD, YYYY')
            : ''}
        </H2>
        <Box h={8} />

        <Flex justify="space-between">
          <Label color="greenLight">Social Security</Label>
          <InternalLink color="greenLight" to="/auth/partner/review/ssn">
            Edit
          </InternalLink>
        </Flex>
        <H2 color="iceberg">{`*** ** ${
          partnerLastFour.length ? partnerLastFour : '****'
        }`}</H2>

        {differentAddress(partner) && (
          <>
            <Box h={6} />

            <Flex justify="space-between">
              <Label color="greenLight">Mailing Address</Label>
              <InternalLink color="greenLight" to="/auth/partner/review/mailing">
                Edit
              </InternalLink>
            </Flex>
            <H2 color="iceberg">{`${partner?.profile?.mailingStreetAddress1} ${
              partner?.profile?.mailingStreetAddress2 || ''
            }`}</H2>
            <H2 color="iceberg">{`${partner?.profile?.mailingCity}, ${partner?.profile?.mailingState} ${partner?.profile?.mailingZipcode}`}</H2>
          </>
        )}

        <Box h={8} />

        <Checkbox
          id="firstAgree"
          borderColor="greenLight"
          value={firstAgree}
          onChange={toggleFirst}
        >
          <Caption color="iceberg">
            I have read & agree to the Zeta’s{' '}
            <ExternalLink
              fontSize={['xxs', 'xs']}
              color="greenLight"
              href="https://www.askzeta.com/privacy-policy"
            >
              Privacy Policy
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink
              fontSize={['xxs', 'xs']}
              color="greenLight"
              href="https://www.askzeta.com/terms-of-use"
            >
              Terms of Use
            </ExternalLink>
            .
          </Caption>
        </Checkbox>
        <Box h={5} />
        <Checkbox
          id="secondAgree"
          borderColor="greenLight"
          value={secondAgree}
          onChange={toggleSecond}
        >
          <Caption color="iceberg">
            I have read & agree to the{' '}
            <ExternalLink
              fontSize={['xxs', 'xs']}
              color="greenLight"
              href="https://www.askzeta.com/e-sign-consent"
            >
              Zeta E-Signature Agreement
            </ExternalLink>{' '}
            and{' '}
            <ExternalLink
              fontSize={['xxs', 'xs']}
              color="greenLight"
              href="https://www.askzeta.com/zeta-piermont-account-agreement"
            >
              Zeta-Piermont Joint Account Agreement
            </ExternalLink>
            .
          </Caption>
        </Checkbox>
        <Box h={5} />
        <Checkbox
          id="thirdAgree"
          borderColor="greenLight"
          value={thirdAgree}
          onChange={toggleThird}
        >
          <Caption color="iceberg">
            I understand that my partner and I will legally co-own this account and have
            equal access to the funds.
          </Caption>
        </Checkbox>
        <Box h={3} />
        <Checkbox
          id="fourthAgree"
          borderColor="greenLight"
          value={fourthAgree}
          onChange={toggleFourth}
        >
          <Caption color="iceberg">
            I confirm that I am a U.S. citizen or a Resident Alien and that the
            information I have provided is correct.
          </Caption>
        </Checkbox>
      </Box>
      <Box h={15} />
      <Flex direction="column" align="center">
        <Button
          light
          maxW={67}
          isLoading={saving}
          isDisabled={!allAgree}
          onClick={submitApp}
        >
          Submit our application
        </Button>
        <Legal color="iceberg" align="center" maxW={68} pt={10}>
          By applying for Zeta Joint Account, you are providing your contact information
          to both Piermont and Zeta for their own separate purposes.
        </Legal>
      </Flex>
      <Box h={32} />
    </>
  );
}

export default Review;
