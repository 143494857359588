import React, { useState, useEffect } from 'react';
import querystring from 'query-string';
import { useStoreActions } from 'easy-peasy';
import { useLocation } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';

import LinkLoader from 'plaid/components/LinkLoader';
import { H1, H2, Paragraph } from 'shared/typography';

import {
  logEvent,
  onExit,
  retrieveLinkToken,
  retrieveReturnTo,
  retrieveExitLocation,
  retrieveIncomingParams,
  storeLinkToken,
  storeReturnTo,
  storeExitTo,
  storeIncomingParams,
} from 'plaid/lib/utils';

// This component integration has a few things to note:
// - Link requires re-using a link token when redirected back from oAuth
//   It should be stored in local storage so it can be retrieved when redirected
//   back to re-initialize link
//
// - A returnTo url and onExit set passed from args will be lost during oauth operations as the user
//  Leaves this page and is redirected to it after Link finishes oAuth to re-init.
//  It will need set into local storage and recalled on subsequent trips to ensure
//  proper navigation
//

const WebLinkCounterparty = () => {
  const {
    createExternalAccount,
    createLinkLog,
    createCounterparties,
    relinkPlaid,
    getLinkToken,
  } = useStoreActions(actions => actions.plaid);
  const { setTransferSource } = useStoreActions(actions => actions.transfers);
  const location = useLocation();
  const { returnTo, exitTo } = location?.state;
  // LinkToken is returned by our api, and retrieved from localstorage if available
  const [linkToken, setLinkToken] = useState(retrieveLinkToken());

  // return and exit locations are expected to be navigable urls - relative or fqdn
  // These should retrieve from local storage by default
  const [returnLocation, setReturnLocation] = useState(retrieveReturnTo());
  const [exitLocation, setExitLocation] = useState(retrieveExitLocation());

  // IncomingParams is expected to be an Object
  const [incomingParams, setIncomingParams] = useState(
    querystring.parse(retrieveIncomingParams()) || {},
  );

  // Saving state completes after Link has completed and the API request is made to persist
  const [saving, setSaving] = useState(false);

  // Set reteurnLocation if none stored in localstorage
  useEffect(() => {
    if (!returnLocation) {
      setReturnLocation(returnTo);
      storeReturnTo(returnTo);
    }
  }, [returnTo, returnLocation]);
  // Set exitLocation if none stored in localstorage
  useEffect(() => {
    if (!exitLocation) {
      setExitLocation(exitTo);
      storeExitTo(exitTo);
    }
  }, [exitTo, exitLocation]);

  useEffect(() => {
    // Set incomingParams if none stored in localstorage
    if (!incomingParams || Object.keys(incomingParams).length === 0) {
      setIncomingParams(querystring.parse(location.search));
      storeIncomingParams(location.search);
    }
    // get LinkToken & store if none stored in localstorage
    if (!linkToken) {
      getLinkToken({
        variables: {
          objType: incomingParams?.objType,
          objId: incomingParams?.objId,
          oauthRedirectUri: window.location.href,
        },
        callback: token => {
          storeLinkToken(token);
          setLinkToken(token);
        },
      });
    }
  }, [linkToken, getLinkToken, location.search, incomingParams]);

  const successRedirectLocation = linkedObject => {
    return `${returnLocation}?obj_id=${linkedObject?.id}`;
  };

  const afterPersist = linkedObject => {
    if (linkedObject.transferrableType === 'Counterparty') {
      setTransferSource(linkedObject);
    }
    onExit({
      callback: () => {
        window.location.href = successRedirectLocation(linkedObject);
      },
    });
  };

  const onSuccess = (_token, metadata) => {
    setSaving(true);
    const metadataJson = JSON.stringify(metadata);

    if (incomingParams?.objId) {
      relinkPlaid({
        variables: {
          objId: incomingParams?.objId,
          objType: incomingParams?.objType,
          metadataJson,
        },
        callback: afterPersist,
      });
    } else if (incomingParams?.objType === 'ExternalAccount') {
      createExternalAccount({
        variables: { metadataJson },
        callback: afterPersist,
      });
    } else {
      createCounterparties({
        variables: { metadataJson },
        callback: afterPersist,
      });
    }
  };

  const exitCallback = () => {
    onExit({
      callback: () => {
        window.location.href = exitLocation;
      },
    });
  };

  const onEvent = ({ action = '', eventName = '', error = '', metadata = {} }) => {
    const status = action.substr(action.lastIndexOf(':') + 1).toUpperCase();

    switch (status) {
      case 'CONNECTED':
        return onSuccess({ metadata });
      case 'EVENT':
        return logEvent({ logger: createLinkLog, eventName, metadata });
      case 'EXIT':
        return onExit({
          error,
          metadata,
          callback: exitCallback,
        });
      default:
        return null;
    }
  };

  const config = {
    token: linkToken,
    onSuccess,
    onExit: () => {
      onExit({
        error: null,
        metadata: {},
        callback: exitCallback,
      });
    },
    onEvent,
  };

  return (
    <Flex flex={1} justifyContent="center" alignItems="center" height="100%">
      {saving && (
        <Flex justify="center" direction="column" height="100%">
          <Flex justify="center" direction="column" mt={10} px={4}>
            <H1 fontSize={48} align="center">
              ⏲
            </H1>
            <H2 color="primary" align="center" mt={6}>
              Give us a minute.
            </H2>
            <Box h={4} />
            <Paragraph fontSize={['md', 'sm']} align="center">
              We're setting up your account.
            </Paragraph>
          </Flex>
        </Flex>
      )}
      {!saving && linkToken && <LinkLoader config={config} />}
    </Flex>
  );
};

export default WebLinkCounterparty;
