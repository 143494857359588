import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';

import Layout from 'auth/components/Layout';
import Step from 'auth/components/Step';
import { Progress1Icon } from 'auth/icons';
import { FAB } from 'shared/buttons';
import { useBreakpoint } from 'shared/components/breakpoint';
import useAnalytics from 'shared/components/useAnalytics';
import { H1, H3, H5 } from 'shared/typography';

const IntroSteps = () => {
  const bp = useBreakpoint();
  const navigate = useNavigate();
  useAnalytics({ name: 'welcome-to-zeta' });

  const saveInfo = () => navigate('/auth/welcome');

  return (
    <Layout dark height="100vh">
      {!bp.large && <Progress1Icon position="absolute" right={0} top={3} />}
      <Flex justify="space-between" align="center">
        <H1 color="shared">Welcome to Zeta.</H1>
        {bp.large && <Progress1Icon />}
      </Flex>

      <Box h={10} />
      <H3 color="iceberg" maxW={80}>
        It takes less than 5 minutes to get setup. Once you're done - we’ll text you a
        link to the app so you can enjoy the full Zeta experience.
      </H3>
      <Box h={15} />

      <Step>Secure your account</Step>
      <Box h={10} />
      <Step>Add your information</Step>
      <Box h={10} />
      <Step>Partner’s Information</Step>
      <Box h={10} />
      <Step>Invite your partner</Step>
      <Box h={10} />
      <Step>Submit your application</Step>
      <Box h={10} />
      <Step>Add funds</Step>
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB onClick={saveInfo} autoFocus />
        </Flex>
        <Box h={10} />
        <Flex justify="flex-end">
          <H5 as={Link} to="/auth/login" color="iceberg">
            Already have an account? Login
          </H5>
        </Flex>
      </Box>
    </Layout>
  );
};

export default IntroSteps;
