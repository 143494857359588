import { gql } from '@apollo/client';

export default gql`
  mutation editPassword($input: EditPasswordInput!) {
    editPassword(input: $input) {
      status
      errors {
        message
        path
      }
    }
  }
`;
