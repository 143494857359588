import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import useAnalytics from 'shared/components/useAnalytics';
import { Paragraph } from 'shared/typography';

const NotApproved = () => {
  useAnalytics({ name: 'declined-application' });

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg="blue"
      justify="center"
      align="center"
    >
      <Text fontSize="8xl">
        <span role="img" aria-label="candle">
          🕯
        </span>
      </Text>
      <Box h={10} />
      <Text fontSize={['4xl', '8xl']} textAlign="center">
        Your application was not approved.
      </Text>
      <Box h={10} />
      <Paragraph fontWeight="semibold" textAlign="center" maxW={80}>
        Unfortunately, your application was declined. This can happen for several reasons.
      </Paragraph>
      <Paragraph fontWeight="semibold" textAlign="center">
        We’ve emailed you with more information.
      </Paragraph>
      <Box h={40} />
    </Flex>
  );
};

export default NotApproved;
