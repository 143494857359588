import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import Teams from 'auth/lib/svg/Teams';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { TextInput } from 'shared/inputs';
import { H1, H3 } from 'shared/typography';

function TeamForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'create-team-name' });

  const { user } = useStoreState(state => state.auth);
  const { editUser } = useStoreActions(actions => actions.auth);

  const [team, setTeam] = useState(
    user?.lastName === user?.pendingPartnerLastName
      ? `${user?.lastName}`
      : `${user?.lastName} ${user?.pendingPartnerLastName}`,
  );
  const [saving, setSaving] = useState(false);

  const saveInfo = () => {
    if (team) {
      setSaving(true);
      editUser({
        variables: { teamName: team },
        callback: () => {
          setSaving(false);
          navigate('/auth/steps/partner-added');
        },
      });
    }
  };

  return (
    <Layout>
      <H1>Give yourself a team name.</H1>
      <Box h={10} />
      <H3 maxW={80}>
        Research shows that viewing your money as a team makes you more successful in your
        relationship. And every dream team needs a name.
      </H3>
      <Box h={15} />
      <TextInput
        id="team"
        label="Team Name"
        autoComplete="off"
        value={team}
        onChange={setTeam}
        placeholder="Kimye"
        onEnter={saveInfo}
        maxLength={20}
      />
      <Box h={15} />
      <Teams />
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB isLoading={saving} isDisabled={!team} onClick={saveInfo} />
        </Flex>
      </Box>
      <Box h={20} />
    </Layout>
  );
}

export default TeamForm;
