import space from 'app/theme/foundations/space';

export default {
  ...space,
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  '3xs': '14rem', // 224px
  '2xs': '16rem', // 256px
  xs: '20rem', // 320px
  sm: '24rem', // 380px
  md: '28rem', // 448px
  lg: '35rem', // 560px
  xl: '37rem', // 592px
  '2xl': '42rem', // 672px
  '3xl': '46.25rem', // 768px
  '4xl': '56rem', // 896px
  '5xl': '64rem', // 1024px
  '6xl': '67.5rem', // 1080px
  '7xl': '80rem',
  '8xl': '90rem',
  container: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
};
