import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, HStack, Flex } from '@chakra-ui/react';
import PuffLoader from 'react-spinners/PuffLoader';
import { motion } from 'framer-motion';

import Layout from 'auth/components/Layout';
import { STATES } from 'auth/lib/constants';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import ErrorBox from 'shared/components/ErrorBox';
import { Checkbox, Select, TextInput } from 'shared/inputs';
import { H1, H3, Paragraph } from 'shared/typography';

function AddressForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'address-info' });

  const { user } = useStoreState(state => state.auth);
  const { editUser, zipLookup } = useStoreActions(actions => actions.auth);

  const [streetAddress1, setStreet1] = useState(user?.profile?.streetAddress1 ?? '');
  const [streetAddress2, setStreet2] = useState(user?.profile?.streetAddress2 ?? '');
  const [zipcode, setZip] = useState(user?.profile?.zipcode ?? '');
  const [city, setCity] = useState(user?.profile?.city ?? '');
  const [state, setState] = useState(user?.profile?.state ?? '');
  const [invalid, setInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const [validZip, setValidZip] = useState(false);
  const [checkingZip, setCheckingZip] = useState(false);
  const [saving, setSaving] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChecked = () => setChecked(val => !val);

  const validate = () =>
    streetAddress1 && city && state && zipcode && zipcode.toString().length === 5;

  const afterZip = () => streetAddress1 && zipcode && zipcode.toString().length === 5;

  const saveInfo = () => {
    if (validate()) {
      setSaving(true);
      editUser({
        variables: {
          streetAddress1,
          streetAddress2,
          zipcode,
          city,
          state,
          skipAddressVerify: true,
        },
        callback: () => {
          setSaving(false);
          navigate(
            checked ? '/auth/invited/personal/mailing' : '/auth/invited/personal/ssn',
          );
        },
        onError: err => {
          setSaving(false);
          setErrors(err);
          err?.streetAddress1 && setInvalid(true);
        },
      });
    }
  };

  const lookUpZip = () => {
    if (afterZip()) {
      setCheckingZip(true);
      zipLookup({
        variables: { zipcode, addressPrefix: streetAddress1 },
        callback: zipInfo => {
          setCity(zipInfo.city);
          setState(zipInfo.stateAbbr);
          setCheckingZip(false);
          setValidZip(true);
        },
      });
    }
  };

  return (
    <Layout>
      <H1>{user?.firstName}, where do you live?</H1>
      <Box h={10} />
      {invalid ? (
        <ErrorBox>Hmm, we couldn't verify that address.</ErrorBox>
      ) : (
        <H3>Use the address on your government-issued ID (eg. your Driver’s License).</H3>
      )}

      <Box h={15} />
      <TextInput
        id="street"
        label="Street"
        autoComplete="address-line1"
        value={streetAddress1}
        onChange={setStreet1}
        placeholder="1234 Happy Ave"
        error={errors.streetAddress1}
      />
      <Box h={15} />
      <TextInput
        id="apt"
        label="Apt/Suite"
        autoComplete="address-line2"
        value={streetAddress2}
        onChange={setStreet2}
        placeholder="L"
        error={errors.streetAddress2}
      />
      <Box h={15} />
      <HStack spacing={4}>
        <TextInput
          id="zipCode"
          label="Zip"
          maxLength={5}
          autoComplete="postal-code"
          value={zipcode}
          cWidth="unset"
          pattern="[0-9]*"
          onChange={val => setZip(val.replace(/\D/g, '').substring(0, 5))}
          onBlur={lookUpZip}
          onEnter={lookUpZip}
          placeholder="10001"
        />
        {checkingZip && (
          <Box pt={0}>
            <PuffLoader size={30} color="#98D3B6" loading />
          </Box>
        )}
      </HStack>
      <Box h={15} />
      {validZip && (
        <motion.div initial={initial} animate={animate} transition={transition}>
          <TextInput
            id="city"
            label="City"
            autoComplete="address-level2"
            value={city}
            error={errors.city}
            onChange={setCity}
          />
          <Box h={15} />
          <Select
            id="state"
            label="State"
            autoComplete="address-level1"
            options={STATES}
            value={state}
            error={errors.state}
            onChange={setState}
          />
        </motion.div>
      )}
      {validZip && <Box h={15} />}
      <Checkbox id="agree" value={checked} onChange={handleChecked}>
        <Paragraph color="primary">I have different mailing address.</Paragraph>
      </Checkbox>
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB isLoading={saving} isDisabled={!validate()} onClick={saveInfo} />
        </Flex>
      </Box>
    </Layout>
  );
}
const initial = { opacity: 0 };
const animate = { opacity: 1 };
const transition = { duration: 1.5 };

export default AddressForm;
