export const validLength = password => password && password.length >= 8;
export const validCaps = password => password && password.match(/[A-Z]/) != null;
export const validNumbers = password => password && password.match(/[0-9]/) != null;

export const validPassword = password => {
  if (!password) return false;
  return validLength(password) && validCaps(password) && validNumbers(password);
};

const getNumberParts = phoneNumber => {
  let onlyNumbers = (phoneNumber || '').replace(/\D/g, '');
  if (onlyNumbers[0] === '1') onlyNumbers = onlyNumbers.substring(1);
  const zip = onlyNumbers.substring(0, 3);
  const middle = onlyNumbers.substring(3, 6);
  const last = onlyNumbers.substring(6, 10);
  return { onlyNumbers, zip, middle, last };
};

export const formatToPhone = value => {
  const { onlyNumbers, zip, middle, last } = getNumberParts(value);

  if (onlyNumbers.length > 6) return `(${zip}) ${middle}-${last}`;
  if (onlyNumbers.length > 3) return `(${zip}) ${middle}`;
  if (onlyNumbers.length > 0) return `(${zip}`;

  return '';
};

export const validPhone = value => {
  if (!value) return false;
  const { onlyNumbers, zip } = getNumberParts(value);
  if (
    !onlyNumbers ||
    onlyNumbers.replace(/\D/g, '').length !== 10 ||
    zip === '555' ||
    zip === '666'
  )
    return false;

  return true;
};

const validateEmail = str => {
  // prettier-ignore
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(str).toLowerCase());
};

export const validEmail = email => {
  if (!email || !validateEmail(email)) return false;
  return true;
};

export const formatDay = day => {
  if (day >= 0 && day <= 31) return day;
  return '';
};

export const formatMonth = month => {
  if (month >= 0 && month <= 12) return month;
  return '';
};

export const formatYear = (year, minAge = 18) => {
  if (year <= new Date().getFullYear()) {
    if (year > 999) {
      if (year <= new Date().getFullYear() - minAge) return year;
      return '';
    }
    return year;
  }
  return '';
};
