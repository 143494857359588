import { CounterpartyFields } from 'plaid/graphql/CounterpartyFields';

export const transferFields = `
id
direction
eligibleForRetry
amountInCents
processAt
processedAt
completionEstimate
status
creatorId
frequency
editable
error
errorLocation
errorCta
memo
notes
provider
zetaContext
recurringTransfer {
  id
  dayOne
  dayTwo
}
from {
  ... on Account {
    id
    transferrableType
    balances {
      id
      availableCheckingBalance
      totalBalance
    }
  }
  ... on Counterparty {
    ${CounterpartyFields}
  }
}
fromType
to {
  ... on Account {
    id
    transferrableType
    balances {
      id
      availableCheckingBalance
      totalBalance
    }
  }
  ... on Counterparty {
    ${CounterpartyFields}
  }
}
disbursements {
  id
  effects {
    id
    amountInCents
    to {
      ... on Account {
        id
        transferrableType
        balances {
          id
          availableCheckingBalance
          totalBalance
        }
      }
      ... on Counterparty {
        ${CounterpartyFields}
      }
    }
  }
}
`;
