import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useStoreState, useStoreActions } from 'easy-peasy';

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const { appKeys } = useStoreState(state => state.app);
  const { setUserSource } = useStoreActions(actions => actions.app);

  useEffect(() => {
    setUserSource(window.location.href);
  }, [setUserSource]);

  const goToEmail = () =>
    navigate(appKeys?.globalWaitlistOn ? '/auth/welcome/waitlist' : '/auth/steps/intro');

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg="blue"
      justify="center"
      align="center"
    >
      <motion.div
        animate={animate}
        onAnimationComplete={goToEmail}
        transition={transition}
      >
        <Text fontSize="8xl">
          <span role="img" aria-label="waving hand">
            👋🏽
          </span>
        </Text>
      </motion.div>
      <Box h={10} />
      <Text fontSize="3xl">Hi There!</Text>
    </Flex>
  );
};

const animate = {
  rotate: [
    0, 0, 0, 0, -10, -15, -20, -15, -10, 0, 10, 0, -10, 0, 9, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0,
  ],
};
const transition = { duration: 3 };

export default WelcomeScreen;
