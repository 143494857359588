/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex, Text } from '@chakra-ui/react';

import { Button } from 'shared/buttons';
import { useBreakpoint } from 'shared/components/breakpoint';
import useAnalytics from 'shared/components/useAnalytics';

const RestartPassComputer = () => {
  const bp = useBreakpoint();
  const navigate = useNavigate();
  useAnalytics({ name: 'invite-partner-choice' });

  const { user, partnerName } = useStoreState(state => state.auth);
  const { trackAnalytics } = useStoreActions(actions => actions.app);
  const { abandonPassPhone, sendReminder, resetPartnerPassDevice, getUser } =
    useStoreActions(actions => actions.auth);

  const [saving, setSaving] = useState(false);

  const onPassTheComp = () => {
    setSaving(true);
    resetPartnerPassDevice({
      callback: existingPfm => {
        trackAnalytics({ name: 'continue-pass-the-phone', data: { platform: 'web' } });
        setSaving(false);
        if (user?.partner.onboardingStatus === 'pass-device-pre-password') {
          navigate(existingPfm ? '/auth/partner/create-pfm' : '/auth/partner/create');
        } else {
          navigate(
            !user?.partner.profile.dateOfBirth
              ? '/auth/partner/dob'
              : '/auth/partner/address',
          );
        }
      },
      onError: () => setSaving(false),
    });
  };

  const cancel = () => {
    trackAnalytics({ name: 'abandon-pass-the-phone', data: { platform: 'web' } });
    setSaving(true);
    abandonPassPhone({ callback: () => onTextLink() });
  };

  const onTextLink = () => {
    sendReminder({
      variables: { sendNow: true },
      callback: () => {
        getUser();
        setSaving(false);
        navigate('/auth/sent');
      },
    });
  };

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg="greenLight"
      justify="center"
      align="center"
    >
      <Text fontSize="8xl">
        <span role="img" aria-label="phone">
          🏁
        </span>
      </Text>
      <Box h={10} />
      <Text fontSize={['4xl', '8xl']}>Pass {partnerName} the computer</Text>
      <Box h={50} />
      <Flex
        direction={!bp.large ? 'column' : 'row'}
        justify="space-between"
        align="center"
        w="3xl"
      >
        <Button w={68} isLoading={saving} onClick={onPassTheComp}>
          Continue
        </Button>
        {!bp.large && <Box h={10} />}
        <Button outlined w={68} isLoading={saving} onClick={cancel}>
          Email {partnerName} a secure link instead
        </Button>
      </Flex>
    </Flex>
  );
};

export default RestartPassComputer;
