/* eslint-disable no-shadow */
import { action, thunk } from 'easy-peasy';

import CREATE_TRANSFER from 'transfers/graphql/mutations/CreateTransferMutation';
import { deepOmitTypeName, parseMutationErrors } from 'shared/lib/parseGraphql';
import ESTIMATE_PAYMENT_COMPLETION from 'transfers/graphql/queries/EstimatedPaymentCompletionQuery';

const transfersModel = {
  selectedTransfer: null,
  transferSource: null,
  transferTarget: null,
  amountInCents: 0,

  clearState: action(state => {
    state.selectedTransfer = {};
    state.transferSource = null;
    state.transferTarget = null;
    state.amountInCents = 0;
  }),

  setAmountInCents: action((state, payload) => {
    state.amountInCents = payload;
  }),

  resetTransferProps: action(state => {
    state.amountInCents = 0;
    state.transferSource = null;
    state.transferTarget = null;
  }),

  setTransferSource: action((state, payload) => {
    state.transferSource = payload;
  }),

  setTransferTarget: action((state, payload) => {
    state.transferTarget = payload;
  }),

  createTransfer: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: CREATE_TRANSFER,
      variables: { input: payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.createTransfer?.errors.length === 0) {
      payload?.callback &&
        payload?.callback(cleanResponse.data?.createTransfer?.transfer);
    } else {
      payload?.onError && payload?.onError(parseMutationErrors(cleanResponse));
    }
  }),
  getEstimatedCompletion: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.query({
      query: ESTIMATE_PAYMENT_COMPLETION,
      variables: { ...payload.variables },
    });
    const cleanResponse = deepOmitTypeName(response);
    payload?.callback && payload?.callback(cleanResponse.data?.paymentCompletionEstimate);
  }),
};

export default transfersModel;
