import ReactPixel from 'react-facebook-pixel';
import { PropTypes } from 'prop-types';

export const pixelTrack = ({ event, data = null, custom = false }) => {
  if (custom) {
    ReactPixel.trackCustom(event, data);
  } else {
    ReactPixel.track(event, data);
  }
};

export const initPixel = () => {
  const options = {};
  if (process.env.NODE_ENV === 'development') {
    options.autoConfig = true;
    options.debug = false;
  }
  ReactPixel.init(process.env.REACT_APP_FB_PIXEL_ID, options);
  ReactPixel.pageView();
};

pixelTrack.propTypes = {
  event: PropTypes.string.isRequired,
  data: PropTypes.string,
  custom: PropTypes.bool,
};

pixelTrack.defaultProps = {
  custom: false,
  data: null,
};
