import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { Box, Flex, Text } from '@chakra-ui/react';

import useAnalytics from 'shared/components/useAnalytics';
import { H3 } from 'shared/typography';

const SubmitApp = () => {
  const navigate = useNavigate();
  useAnalytics({ name: 'submit-application' });

  const { submitApplication } = useStoreActions(actions => actions.auth);

  useEffect(() => {
    submitApplication({
      onComplete: appId => navigate(`/auth/processing?appId=${appId}`),
      onError: () => navigate('/auth/resubmit-application'),
    });
  }, [submitApplication, navigate]);

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg="greenPea"
      justify="center"
      align="center"
    >
      <Text fontSize="8xl">
        <span role="img" aria-label="letter">
          💌
        </span>
      </Text>
      <Box h={10} />
      <Text fontSize={['4xl', '8xl']} align="center" color="iceberg">
        Submitting your application.
      </Text>
      <Box h={10} />
      <H3 fontWeight="semibold" align="center" color="iceberg">
        We’re processing your application.
      </H3>
    </Flex>
  );
};

export default SubmitApp;
