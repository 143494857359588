/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';

import Confetti from 'auth/lib/movies/Confetti.mp4';
import FAB from 'shared/buttons/FAB';
import useAnalytics from 'shared/components/useAnalytics';
import { H3 } from 'shared/typography';
import { pixelTrack } from 'shared/components/usePixel';

const CongratsTwo = () => {
  const navigate = useNavigate();
  useAnalytics({ name: 'make-it-rain' });
  pixelTrack({ event: 'ApplicationApproved', custom: true });

  const goToFinal = () => navigate('/auth/final');

  return (
    <Flex position="fixed" h="100%" w="100%">
      <video muted autoPlay style={videoStyle} loop>
        <source src={Confetti} type="video/mp4" />
      </video>
      <Flex
        position="absolute"
        direction="column"
        align="center"
        justify="center"
        width="100%"
        height="100%"
      >
        <Text fontSize="8xl">
          <span role="img" aria-label="celebrate">
            🎉
          </span>
        </Text>
        <Box h={10} />
        <Text fontSize={['4xl', '8xl']}>Congrats!</Text>
        <Box h={10} />
        <H3 fontWeight="semibold" textAlign="center" maxW={70}>
          Download the app to start using your virtual card today.
        </H3>
        <Flex width="90%" justify="flex-end" mt={8} mr={[0, 0, 0, 70]}>
          <FAB onClick={goToFinal} />
        </Flex>
      </Flex>
    </Flex>
  );
};

const videoStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  padding: 'none',
};

export default CongratsTwo;
