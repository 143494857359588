import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { Text } from '@chakra-ui/react';

const InternalLink = ({
  children,
  as,
  color,
  fontSize,
  lineHeight,
  fontWeight,
  ...rest
}) => {
  return (
    <Text
      my={0}
      as={as}
      fontSize={['md', 'lg']}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={color}
      textDecoration="underline"
      {...rest}
    >
      {children}
    </Text>
  );
};

InternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};
InternalLink.defaultProps = {
  as: Link,
  fontSize: 'lg', // 18px
  lineHeight: '6',
  fontWeight: 'semibold',
  color: 'greenPea',
};

export default InternalLink;
