import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';

import Layout from 'auth/components/Layout';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { DateInput } from 'shared/inputs';
import { H1, H3 } from 'shared/typography';
import { invalidAgeMessage, isValidAdultAge } from 'auth/lib/utils';

function PassDOBForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'pass-birthday' });

  const { partner } = useStoreState(state => state.auth);
  const { editPartner } = useStoreActions(actions => actions.auth);

  const [dateOfBirth, setDateOfBirth] = useState(
    partner?.profile?.dateOfBirth ? moment(partner?.profile?.dateOfBirth) : null,
  );
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState({});

  const validate = value => {
    if (!isValidAdultAge({ dateOfBirth: value })) {
      setErrors({ dateOfBirth: invalidAgeMessage({ dateOfBirth }) });
    } else {
      setErrors({});
    }
  };
  const saveInfo = () => {
    if (!isValidAdultAge({ dateOfBirth })) {
      validate(dateOfBirth);
      return;
    }
    setSaving(true);
    editPartner({
      variables: { dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD') },
      callback: () => {
        setSaving(false);
        navigate('/auth/partner/address');
      },
    });
  };

  return (
    <Layout>
      <H1>When is your birthday?</H1>
      <Box h={10} />
      <H3>
        You’ll need to be at least 18 years and a US citizen or legal resident to get a
        Zeta Joint Card.
      </H3>
      <Box h={15} />
      <DateInput
        id="dob"
        label="Date of Birth"
        autoComplete="bday"
        error={errors.dateOfBirth}
        selected={dateOfBirth}
        onChange={value => {
          setDateOfBirth(value);
          validate(value);
        }}
        onSelect={validate}
        onBlur={() => validate(dateOfBirth)}
        onEnter={saveInfo}
      />
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB
            isLoading={saving}
            isDisabled={!isValidAdultAge({ dateOfBirth })}
            onClick={saveInfo}
          />
        </Flex>
      </Box>
      <Box h={20} />
    </Layout>
  );
}

export default PassDOBForm;
