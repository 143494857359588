import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';

import { Button } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { H3 } from 'shared/typography';

const NeedMoreTime = () => {
  const navigate = useNavigate();
  useAnalytics({ name: 'manual-review' });

  const goToFinal = () => navigate('/auth/final');

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg="blue"
      justify="center"
      align="center"
    >
      <Text fontSize="8xl">
        <span role="img" aria-label="sand clock">
          ⏳
        </span>
      </Text>
      <Box h={10} />
      <Text fontSize={['4xl', '8xl']}>We need more time.</Text>
      <Box h={10} />
      <H3 fontWeight="semibold" textAlign="center" maxW={70}>
        We’re still crossing a few Ts and dotting a few Is. <br />
        We’ll follow up with you both via email within 1 business day.
      </H3>
      <Box h={40} />
      <Flex justify="center" w="3xl">
        <Button w={68} onClick={goToFinal}>
          Download Zeta App
        </Button>
      </Flex>
    </Flex>
  );
};

export default NeedMoreTime;
