import { gql } from '@apollo/client';

import { userFields } from 'auth/graphql/UserFields';

export default gql`
  query currentUser {
    currentUser {
      ${userFields}
    }
  }
`;
