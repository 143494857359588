import { action, createStore } from 'easy-peasy';

import axios from 'axios';

import { client } from 'app/config/Apollo';
import { storeModel } from 'app/config/Store';

let initialState = {};

const store = createStore(
  { ...storeModel, reset: action(() => ({ ...initialState })), ...initialState },
  { injections: { client, axios, errorLog: () => {} } },
);

initialState = store.getState();

export default store;
