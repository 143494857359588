import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { Flex } from '@chakra-ui/react';

import NoMatch from 'app/pages/NoMatch';

import AuthRoute from 'auth/components/AuthRoute';

import CheckFBOWaitList from 'auth/components/primary/CheckFBOWaitList';
import CheckEmailForm from 'auth/components/primary/CheckEmailForm';
import NewPasswordForm from 'auth/components/primary/NewPasswordForm';
import CreateAccountPFM from 'auth/components/primary/CreateAccountPFM';
import LogIn from 'auth/components/prelude/LogIn';
import NameForm from 'auth/components/primary/NameForm';
import AddressForm from 'auth/components/primary/AddressForm';
import AddMoney from 'auth/components/primary/AddMoney';
import FinalizeTransfer from 'auth/components/primary/FinalizeTransfer';

import MailingForm from 'auth/components/primary/MailingForm';
import DOBForm from 'auth/components/primary/DOBForm';
import SSNForm from 'auth/components/primary/SSNForm';
import Review from 'auth/components/review/Review';
import PartnerInfoForm from 'auth/components/primary/PartnerInfoForm';
import PartnerDOBForm from 'auth/components/primary/PartnerDOBForm';
import TeamForm from 'auth/components/primary/TeamForm';
import VerifyInviteForm from 'auth/components/primary/VerifyInviteForm';

import ReviewSummary from 'auth/components/review/ReviewSummary';
import ReviewContactForm from 'auth/components/review/ReviewContactForm';
import ReviewDOBForm from 'auth/components/review/ReviewDOBForm';
import ReviewSSNForm from 'auth/components/review/ReviewSSNForm';
import ReviewMailingForm from 'auth/components/review/ReviewMailingForm';

import IntroSteps from 'auth/components/steps/IntroSteps';
import InfoAdded from 'auth/components/steps/InfoAdded';
import AccountSecured from 'auth/components/steps/AccountSecured';
import PartnerInfoAdded from 'auth/components/steps/PartnerInfoAdded';
import ApplicationSubmitted from 'auth/components/steps/ApplicationSubmitted';

import CreateAccountForm from 'auth/components/pass-computer/CreateAccountForm';
import CreatePFMAccountForm from 'auth/components/pass-computer/CreatePFMAccountForm';
import PassDOBForm from 'auth/components/pass-computer/PassDOBForm';
import PartnerAddressForm from 'auth/components/pass-computer/PartnerAddressForm';
import PartnerMailingForm from 'auth/components/pass-computer/PartnerMailingForm';
import PartnerSSNForm from 'auth/components/pass-computer/PartnerSSNForm';

import SideImages from 'auth/components/SideImages';
import { useBreakpoint } from 'shared/components/breakpoint';
import { initPixel } from 'shared/components/usePixel';

const PrimaryOnboarding = () => {
  const bp = useBreakpoint();
  const location = useLocation();
  initPixel();

  if (process.env.REACT_APP_GTM_ID && process.env.REACT_APP_GTM_ID !== '') {
    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
    });
  }

  return (
    <Flex>
      {bp.large && <SideImages location={location.pathname} />}

      <Routes>
        <Route path="login" element={<LogIn />} />
        <AuthRoute path="verify" element={<VerifyInviteForm />} />

        <Route path="steps">
          <Route path="intro" element={<IntroSteps />} />
          <AuthRoute checkUser path="secured" element={<AccountSecured />} />
          <AuthRoute path="part-done" element={<InfoAdded />} />

          <AuthRoute path="application-submitted" element={<ApplicationSubmitted />} />
          <AuthRoute path="partner-added" element={<PartnerInfoAdded />} />
        </Route>

        <Route path="welcome">
          <Route path="/" element={<CheckEmailForm />} />
          <Route path="waitlist" element={<CheckFBOWaitList />} />
          <Route path="pfm" element={<CreateAccountPFM />} />
          <Route path="create" element={<NewPasswordForm />} />
        </Route>

        <Route path="personal">
          <AuthRoute checkUser path="name" element={<NameForm />} />
          <AuthRoute checkUser path="address" element={<AddressForm />} />

          <AuthRoute checkUser path="mailing" element={<MailingForm />} />
          <AuthRoute checkUser path="dob" element={<DOBForm />} />
          <AuthRoute checkUser path="ssn" element={<SSNForm />} />
          <AuthRoute checkUser path="review" element={<Review />}>
            <Route path="contact" element={<ReviewContactForm />} />
            <Route path="dob" element={<ReviewDOBForm />} />
            <Route path="ssn" element={<ReviewSSNForm />} />
            <Route path="mailing" element={<ReviewMailingForm />} />
            <Route path="/" element={<ReviewSummary />} />
          </AuthRoute>
          <AuthRoute path="partner-info" element={<PartnerInfoForm />} />
          <AuthRoute path="partner-dob" element={<PartnerDOBForm />} />
          <AuthRoute path="partner-team" element={<TeamForm />} />
        </Route>
        <AuthRoute path="add-money" element={<AddMoney />} />
        <AuthRoute path="finalize-transfer" element={<FinalizeTransfer />} />
        {/* Partner - Pass Computer Flow */}
        <Route path="partner">
          <AuthRoute checkPartner path="create" element={<CreateAccountForm />} />
          <AuthRoute checkPartner path="create-pfm" element={<CreatePFMAccountForm />} />
          <AuthRoute checkPartner path="dob" element={<PassDOBForm />} />
          <AuthRoute checkPartner path="address" element={<PartnerAddressForm />} />
          <AuthRoute checkPartner path="mailing" element={<PartnerMailingForm />} />
          <AuthRoute checkPartner path="ssn" element={<PartnerSSNForm />} />
        </Route>

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Flex>
  );
};

export default PrimaryOnboarding;
