import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import { signIn } from 'auth/lib/authentication';
import { FAB } from 'shared/buttons';
import { TextInput } from 'shared/inputs';
import { H1, H3, Legal } from 'shared/typography';

const ConfirmForgotPassForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const search = location.search || '';
  const { email } = querystring.parse(search);

  const { verifyPasswordReset, setTempPass, sendPasswordReset } = useStoreActions(
    actions => actions.auth,
  );

  const [code, setCode] = useState('');
  const [showError, setShowError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [didResend, setDidResend] = useState(false);

  const handleCodeChange = value => {
    setShowError(false);
    setCode(value);
  };

  const verifyCode = () => {
    setShowError(false);
    setSaving(true);
    verifyPasswordReset({
      variables: { code },
      callback: afterVerification,
      onError: () => {
        setSaving(false);
        setShowError(true);
      },
    });
  };

  const afterVerification = response => {
    setSaving(false);
    const { authToken, tempPass } = response;
    setTempPass(tempPass);
    signIn(authToken, true);
    navigate(`/auth/forgot-password/new-password?email=${encodeURIComponent(email)}`);
  };

  const resendCode = () => {
    setDidResend(true);
    sendPasswordReset({
      variables: { email: decodeURIComponent(email) },
    });
  };

  return (
    <>
      <H1>Let's get you back in.</H1>
      <Box h={10} />
      <H3>
        Enter the code from the email you just received. It may take a minute or two.
      </H3>
      <Box h={15} />
      <TextInput
        id="secretCode"
        type="password"
        label="Top secret code"
        autoComplete="one-time-code"
        value={code}
        error={showError ? errorMsg : ''}
        onChange={handleCodeChange}
        onEnter={verifyCode}
        placeholder="Your secret code"
      />
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB isLoading={saving} isDisabled={!code} onClick={verifyCode} />
        </Flex>
        <Box h={8} />
        {!didResend && (
          <Flex justify="flex-end">
            <button type="button" style={buttonStyle} onClick={resendCode}>
              <Legal as="i">Resend verification code.</Legal>
            </button>
          </Flex>
        )}
      </Box>
    </>
  );
};

const buttonStyle = { outline: 'none' };
const errorMsg = "Whoops, that code isn't right. Try again.";

export default ConfirmForgotPassForm;
