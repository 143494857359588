/* eslint-disable no-console */
import {
  ApolloClient,
  createHttpLink,
  ApolloLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setExtra, captureException } from '@sentry/browser';

import { signOut, getLoginToken } from 'auth/lib/authentication';

const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL || '/graphql' });

const authLink = new ApolloLink((operation, forward) => {
  const token = getLoginToken();
  operation.setContext({ headers: { authorization: token ? `Bearer ${token}` : null } });

  return forward(operation);
});

const formatProblems = (problems = []) =>
  problems.map(({ explanation, path }) => `${path.join(' ')} -- ${explanation}`);

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (process.env.NODE_ENV === 'development') {
    if (graphQLErrors) {
      const { operationName: query, variables } = operation;
      graphQLErrors.forEach(({ locations, message, path, problems }) => {
        console.group('GRAPHQL ERROR');
        console.error('QUERY:', query);
        console.error('VARIABLES:', variables);
        console.error('MESSAGE:', message);
        console.error('PROBLEMS:', formatProblems(problems));
        console.error('LOCATIONS:', locations);
        path && console.error('PATH:', path);
        console.groupEnd();
      });
    }
    if (networkError) {
      console.error(`[GraphQL Network error]: ${networkError}`);
    }
  } else {
    if (graphQLErrors) {
      const { operationName: query, variables } = operation;
      graphQLErrors.forEach(({ locations, message, path, problems }) => {
        setExtra('metadata', { locations, message, path, problems });
        captureException(
          `GQL ERR: ${message} ||PROBLEMS: ${formatProblems(
            problems,
          )}||QUERY: ${query}||VARIABLES: ${JSON.stringify(
            variables,
          )}||LOCATIONS: ${locations}${path && `||PATH:  ${path}`}`,
        );
      });
    }
    if (networkError) {
      captureException(`[GraphQL Network error]: ${JSON.stringify(networkError)}`);
    }
  }

  if (networkError?.result?.ZETA_ERROR === 'EXPIRED_TOKEN') signOut();
});
const responseLogger = new ApolloLink((operation, forward) => {
  if (process.env.NODE_ENV === 'development') {
    const { operationName: query, variables } = operation;

    return forward(operation).map(result => {
      console.log(
        `{
            request: {
              query: ${query},
              variables: ${JSON.stringify(variables)},
            },
            result: ${JSON.stringify(result)},
          },`,
      );
      return result;
    });
  }
  return forward(operation);
});

const link = from([authLink, errorLink, responseLogger, httpLink]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  shouldBatch: true,
  defaultOptions: {
    query: { fetchPolicy: 'network-only' },
    watchQuery: { fetchPolicy: 'network-only' },
    mutate: { fetchPolicy: 'no-cache' },
    subscribe: { fetchPolicy: 'network-only' },
  },
});
