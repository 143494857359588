import gql from 'graphql-tag';

import { ExternalAccountFields } from 'plaid/graphql/ExternalAccountFields';

export default gql`
  mutation createExternalAccount($input: CreateExternalAccountInput!) {
    createExternalAccount(input: $input) {
      errors {
        path
        message
      }
      externalAccount {
        ${ExternalAccountFields}
      }
    }
  }
`;
