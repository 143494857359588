import { gql } from '@apollo/client';

export default gql`
  query UserSentInvitation {
    currentUser {
      id
      invitations {
        id
        email
        phoneNumber
        claimedAt
      }
    }
  }
`;
