import { gql } from '@apollo/client';

import { CounterpartyFields } from 'plaid/graphql/CounterpartyFields';

export default gql`
  mutation editCounterparty($input: EditCounterpartyInput!) {
    editCounterparty(input: $input) {
      errors {
        path
        message
      }
      counterparty {
        ${CounterpartyFields}
      }
    }
  }
`;
