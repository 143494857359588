import { setExtra, captureException } from '@sentry/browser';

const linkTokenKey = 'zjcLinkToken';
const linkReturnKey = 'zjcLinkReturnTo';
const linkExitKey = 'zjcLinkExitTo';
const linkIncomingParams = 'zjcLinkIncomingParams';

export const EXIT_URL = `${process.env.REACT_APP_NATIVE_URL}://counterparty`;
export const CC_EXIT_URL = `${process.env.REACT_APP_NATIVE_URL}://external`;

export const INSTITUTION_NOT_SUPPORTED_ERRORS = [
  'INSTITUTION_NOT_AVAILABLE',
  'INSTITUTION_NO_LONGER_SUPPORTED',
  'INSTITUTION_NOT_SUPPORTED',
  'ITEM_NOT_SUPPORTED',
];

export const logEvent = ({ logger, eventName, metadata }) => {
  let log = true;
  if (
    metadata.error_code !== null &&
    metadata.error_code !== 'INVALID_CREDENTIALS' &&
    metadata.error_code !== 'INVALID_MFA' &&
    metadata.error_code !== 'NO_ACCOUNTS' &&
    !INSTITUTION_NOT_SUPPORTED_ERRORS.includes(metadata.error_code)
  ) {
    setExtra('metadata', { link_data: metadata });
    captureException(metadata.error_message);
  }

  if (eventName === 'SEARCH_INSTITUTION' || eventName === 'TRANSITION_VIEW') {
    if (
      eventName === 'SEARCH_INSTITUTION' &&
      metadata.institution_search_query.length < 3
    ) {
      log = false;
    }
  }
  if (log) {
    logger({
      variables: {
        source: 'web',
        metadataJson: JSON.stringify(metadata),
      },
    });
  }
};

export const storeLinkToken = token => {
  window.localStorage.setItem(linkTokenKey, token);
};

export const storeReturnTo = url => {
  window.localStorage.setItem(linkReturnKey, url);
};

export const storeExitTo = url => {
  window.localStorage.setItem(linkExitKey, url);
};

export const storeIncomingParams = str => {
  window.localStorage.setItem(linkIncomingParams, str);
};

export const clearLinkToken = () => {
  window.localStorage.removeItem(linkTokenKey);
};

export const clearReturnTo = () => {
  window.localStorage.removeItem(linkReturnKey);
};

export const clearExitTo = () => {
  window.localStorage.removeItem(linkExitKey);
};

export const clearIncomingParams = () => {
  window.localStorage.removeItem(linkIncomingParams);
};

export const retrieveLinkToken = () => {
  const token = window.localStorage.getItem(linkTokenKey);
  window.localStorage.removeItem(linkTokenKey);
  return token;
};

export const retrieveReturnTo = () => {
  const returnToLocation = window.localStorage.getItem(linkReturnKey);
  window.localStorage.removeItem(linkReturnKey);
  return returnToLocation;
};

export const retrieveExitLocation = () => {
  const exitToLocation = window.localStorage.getItem(linkExitKey);
  window.localStorage.removeItem(linkExitKey);
  return exitToLocation;
};

export const retrieveIncomingParams = () => {
  const incomingParams = window.localStorage.getItem(linkIncomingParams);
  window.localStorage.removeItem(linkIncomingParams);
  return incomingParams;
};

export const clearAllLinkStorage = () => {
  clearLinkToken();
  clearReturnTo();
  clearExitTo();
  clearIncomingParams();
};

export const onExit = ({ error, metadata, callback }) => {
  if (error) {
    setExtra('metadata', { link_data: metadata });
    captureException(metadata.error_message);
  }
  clearAllLinkStorage();
  callback && callback();
};
