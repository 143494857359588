/* eslint-disable no-shadow */
import { action, thunk } from 'easy-peasy';

import GET_MARQETA_TOKEN from 'cards/graphql/MarqetaTokenQuery';
import { deepOmitTypeName } from 'shared/lib/parseGraphql';

const cardModel = {
  loadingMQToken: false,

  setLoadingMQToken: action((state, payload) => {
    state.loadingMQToken = payload;
  }),

  getMQToken: thunk(async (action, payload, { injections }) => {
    action.setLoadingMQToken(true);
    const { client } = injections;

    const response = await client.query({
      query: GET_MARQETA_TOKEN,
      variables: payload.variables,
    });

    const cleanResponse = deepOmitTypeName(response);
    action.setLoadingMQToken(false);
    payload.callback && payload.callback(cleanResponse.data.marqetaToken);
  }),
};

export default cardModel;
