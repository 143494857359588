import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, InputGroup, InputRightElement } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

import TextInput from 'shared/inputs/TextInput';

const PasswordInput = ({ error, ...rest }) => {
  const [reveal, setReveal] = useState(false);

  const toggleShow = () => setReveal(val => !val);

  return (
    <InputGroup maxW={67}>
      <TextInput
        type={reveal ? 'text' : 'password'}
        width={67}
        pl={0}
        pr="3.5rem"
        borderRadius={0}
        border="none"
        borderBottomColor={error ? 'danger' : 'boulderWTrans'}
        error={error}
        {...rest}
      />
      <InputRightElement w="1.5rem" h="1.5rem" right="1.7rem" top="2.2rem">
        <IconButton
          aria-label="toggle show password"
          icon={reveal ? <ViewOffIcon /> : <ViewIcon />}
          minW="1.5rem"
          h="1.5rem"
          onClick={toggleShow}
        />
      </InputRightElement>
    </InputGroup>
  );
};

PasswordInput.propTypes = { error: PropTypes.string };
PasswordInput.defaultProps = { error: '' };

export default PasswordInput;
