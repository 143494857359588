import { useStoreState, useStoreActions } from 'easy-peasy';

const useSeenFlag = () => {
  const { user } = useStoreState(state => state.auth);
  const { markSeen: hasSeen } = useStoreActions(actions => actions.auth);

  const hasUserSeen = value => user?.seenFtes?.includes(value);
  const hasPartnerSeen = value => user?.partner?.seenFtes?.includes(value);

  const hasCoupleSeen = value =>
    user?.seenFtes?.includes(value) || user?.partner?.seenFtes?.includes(value);

  const markSeen = value => !hasUserSeen(value) && hasSeen({ variables: value });

  return { markSeen, hasUserSeen, hasPartnerSeen, hasCoupleSeen };
};

export default useSeenFlag;
