import { gql } from '@apollo/client';

export default gql`
  query ZipcodeAutocomplete($addressPrefix: String!, $zipcode: String!) {
    zipcodeAutocomplete(addressPrefix: $addressPrefix, zipcode: $zipcode) {
      city
      state
      stateAbbr
      zipcode
    }
  }
`;
