import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import { RELATIONSHIP_STATUS } from 'auth/lib/constants';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { PhoneInput, Select, TextInput } from 'shared/inputs';
import { formatToPhone, validPhone, validEmail } from 'shared/lib/inputHelpers';
import { H1, H3, Paragraph } from 'shared/typography';

function PartnerInfoForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'partner-info' });

  const { user, invitedPartner, inviteSent } = useStoreState(state => state.auth);
  const { invitePartner, editInvitation, setInviteSent, setShortCircuit } =
    useStoreActions(actions => actions.auth);

  const [relationship, setRelationship] = useState(user?.pendingRelationshipStatus ?? '');
  const [firstName, setFirstName] = useState(user?.pendingPartnerFirstName ?? '');
  const [lastName, setLastName] = useState(user?.pendingPartnerLastName ?? '');
  const [email, setEmail] = useState(invitedPartner?.email ?? '');
  const [phone, setPhone] = useState(invitedPartner?.phoneNumber ?? '');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState({});

  const handleEmail = value => {
    setError({});
    setEmail(value.toLowerCase());
  };

  const handleEmailBlur = () => {
    if (!validEmail(email)) {
      setError({ email: 'please enter a valid email address' });
    }
  };

  const handlePhone = value => {
    setError({});
    setPhone(value);
    if (value === formatToPhone(user?.phoneNumber))
      setError({
        phoneNumber: 'please enter a separate number for your partner',
      });
  };

  const validate = () =>
    formatToPhone(user?.phoneNumber) !== phone &&
    validPhone(phone) &&
    validEmail(email) &&
    lastName &&
    firstName &&
    relationship;

  const saveInfo = () => {
    if (validate()) {
      setSaving(true);
      const variables = {
        pendingPartnerFirstName: firstName,
        pendingPartnerLastName: lastName,
        pendingRelationshipStatus: relationship,
        email,
        phoneNumber: phone,
      };
      !inviteSent
        ? invitePartner({ variables, callback, onInvited, onError })
        : editInvitation({ variables, callback, onInvited, onError });
    }
  };

  const callback = () => {
    setSaving(false);
    !inviteSent && setInviteSent(true);
    navigate('/auth/personal/partner-dob');
  };

  const onInvited = () => {
    setSaving(false);
    !inviteSent && setInviteSent(true);
    setShortCircuit(true);
    navigate('/auth/primary/verify');
  };

  const onError = err => {
    setSaving(false);
    setError(err);
  };

  const phoneError = error?.pendingInvite
    ? `That phone number doesn't match what we have on file for ${email}.`
    : '';

  return (
    <Layout>
      <H1>Now, tell us about your partner.</H1>
      <Box h={10} />
      <H3>We’ll use this information to customize your partner’s invite.</H3>
      <Box h={15} />
      <Select
        id="relationship"
        label="Relationship Status"
        options={RELATIONSHIP_STATUS}
        value={relationship}
        onChange={setRelationship}
      />
      <Box h={15} />
      <TextInput
        id="partnerFirstName"
        label="Partner's First Name"
        autoComplete="given-name"
        value={firstName}
        error={error?.pendingPartnerFirstName ?? error?.firstName}
        onChange={setFirstName}
        placeholder="John"
      />
      <Box h={15} />
      <TextInput
        id="partnerLastName"
        label="Partner's Last Name"
        autoComplete="family-name"
        value={lastName}
        error={error?.pendingPartnerLastName ?? error?.lastName}
        onChange={setLastName}
        placeholder="Rockstar"
      />
      <Box h={15} />
      <TextInput
        id="partnerEmail"
        type="email"
        label="Partner's Email"
        autoComplete="email"
        error={error?.email}
        value={email}
        onChange={handleEmail}
        onBlur={handleEmailBlur}
        placeholder="john@rockstar.com"
      />
      <Box h={15} />
      <PhoneInput
        id="partnerPhoneNumber"
        label="Partner's Cell #"
        error={error?.phoneNumber || phoneError}
        value={phone}
        onChange={handlePhone}
        onEnter={saveInfo}
      />
      <Box h={4} />
      <Paragraph color="primary">
        We’ll text your partner a secret code to ensure your security.
      </Paragraph>
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB isLoading={saving} isDisabled={!validate()} onClick={saveInfo} />
        </Flex>
      </Box>
      <Box h={20} />
    </Layout>
  );
}

export default PartnerInfoForm;
