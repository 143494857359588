/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { Box, Flex, Text } from '@chakra-ui/react';

import { Button } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { H3 } from 'shared/typography';

const AheadGame = () => {
  const navigate = useNavigate();
  useAnalytics({ name: 'pass-reroute' });

  const { abandonPassPhone } = useStoreActions(actions => actions.auth);

  const [saving, setSaving] = useState(false);

  const onContinue = () => {
    setSaving(true);
    abandonPassPhone({
      callback: () => {
        setSaving(false);
        navigate('final');
      },
    });
  };

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg="yellowLight"
      justify="center"
      align="center"
    >
      <Text fontSize="8xl">
        <span role="img" aria-label="warning">
          🎌
        </span>
      </Text>
      <Box h={10} />
      <Text fontSize={['4xl', '8xl']}>Ahead of the game!</Text>
      <Box h={10} />
      <H3 fontWeight="semibold" textAlign="center" maxW="4xl">
        Looks like you’ve already started a Zeta Joint Card application. To complete your
        part, we’ll need to text you a secret code.
      </H3>
      <Box h={40} />
      <Flex justify="center" w="3xl">
        <Button w={68} isLoading={saving} onClick={onContinue}>
          Continue
        </Button>
      </Flex>
    </Flex>
  );
};

export default AheadGame;
