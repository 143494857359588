import React from 'react';
import { PropTypes } from 'prop-types';

import { Text } from '@chakra-ui/react';

const H6 = ({ children, as, fontSize, lineHeight, fontWeight, ...rest }) => {
  return (
    <Text
      my={0}
      as={as}
      fontSize={['xs', 'sm']} // 14px
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      {...rest}
    >
      {children}
    </Text>
  );
};

H6.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
};
H6.defaultProps = {
  as: 'h6',
  fontSize: 'sm', // 14px
  lineHeight: '5',
  fontWeight: 'semibold',
};

export default H6;
