import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import PasswordValidations from 'auth/components/PasswordValidations';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { validEmail, validPassword } from 'shared/lib/inputHelpers';
import { TextInput, PasswordInput } from 'shared/inputs';
import { H1, H3, Legal, ExternalLink } from 'shared/typography';

function CreateAccountForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'pass-create-account' });

  const { user, partner } = useStoreState(state => state.auth);
  const { editPassword, editPartner } = useStoreActions(actions => actions.auth);

  const [email, setEmail] = useState(partner?.email ?? '');
  const [password, setPassword] = useState('');
  const [viewValidations, showValidations] = useState(false);
  const [saving, setSaving] = useState(false);
  const [modified, setModified] = useState(false);

  const showVals = () => showValidations(true);
  const handleEmailChange = value => {
    !modified && setModified(true);
    setEmail(value);
  };

  const handleEvent = e => {
    e.preventDefault();
  };
  const handlePassChange = value => {
    setPassword(value);
  };

  const onEmailChange = () => {
    if (modified && validEmail(email)) {
      setSaving(true);
      editPartner({
        variables: { email },
        callback: () => setSaving(false),
        onError: err => {
          setSaving(false);
          if (err.email) navigate('/auth/ahead');
        },
      });
    }
  };

  const createAccount = () => {
    if (validPassword(password)) {
      setSaving(true);
      editPassword({
        variables: { newPassword: password, forPartner: true },
        callback: () => {
          setSaving(false);
          navigate(
            !partner?.profile?.dateOfBirth
              ? '/auth/partner/dob'
              : '/auth/partner/address',
          );
        },
        onError: () => setSaving(false),
      });
    }
  };

  return (
    <Layout>
      <H1>{partner?.firstName}, let’s secure your account.</H1>
      <Box h={10} />
      <H3>Create your personal login. {user?.firstName} has their own account.</H3>
      <Box h={15} />
      <form onSubmit={handleEvent}>
        <TextInput
          id="userEmail"
          type="email"
          label="Email"
          autoComplete="email"
          value={email}
          onChange={handleEmailChange}
          onEnter={onEmailChange}
          onBlur={onEmailChange}
        />
        <Box h={15} />
        <PasswordInput
          id="userPassword"
          label="Password"
          autoComplete="new-password"
          value={password}
          onFocus={showVals}
          onChange={handlePassChange}
          onEnter={createAccount}
        />
        <PasswordValidations viewValidations={viewValidations} password={password} />
        <Box h={26} />
        <Box position="absolute" right={0} top={[80, 78]}>
          <Flex justify="flex-end">
            <FAB
              isLoading={saving}
              isDisabled={!validPassword(password)}
              onClick={createAccount}
            />
          </Flex>
          <Box h={8} />
          <Flex justify="flex-end">
            <Legal>
              By creating an account, you agree to Zeta’s{' '}
              <ExternalLink as="i" href="https://www.askzeta.com/privacy-policy">
                Privacy Policy
              </ExternalLink>{' '}
              &{' '}
              <ExternalLink as="i" href="https://www.askzeta.com/terms-of-use">
                Terms of Use
              </ExternalLink>
              .
            </Legal>
          </Flex>
        </Box>
        <Box h={20} />
      </form>
    </Layout>
  );
}

export default CreateAccountForm;
