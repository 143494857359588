import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Flex } from '@chakra-ui/react';

import SideImages from 'auth/components/SideImages';
import { useBreakpoint } from 'shared/components/breakpoint';
import { initPixel } from 'shared/components/usePixel';

const SecondaryOnboarding = () => {
  const bp = useBreakpoint();
  const location = useLocation();
  initPixel();

  return (
    <Flex>
      {bp.large && <SideImages location={location.pathname} />}

      <Outlet />
    </Flex>
  );
};

export default SecondaryOnboarding;
