/* eslint-disable no-shadow */
import { action, thunk } from 'easy-peasy';

import APP_KEYS from 'app/graphql/AppKeysQuery';
import ANALYTICS_EVENT_MUTATION from 'app/graphql/CreateAnalyticsEventMutation';
import { deepOmitTypeName } from 'shared/lib/parseGraphql';

const isDevelopment = process.env.NODE_ENV === 'development';
const isTest = process.env.NODE_ENV === 'test';

const appModel = {
  appKeys: {},
  userSource: null,

  setAppKeys: action((state, payload) => {
    state.appKeys = payload;
  }),

  setUserSource: action((state, payload) => {
    state.userSource = payload;
  }),

  getKeys: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.query({
      query: APP_KEYS,
      variables: { zetaAppKey: process.env.REACT_APP_APP_KEY },
      fetchPolicy: 'network-only',
    });
    const cleanResponse = deepOmitTypeName(response);
    action.setAppKeys(cleanResponse.data?.appKeys);
  }),

  trackAnalytics: thunk(async (action, payload, { injections }) => {
    if (isTest) return;

    // eslint-disable-next-line no-console
    if (isDevelopment) console.log('MIXPANEL DEBUG:', payload.name, payload?.data);

    const { client } = injections;

    await client.mutate({
      mutation: ANALYTICS_EVENT_MUTATION,
      variables: {
        input: {
          name: payload.name,
          data: JSON.stringify(payload?.data),
          source: 'web',
          target: 'mixpanel',
        },
      },
    });

    payload.callback && payload.callback();
  }),
};

export default appModel;
