import React from 'react';
import { PropTypes } from 'prop-types';

import { TextInput } from 'shared/inputs';
import { toDollars } from 'shared/lib/toDollars';

const MoneyInput = ({ value, onChange, forwardRef, onBlur, rounded, ...rest }) => (
  <TextInput
    label="Date"
    value={value && rounded ? toRoundedDollars(value) : toDollars(value)}
    onBlur={onBlur}
    onChange={text => {
      const returnVal = text.replace(/[^0-9-]/g, '');
      onChange && onChange(parseInt(returnVal, 10));
    }}
    {...rest}
  />
);

const toRoundedDollars = value => {
  const amount = Math.ceil(Math.abs(value));
  return toDollars(amount, { asIs: true, showCents: false });
};

MoneyInput.propTypes = {
  value: PropTypes.number,
  rounded: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  forwardRef: PropTypes.object,
  onBlur: PropTypes.func,
};

MoneyInput.defaultProps = {
  value: null,
  rounded: false,
  forwardRef: null,
  onBlur: null,
};

export default MoneyInput;
