import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, Flex } from '@chakra-ui/react';

import { CheckMarkIcon } from 'shared/icons';
import { H3 } from 'shared/typography';

const Step = ({ children, checked }) => {
  return (
    <Flex align="center">
      {checked ? (
        <CheckMarkIcon color="shared" />
      ) : (
        <Box
          color="shared"
          rounded="full"
          borderWidth="2px"
          opacity="0.5"
          w="18px"
          h="18px"
        />
      )}
      <H3 color="iceberg" ml={4}>
        {children}
      </H3>
    </Flex>
  );
};

Step.propTypes = { children: PropTypes.node.isRequired, checked: PropTypes.bool };
Step.defaultProps = { checked: false };

export default Step;
