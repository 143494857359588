import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Flex, Text } from '@chakra-ui/react';
import querystring from 'query-string';
import { motion } from 'framer-motion';

const WelcomePartner = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goToEmail = () => {
    const { claimToken } = querystring.parse(location.search ?? '');
    const route = claimToken
      ? `/auth/invited/steps/intro-steps?claimtoken=${claimToken}`
      : '/auth/invited/steps/intro-steps';
    navigate(route);
  };

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg="yellowLight"
      justify="center"
      align="center"
    >
      <motion.div
        animate={animate}
        onAnimationComplete={goToEmail}
        transition={transition}
      >
        <Text fontSize="8xl">
          <span role="img" aria-label="waving hand">
            👋🏽
          </span>
        </Text>
      </motion.div>
      <Box h={10} />
      <Text fontSize="3xl">Hi There!</Text>
    </Flex>
  );
};

const animate = {
  rotate: [
    0, 0, 0, 0, -10, -15, -20, -15, -10, 0, 10, 0, -10, 0, 9, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0,
  ],
};
const transition = { duration: 3 };

export default WelcomePartner;
