import React from 'react';
import { PropTypes } from 'prop-types';

import TextInput from 'shared/inputs/TextInput';

const SSNInput = ({ onChange, ...rest }) => {
  const handleChange = value => onChange(value.replace(/\D/g, '').substring(0, 9));

  return (
    <TextInput
      type="password"
      maxLength="9"
      pattern="[0-9]*"
      onChange={handleChange}
      {...rest}
    />
  );
};

SSNInput.propTypes = { onChange: PropTypes.func.isRequired };

export default SSNInput;
