import React, { useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useStoreActions } from 'easy-peasy';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import { H1, H3 } from 'shared/typography';

const PatriotActModal = ({ isOpen, onClose }) => {
  const { trackAnalytics } = useStoreActions(actions => actions.app);

  useEffect(() => {
    if (isOpen) trackAnalytics({ name: 'us-patriot-modal' });
  }, [isOpen, trackAnalytics]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent bg="yellowLight" borderRadius="10px">
        <ModalHeader px={15} pt={24}>
          <H1>USA Patriot Act</H1>
        </ModalHeader>
        <ModalCloseButton mt={8} mr={8} size="lg" color="primary" />
        <ModalBody px={15} pb={68} pt={10}>
          <H3>
            To help the government fight the funding of terrorism and money laundering
            activities, federal law requires all US financial institutions to obtain,
            verify, and record information that identifies each person who opens an
            account.
          </H3>
          <Box h={10} />
          <H3>
            What this means for you: When you open an account, we will ask for your name,
            address, date of birth, and other information that will allow us to identify
            you. We may also ask to see your identifying documents.
          </H3>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

PatriotActModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PatriotActModal;
