export default {
  primary: '#0D342D',
  primaryWTrans: '#0D342D1A',

  shared: '#3EB579',
  logo: '#D2FFE4',
  iceberg: '#EBF7F1',
  icebergWTrans: '#EBF7F180',
  greenPea: '#195F53',

  yellow: '#F2D022',
  blue: '#6EDAF2',

  greenLight: '#98D3B6',
  yellowLight: '#F1E08A',
  blueLight: '#B0E6F2',

  danger: '#EE5C3D',
  lightDanger: '#FEF1F1',
  dangerWTrans: '#EE5C3D80',

  white: '#ffffff',
  black: '#000000',
  boulder: '#787878',
  boulderWTrans: '#78787880',
  darkGray: '#333333',
  gray: '#cacaca',
  lightGray: '#EBEBEB',
  background: '#f5f5f5',
};
