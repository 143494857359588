import React, { useEffect, useState } from 'react';
import Player from '@vimeo/player';

const NoMatch = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', updateWindowWidth);
    document.body.style = 'background-color: #0a3030;';
  }, []);

  useEffect(() => {
    let player = null;
    if (windowWidth > 640)
      player = new Player('player', {
        id: 426010339,
        background: true,
        controls: false,
        responsive: 1,
        loop: true,
      });
    return () => player && player.destroy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  const goBack = e => {
    e.preventDefault();
    window.history.back();
  };

  return (
    <div style={styles(windowWidth).container}>
      {windowWidth > 640 && (
        <div style={styles(windowWidth).player}>
          <div id="player" style={{ margin: '0px auto' }} />
        </div>
      )}
      <div style={styles(windowWidth).content}>
        <div
          style={{
            position: 'absolute',
            top: '50px',
            left: windowWidth < 640 ? '30px' : '70px',
          }}
        >
          <svg
            width="131"
            height="45"
            viewBox="0 0 131 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.00084229 10.0608L10.0617 0V10.0608H0.00084229ZM21.6106 11.7134V21.6083H11.7157L21.6106 11.7134ZM0.207874 11.021L10.0608 20.874V11.021H0.207874ZM21.6109 32.4231L11.7559 22.5681H21.6109V32.4231ZM11.0215 11.0207V20.945L20.9458 11.0207H11.0215ZM21.0789 10.0611H11.0223V0.00341797L21.0789 10.0611ZM22.5692 21.6083V11.7134L32.4631 21.6083H22.5692ZM34.118 10.0608V0L44.1788 10.0608H34.118ZM43.971 11.0205L34.118 20.8745V11.0205H43.971ZM22.5712 32.4229L32.4252 22.5679H22.5712V32.4229ZM33.1577 11.0207V20.945L23.2344 11.0207H33.1577ZM33.1581 10.0611H23.1005L33.1581 0.00341797V10.0611ZM0 22.5681L10.0608 32.6289V22.5681H0ZM9.43831 21.6084H0V12.0231L9.43831 21.6084ZM20.9878 33.1579L11.0215 23.1916V33.1579H20.9878ZM21.6095 44.1776L11.5508 34.1179H21.6095V44.1776ZM34.118 22.5679V32.6297L44.1788 22.5679H34.118ZM44.1803 21.6082H34.743L44.1803 12.0229V21.6082ZM23.1914 33.1579L33.1577 23.1916V33.1579H23.1914ZM22.5692 44.1774V34.1177H32.629L22.5692 44.1774Z"
                fill="#CB76E8"
              />
              <mask
                id="mask0"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="45"
                height="45"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.00084229 10.0608L10.0617 0V10.0608H0.00084229ZM21.6106 11.7134V21.6083H11.7157L21.6106 11.7134ZM0.207874 11.021L10.0608 20.874V11.021H0.207874ZM21.6109 32.4231L11.7559 22.5681H21.6109V32.4231ZM11.0215 11.0207V20.945L20.9458 11.0207H11.0215ZM21.0789 10.0611H11.0223V0.00341797L21.0789 10.0611ZM22.5692 21.6083V11.7134L32.4631 21.6083H22.5692ZM34.118 10.0608V0L44.1788 10.0608H34.118ZM43.971 11.0205L34.118 20.8745V11.0205H43.971ZM22.5712 32.4229L32.4252 22.5679H22.5712V32.4229ZM33.1577 11.0207V20.945L23.2344 11.0207H33.1577ZM33.1581 10.0611H23.1005L33.1581 0.00341797V10.0611ZM0 22.5681L10.0608 32.6289V22.5681H0ZM9.43831 21.6084H0V12.0231L9.43831 21.6084ZM20.9878 33.1579L11.0215 23.1916V33.1579H20.9878ZM21.6095 44.1776L11.5508 34.1179H21.6095V44.1776ZM34.118 22.5679V32.6297L44.1788 22.5679H34.118ZM44.1803 21.6082H34.743L44.1803 12.0229V21.6082ZM23.1914 33.1579L33.1577 23.1916V33.1579H23.1914ZM22.5692 44.1774V34.1177H32.629L22.5692 44.1774Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M88.0201 35.4256L54.099 1.50439L21.9331 33.6703L-4.64862 7.08858L-31.0957 33.5357L2.82549 67.4568L24.3918 45.8906L50.9735 72.4723L88.0201 35.4256Z"
                  fill="#D2FFE4"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M21.9336 -19.5601L48.3807 6.88702L21.9336 33.3341L-4.51348 6.88702L21.9336 -19.5601Z"
                  fill="#3EB579"
                />
              </g>
              <path
                d="M70.617 33.4854H54.1207V30.4378L64.7493 15.1999H54.4088V11.3185H70.3289V14.3509L59.7155 29.6039H70.617V33.4854ZM87.5631 33.4854H74.7967V11.3185H87.5631V15.1696H79.4969V20.0366H87.0021V23.8878H79.4969V29.6039H87.5631V33.4854ZM101.401 33.4854H96.7008V15.2303H90.6814V11.3185H107.42V15.2303H101.401V33.4854ZM125.049 33.4854L123.442 28.209H115.36L113.753 33.4854H108.689L116.512 11.2275H122.259L130.113 33.4854H125.049ZM122.32 24.2668C120.834 19.4857 119.995 16.7818 119.803 16.1551C119.621 15.5284 119.489 15.0332 119.408 14.6693C119.075 15.9631 118.12 19.1623 116.543 24.2668H122.32Z"
                fill="#D2FFE4"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="131" height="44.637" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <h1 style={styles(windowWidth).h1}>
          Oops! The page you were looking for doesn't exist.
        </h1>
        {windowWidth > 640 ? (
          <>
            <h3 style={styles(windowWidth).h3}>
              You may have mistyped the address or the page may have moved.
            </h3>

            <p style={styles(windowWidth).p}>
              Nevertheless, we've got some quick links below that might be helpful.
            </p>
          </>
        ) : (
          <p style={styles(windowWidth).p}>
            You may have mistyped the address or the page may have moved. Nevertheless,
            we've got some quick links below that might be helpful.
          </p>
        )}
        <div style={styles(windowWidth).buttonContainer}>
          <button type="button" style={styles(windowWidth).button} onClick={goBack}>
            Go Back
          </button>
          <p style={styles(windowWidth).separator}>|</p>
          <button
            type="button"
            style={styles(windowWidth).button}
            onClick={() => {
              window.location.href = 'https://www.askzeta.com/magazine/';
            }}
          >
            Money Date Mag
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = windowWidth => ({
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: '#0a3030',
  },
  player: {
    backgroundColor: '#0a3030',
    width: '100%',
    marginTop: '-4rem',
    minWidth: '87rem',
  },
  content: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    paddingLeft: windowWidth < 640 ? '2.5rem' : '4rem',
    paddingRight: windowWidth < 640 ? '2.5rem' : '0',
    paddingBottom: '2rem',
  },
  h1: {
    color: '#98d3b6',
    paddingTop: '9rem',
    maxWidth: '34rem',
    fontSize: windowWidth < 640 ? '1.625rem' : '2.25rem',
    lineHeight: windowWidth < 640 ? '2.7rem' : '3.0625rem',
  },
  h3: {
    color: '#ebf7f1',
    fontWeight: '600',
    maxWidth: '26rem',
    fontSize: windowWidth < 640 ? '1.125rem' : '1.375rem',
    lineHeight: windowWidth < 640 ? '2.1rem' : '1.875rem',
    paddingTop: windowWidth < 640 ? '1rem' : 0,
  },
  p: {
    color: '#ebf7f1',
    paddingTop: windowWidth < 640 ? 0 : '5rem',
    maxWidth: windowWidth < 640 ? '17rem' : '19rem',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
  },
  buttonContainer: {
    paddingTop: '1.5rem',
    display: 'flex',
    maxWidth: '30rem',
    marginLeft: '-0.8rem',
  },
  button: {
    color: '#ebf7f1',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '8px',
    padding: '16px 14px',
    fontFamily: 'Open Sans',
    fontSize: '0.875rem',
    outline: 'none',
    cursor: 'pointer',
    textAlign: 'start',
  },
  separator: {
    color: '#ebf7f1',
    fontSize: '0.875rem',
    lineHeight: '1.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },
});

export default NoMatch;
