import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import PasswordValidations from 'auth/components/PasswordValidations';
import { FAB } from 'shared/buttons';
import { validPassword } from 'shared/lib/inputHelpers';
import { PasswordInput } from 'shared/inputs';
import { H1, H3 } from 'shared/typography';

const NewPasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const search = location.search || '';
  const { email } = querystring.parse(search);

  const { tempPass } = useStoreState(state => state.auth);
  const { resetPassword } = useStoreActions(actions => actions.auth);

  const [password, setPassword] = useState('');
  const [viewValidations, showValidations] = useState(false);
  const [saving, setSaving] = useState(false);

  const showVals = () => showValidations(true);

  const confirmNewPassword = () => {
    if (validPassword(password)) {
      setSaving(true);
      resetPassword({
        variables: {
          email: decodeURIComponent(email),
          password: tempPass,
          newPassword: password,
        },
        callback: afterConfirmation,
      });
    }
  };

  const afterConfirmation = () => {
    setSaving(false);
    navigate('/auth/login');
  };

  return (
    <>
      <H1>And you're back!</H1>
      <Box h={10} />
      <H3>Let's set a new password to secure your account.</H3>
      <Box h={15} />

      <PasswordInput
        id="userPassword"
        label="Password"
        autoComplete="new-password"
        value={password}
        onFocus={showVals}
        onChange={setPassword}
        onEnter={confirmNewPassword}
      />
      <PasswordValidations viewValidations={viewValidations} password={password} />
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB
            isLoading={saving}
            isDisabled={!validPassword(password)}
            onClick={confirmNewPassword}
          />
        </Flex>
      </Box>
    </>
  );
};

export default NewPasswordForm;
