import { useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';

const useAnalytics = props => {
  const { trackAnalytics } = useStoreActions(actions => actions.app);

  useEffect(() => {
    trackAnalytics(props);
  }, [trackAnalytics, props]);

  return null;
};

export default useAnalytics;
