import { gql } from '@apollo/client';

export default gql`
  query paymentCompletionEstimate(
    $fromId: ID!
    $toId: ID!
    $fromType: String!
    $toType: String!
    $sendOn: ISO8601Date!
    $processNow: Boolean
  ) {
    paymentCompletionEstimate(
      fromId: $fromId
      toId: $toId
      fromType: $fromType
      toType: $toType
      sendOn: $sendOn
      processNow: $processNow
    ) {
      adjustedSendOn
      startDate
      endDate
      paymentMethod
      service
      errors {
        path
        message
      }
    }
  }
`;
