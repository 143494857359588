import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { validEmail } from 'shared/lib/inputHelpers';
import { TextInput } from 'shared/inputs';
import { H1, H3, H5 } from 'shared/typography';

function CheckEmailForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'FBO-waitlist-signup' });

  const { checkEmail, subscribeToList } = useStoreActions(actions => actions.auth);

  const [email, setEmail] = useState('');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');

  const handleEmailChange = value => {
    setEmail(value.toLowerCase());
    setError('');
  };

  const checkInvitation = () => {
    if (validEmail(email)) {
      setSaving(true);
      checkEmail({
        variables: { email },
        onInvited: token => {
          setSaving(false);
          navigate(`/auth/invited/steps/intro-steps?claimtoken=${token}`);
        },
        onInvitedAdditionalOwner: token => {
          setSaving(false);
          window.location.href = `${process.env.REACT_APP_NEXT_URL}/welcome/additional?token=${token}`;
        },
        onNewUser: subscribe,
        onPfmUser: subscribe,
        onExistingUser: () => {
          setSaving(false);
          setError('Sorry, that email address is already in use. Sign in instead');
        },
      });
    }
  };

  const subscribe = () => {
    subscribeToList({
      variables: { list: 'joint_account', email },
      callback: () => navigate('/auth/on-the-list'),
    });
  };

  return (
    <Layout>
      <H1>Enter your email to get first access.</H1>
      <Box h={10} />
      <H3>
        We’re upgrading our Zeta accounts which will be available in a few weeks. Add your
        email below to jump the line.
      </H3>
      <Box h={15} />
      <TextInput
        id="userEmail"
        type="email"
        label="Email"
        error={error}
        autoComplete="email"
        value={email}
        onChange={handleEmailChange}
        onEnter={checkInvitation}
        placeholder="chrissy@cooks.com"
      />
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB
            isLoading={saving}
            isDisabled={!validEmail(email)}
            onClick={checkInvitation}
          />
        </Flex>

        <Box h={10} />
        <Flex justify="flex-end">
          <H5 as={Link} to="/auth/login" color="primary">
            Already have an account? Login
          </H5>
        </Flex>
      </Box>
      <Box h={20} />
    </Layout>
  );
}

export default CheckEmailForm;
