import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import { LockIcon } from 'auth/icons';
import { Button } from 'shared/buttons';
import { SSNInput } from 'shared/inputs';
import { H6 } from 'shared/typography';
import isPresent from 'shared/lib/isPresent';
import ShowError from 'shared/components/ShowError';

function ReviewSSNForm() {
  const navigate = useNavigate();

  const { editUser, getToken, setLastFour } = useStoreActions(actions => actions.auth);

  const [ssn, setSSN] = useState('');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);
  const validateSsnFormat = value => {
    const uniqueIntegers = value
      .split('')
      .filter((int, index, arr) => arr.indexOf(int) === index);
    return uniqueIntegers.length > 1;
  };
  const validate = () => ssn && ssn.toString().length === 9 && validateSsnFormat(ssn);
  const handleEvent = e => e.preventDefault();
  const handleSsnChange = value => {
    setSSN(value);
    setError({});

    if (value.toString().length === 9 && !validateSsnFormat(value)) {
      setError({ message: 'Sorry, we require a valid SSN to sign up.' });
    }
  };

  const saveInfo = () => {
    if (validate()) {
      setSaving(true);
      getToken({
        forPartner: false,
        variables: { type: 'ssn', data: ssn },
        callback: token => {
          editUser({
            variables: { ssnToken: token, ssnLast4: ssn.slice(ssn.length - 4) },
            callback: () => {
              setSaving(false);

              setLastFour(ssn.toString().substring(5));
              navigate(-1);
            },
          });
        },
        onError: ({ error: message }) => {
          setSaving(false);
          setError({ message });
        },
      });
    }
  };

  return (
    <form onSubmit={handleEvent}>
      {isPresent(error) ? <ShowError error={error} /> : null}

      <SSNInput
        id="ssn"
        dark
        label="Enter your SSN"
        autoComplete="off"
        value={ssn}
        onChange={handleSsnChange}
        onEnter={saveInfo}
      />
      <Flex bg="greenLight" w={67} rounded="md" p={2} mt={2} align="center">
        <H6 color="primary">This information is encrypted using SSL.</H6>
        <LockIcon />
      </Flex>
      <Box h={15} />
      <Flex justify="center">
        <Button
          light
          maxW={67}
          isLoading={saving}
          isDisabled={!validate()}
          onClick={saveInfo}
        >
          Save changes
        </Button>
      </Flex>
    </form>
  );
}

export default ReviewSSNForm;
