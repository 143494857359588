import { gql } from '@apollo/client';

export default gql`
  query pendingInvitation($claimToken: String!) {
    pendingInvitation(claimToken: $claimToken) {
      email
      phoneNumberDigits
      senderFirstName
      recipientFirstName
    }
  }
`;
