import React from 'react';
import { Outlet } from 'react-router-dom';
import { Flex, Image } from '@chakra-ui/react';

import ImageLayout from 'auth/components/ImageLayout';
import Layout from 'auth/components/Layout';
import CreateAccount from 'auth/lib/images/CreateAccount.jpg';
import { useBreakpoint } from 'shared/components/breakpoint';

const ForgotPassword = () => {
  const bp = useBreakpoint();

  return (
    <Flex>
      {bp.large && (
        <ImageLayout>
          <Image objectFit="cover" style={styles} alt="" src={CreateAccount} />
        </ImageLayout>
      )}

      <Layout>
        <Outlet />
      </Layout>
    </Flex>
  );
};

const styles = {
  flex: '1 1',
  overflow: 'hidden',
  maxWidth: '100%',
  minHeight: '100vh',
  height: '1080px',
};

export default ForgotPassword;
