import React from 'react';
import { PropTypes } from 'prop-types';

import { Text } from '@chakra-ui/react';

const Legal = ({ children, as, fontSize, lineHeight, fontWeight, color, ...rest }) => {
  return (
    <Text
      my={0}
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={color}
      fontStyle="italic"
      {...rest}
    >
      {children}
    </Text>
  );
};

Legal.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};
Legal.defaultProps = {
  as: 'i',
  fontSize: 'xxs', // 10px
  lineHeight: '4',
  fontWeight: 'normal',
  color: 'primary',
};

export default Legal;
