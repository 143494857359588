import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';

import ReviewSummary from 'auth/components/pass-computer/ReviewSummary';
import ReviewContactForm from 'auth/components/pass-computer/ReviewContactForm';
import ReviewDOBForm from 'auth/components/pass-computer/ReviewDOBForm';
import ReviewSSNForm from 'auth/components/pass-computer/ReviewSSNForm';
import ReviewMailingForm from 'auth/components/pass-computer/ReviewMailingForm';
import useAnalytics from 'shared/components/useAnalytics';
import { H1 } from 'shared/typography';

function PassComputerReview() {
  useAnalytics({ name: 'pass-confirm-info' });

  return (
    <Flex
      direction="column"
      h="100%"
      w="100%"
      bg="greenPea"
      overflow="auto"
      py={[18, 18, 32]}
      px={[5, 0]}
      align={['auto', 'center']}
    >
      <Box maxWidth={['xs', 'xl', '3xl']} minWidth={['xs', 'lg', '2xl']}>
        <H1 color="iceberg">Great, let’s review your information.</H1>
        <Box h={10} />
        <Routes>
          <Route path="contact" element={<ReviewContactForm />} />
          <Route path="dob" element={<ReviewDOBForm />} />
          <Route path="ssn" element={<ReviewSSNForm />} />
          <Route checkUser path="mailing" element={<ReviewMailingForm />} />
          <Route path="/" element={<ReviewSummary />} />
        </Routes>
      </Box>
    </Flex>
  );
}

export default PassComputerReview;
