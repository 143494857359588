import { useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';

const LinkLoader = ({ config }) => {
  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    open();
  }, [ready, open]);

  return null;
};

export default LinkLoader;
