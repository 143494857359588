import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import { FAB } from 'shared/buttons';
import { validPhone } from 'shared/lib/inputHelpers';
import useAnalytics from 'shared/components/useAnalytics';
import { PhoneInput, TextInput } from 'shared/inputs';
import { H1, H3 } from 'shared/typography';

function NameForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'personal-info' });

  const { user } = useStoreState(state => state.auth);
  const { editUser } = useStoreActions(actions => actions.auth);

  const [firstName, setFirstName] = useState(user?.firstName ?? '');
  const [lastName, setLastName] = useState(user?.lastName ?? '');
  const [phone, setPhone] = useState(user?.phoneNumber ?? '');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState({});

  const handlePhoneChange = value => {
    setError({});
    setPhone(value);
  };

  const checkLast = () => {
    if (lastName.length <= 2) setError({ lastName: 'please enter your last name' });
  };

  const handleLastName = value => {
    setError({});
    setLastName(value);
  };

  const validate = () => {
    return !!firstName && !!lastName && lastName.length >= 2 && validPhone(phone);
  };

  const saveInfo = () => {
    if (validate()) {
      setSaving(true);
      editUser({
        variables: { firstName, lastName, phoneNumber: phone },
        callback: () => {
          setSaving(false);
          navigate('/auth/personal/address');
        },
        onError: err => {
          setSaving(false);
          setError(err);
        },
      });
    }
  };

  return (
    <Layout h="100vh">
      <H1>Our name is Zeta, and you are?</H1>
      <Box h={10} />
      <H3>Use the name on your government-issued ID (eg. your Driver’s License).</H3>
      <Box h={2} />
      <H3 maxW={80}>
        Don’t worry - if you have a different preferred name or nickname, we’ll give you a
        chance to add that later.
      </H3>
      <Box h={15} />
      <TextInput
        id="userFirstName"
        label="First Name"
        autoComplete="given-name"
        value={firstName}
        error={error?.firstName}
        onChange={setFirstName}
        placeholder="Chrissy"
      />
      <Box h={15} />
      <TextInput
        id="userLastName"
        label="Last Name"
        autoComplete="family-name"
        value={lastName}
        error={error?.lastName}
        onChange={handleLastName}
        onBlur={checkLast}
        placeholder="Cooks"
      />
      <Box h={15} />
      <PhoneInput
        id="userPhoneNumber"
        label="Phone Number"
        error={error?.phoneNumber}
        value={phone}
        onChange={handlePhoneChange}
        onEnter={saveInfo}
      />
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB isLoading={saving} isDisabled={!validate()} onClick={saveInfo} />
        </Flex>
      </Box>
    </Layout>
  );
}

export default NameForm;
