import gql from 'graphql-tag';

import { CounterpartyFields } from 'plaid/graphql/CounterpartyFields';

export default gql`
  mutation createCounterparties($input: CreateCounterpartiesInput!) {
    createCounterparties(input: $input) {
      errors {
        path
        message
      }
      counterparties {
        ${CounterpartyFields}
      }
    }
  }
`;
