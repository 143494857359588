import { gql } from '@apollo/client';

export default gql`
  mutation abandonPassPhone($input: AbandonPassPhoneInput!) {
    abandonPassPhone(input: $input) {
      ok
      errors {
        message
        path
      }
    }
  }
`;
