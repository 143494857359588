import { gql } from '@apollo/client';

import { transferFields } from 'transfers/graphql/TransferFields';

export default gql`
  mutation createTransfer($input: CreateTransferInput!) {
    createTransfer(input: $input) {
      transfer {
        ${transferFields}
      }
      errors {
        path
        message
      }
    }
  }
`;
