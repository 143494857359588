import React from 'react';
import { PropTypes } from 'prop-types';
import { Button as CKButton } from '@chakra-ui/react';

const Button = ({ children, outlined, light, onClick, ...rest }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick && onClick();
  };

  if (outlined)
    return (
      <CKButton
        borderColor="primaryWTrans"
        color="primary"
        variant="outline"
        rounded="8px"
        width="100%"
        py={6}
        onClick={handleClick}
        {...rest}
      >
        {children}
      </CKButton>
    );
  return (
    <CKButton
      bg={light ? 'iceberg' : 'primary'}
      color={light ? 'primary' : 'iceberg'}
      variant="solid"
      rounded="8px"
      width="100%"
      py={6}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </CKButton>
  );
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  outlined: PropTypes.bool,
  light: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
Button.defaultProps = { outlined: false, light: false };

export default Button;
