import { gql } from '@apollo/client';

import { userFields } from 'auth/graphql/UserFields';

export default gql`
  mutation resetPartnerPassDevice($input: ResetPartnerPassDeviceInput!) {
    resetPartnerPassDevice(input: $input) {
      partner {
        ${userFields}
      }
      tempPass
      existingPfm
      errors {
        message
        path
      }
    }
  }
`;
