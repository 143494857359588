import React from 'react';
import { Outlet } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { Box } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import useAnalytics from 'shared/components/useAnalytics';
import { H1 } from 'shared/typography';

function Review() {
  useAnalytics({ name: 'confirm-info' });

  const { user } = useStoreState(state => state.auth);

  return (
    <Layout>
      <H1>{user?.firstName}, let's review your information.</H1>
      <Box h={10} />
      <Outlet />
    </Layout>
  );
}

export default Review;
