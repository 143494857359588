import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { StoreProvider } from 'easy-peasy';
import { BrowserRouter as Router } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import { AnimatePresence } from 'framer-motion';
import { ChakraProvider } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import ScrollToTop from 'app/components/ScrollToTop';
import { client } from 'app/config/Apollo';
import store from 'app/config/EasyPeasy';
import MainRouter from 'app/MainRouter';
import theme from 'app/theme';
import { BreakpointProvider } from 'shared/components/breakpoint';
import branch from 'branch-sdk';

const queries = {
  mobile: '(max-width: 640px)',
  tablet: '(max-width: 832px)',
  large: '(min-width: 833px)',
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

if (process.env.REACT_APP_BRANCH_KEY?.length > 0) {
  branch.init(process.env.REACT_APP_BRANCH_KEY, () => {});
}

function App() {
  return (
    <HttpsRedirect>
      <ApolloProvider client={client}>
        <StoreProvider store={store}>
          <Router>
            <ScrollToTop />
            <ChakraProvider theme={theme}>
              <BreakpointProvider queries={queries}>
                <AnimatePresence>
                  <MainRouter />
                </AnimatePresence>
              </BreakpointProvider>
            </ChakraProvider>
          </Router>
        </StoreProvider>
      </ApolloProvider>
    </HttpsRedirect>
  );
}

export default App;
