import React from 'react';
import { PropTypes } from 'prop-types';
import { Image } from '@chakra-ui/react';

import ImageLayout from 'auth/components/ImageLayout';
import Lobby from 'auth/lib/images/Intro.jpg';
import CreateAccount from 'auth/lib/images/CreateAccount.jpg';
import Name from 'auth/lib/images/Name.jpg';
import Partner from 'auth/lib/images/Partner.jpg';
import Transformer from 'auth/lib/images/Transformer.jpg';

const SideImages = ({ location }) => {
  if (location.includes('/steps'))
    return (
      <ImageLayout dark={false}>
        <Image objectFit="cover" style={steps} alt="" src={Lobby} />
      </ImageLayout>
    );

  if (
    location.includes('/welcome') ||
    location.includes('/login') ||
    location.includes('/ssn') ||
    location.includes('/verify') ||
    location.includes('/finalize-transfer') ||
    location.includes('/add-money')
  )
    return (
      <ImageLayout>
        <Image objectFit="cover" style={styles} alt="" src={CreateAccount} />
      </ImageLayout>
    );

  if (location.includes('/personal/partner-'))
    return (
      <ImageLayout>
        <Image objectFit="cover" style={styles} alt="" src={Transformer} />
      </ImageLayout>
    );

  if (location.includes('/partner'))
    return (
      <ImageLayout>
        <Image objectFit="cover" style={styles} alt="" src={Partner} />
      </ImageLayout>
    );

  if (location.includes('/personal'))
    return (
      <ImageLayout>
        <Image objectFit="cover" style={styles} alt="" src={Name} />
      </ImageLayout>
    );

  return null;
};

const steps = {
  flex: '1 1',
  overflow: 'hidden',
  maxWidth: '100%',
  minHeight: '100vh',
  height: '100vh',
  minWidth: '530px',
};

const styles = {
  flex: '1 1',
  overflow: 'hidden',
  maxWidth: '100%',
  minHeight: '100vh',
  height: '100vh',
  minWidth: '530px',
};

SideImages.propTypes = { location: PropTypes.string.isRequired };

export default SideImages;
