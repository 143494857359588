import React, { useEffect, useRef } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import AppleBadge from 'auth/lib/images/AppleBadge.png';
import GooglePlayBadge from 'auth/lib/images/GooglePlayBadge.png';
import { appleLocation, playLocation } from 'auth/lib/utils';
import { H3, Paragraph } from 'shared/typography';

const AppStoreRedirect = () => {
  const redirectTimeout = useRef(false);

  useEffect(() => {
    redirectTimeout.current = setTimeout(() => {
      const store = getMobileOperatingSystem();
      if (store === 'Android') {
        window.location.href = playLocation;
      } else if (store === 'iOS') {
        window.location.href = appleLocation;
      }
    }, 2500);
    return () => clearTimeout(redirectTimeout.current);
  }, []);

  return (
    <Flex justify="center" align="center" height="100vh" width="100%">
      <Box borderWidth="1px" borderColor="gray" borderRadius="md" p={16} px={26}>
        <H3 fontWeight="semibold" textAlign="center">
          Our Zeta robots are taking you to the app store.
        </H3>
        <Box h={15} />
        <Paragraph textAlign="center">
          If they're not quick enough, click your app store below.
        </Paragraph>
        <Box h={8} />
        <Flex justify="center" pad={{ top: 'medium' }} wrap>
          <button
            type="submit"
            style={{
              border: 'none',
              backgroundColor: 'none',
              padding: '0 0 0 0',
              marginRight: '4px',
              cursor: 'pointer',
            }}
            onClick={e => {
              e.preventDefault();
              clearTimeout(redirectTimeout.current);
              window.location.href = appleLocation;
            }}
          >
            <figure
              style={{
                display: 'block',
                width: '160px',
                height: '54px',
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${AppleBadge})`,
              }}
            />
          </button>
          <button
            type="submit"
            style={{
              border: 'none',
              backgroundColor: 'none',
              padding: '0 0 0 0',
              cursor: 'pointer',
            }}
            onClick={e => {
              e.preventDefault();
              clearTimeout(redirectTimeout.current);
              window.location.href = playLocation;
            }}
          >
            <figure
              style={{
                display: 'block',
                width: '160px',
                height: '54px',
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${GooglePlayBadge})`,
              }}
            />
          </button>
        </Flex>
      </Box>
    </Flex>
  );
};

const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) return 'Windows Phone';
  if (/android/i.test(userAgent)) return 'Android';
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return 'iOS';

  return 'unknown';
};

export default AppStoreRedirect;
