import React from 'react';
import { Route, Routes } from 'react-router-dom';

import GoToMobile from 'app/pages/GoToMobile';
import AuthRoute from 'auth/components/AuthRoute';
import MobileShowCard from 'cards/pages/MobileShowCard';

const CardRouter = () => {
  return (
    <Routes>
      <AuthRoute path="mobile">
        <AuthRoute path="/show" element={<MobileShowCard />} />
      </AuthRoute>
      <Route path="*" element={<GoToMobile />} />
    </Routes>
  );
};

export default CardRouter;
