import gql from 'graphql-tag';

export default gql`
  mutation sendSms($input: SendSmsInput!) {
    sendSms(input: $input) {
      ok
      errors {
        message
        path
      }
    }
  }
`;
