import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';
import Cookies from 'universal-cookie';

import Layout from 'auth/components/Layout';
import PasswordValidations from 'auth/components/PasswordValidations';
import { signIn } from 'auth/lib/authentication';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { validPassword } from 'shared/lib/inputHelpers';
import { PasswordInput } from 'shared/inputs';
import { H1, Legal, ExternalLink } from 'shared/typography';
import { pixelTrack } from 'shared/components/usePixel';
import branch from 'branch-sdk';

function NewPasswordForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'create-password' });

  const { createUser } = useStoreActions(actions => actions.auth);
  const { userSource } = useStoreState(state => state.app);

  const [password, setPassword] = useState('');
  const [viewValidations, showValidations] = useState(false);
  const [saving, setSaving] = useState(false);

  const cookies = new Cookies();

  const referrerToken = cookies.get('ZJCReferrerToken') || null;

  const showVals = () => showValidations(true);
  const handleEvent = e => {
    e.preventDefault();
  };

  const createAccount = () => {
    if (validPassword(password)) {
      setSaving(true);
      createUser({
        variables: { password, sourceLocation: userSource, referrerToken },
        callback: afterCreation,
        onError: () => setSaving(false),
      });
    }
  };

  const afterCreation = token => {
    branch.logEvent('ApplicationStarted');
    setSaving(false);
    pixelTrack({ event: 'ApplicationStarted', custom: true });
    signIn(token, true);
    setTimeout(() => {
      navigate('/auth/steps/secured');
    }, 200);
  };

  return (
    <Layout>
      <H1>And set a strong password.</H1>
      <Box h={15} />
      <form onSubmit={handleEvent}>
        <PasswordInput
          id="userPassword"
          label="Password"
          autoComplete="new-password"
          value={password}
          onFocus={showVals}
          onChange={setPassword}
          onEnter={createAccount}
        />
        <PasswordValidations viewValidations={viewValidations} password={password} />
        <Box h={26} />
        <Box position="absolute" right={0} top={[80, 78]}>
          <Flex justify="flex-end">
            <FAB
              isLoading={saving}
              isDisabled={!validPassword(password)}
              onClick={createAccount}
            />
          </Flex>

          <Box h={8} />
          <Flex justify="flex-end">
            <Legal>
              By creating an account, you agree to Zeta’s{' '}
              <ExternalLink as="i" href="https://www.askzeta.com/privacy-policy">
                Privacy Policy
              </ExternalLink>{' '}
              &{' '}
              <ExternalLink as="i" href="https://www.askzeta.com/terms-of-use">
                Terms of Use
              </ExternalLink>
              .
            </Legal>
          </Flex>
        </Box>
        <Box h={20} />
      </form>
    </Layout>
  );
}

export default NewPasswordForm;
