import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, HStack, Flex } from '@chakra-ui/react';
import PuffLoader from 'react-spinners/PuffLoader';
import { motion } from 'framer-motion';

import Layout from 'auth/components/Layout';
import { STATES } from 'auth/lib/constants';
import { FAB } from 'shared/buttons';
import ErrorBox from 'shared/components/ErrorBox';
import { Select, TextInput } from 'shared/inputs';
import { H1, H3 } from 'shared/typography';

function MailingForm() {
  const navigate = useNavigate();
  const { user } = useStoreState(state => state.auth);
  const { editUser, zipLookup } = useStoreActions(actions => actions.auth);

  const [streetAddress1, setStreet1] = useState(
    user?.profile?.mailingStreetAddress1 ?? '',
  );
  const [streetAddress2, setStreet2] = useState(
    user?.profile?.mailingStreetAddress2 ?? '',
  );
  const [zipcode, setZip] = useState(user?.profile?.mailingZipcode ?? '');
  const [city, setCity] = useState(user?.profile?.mailingCity ?? '');
  const [state, setState] = useState(user?.profile?.mailingState ?? '');
  const [invalid, setInvalid] = useState(false);
  const [validZip, setValidZip] = useState(!!user?.profile?.mailingZipcode);
  const [checkingZip, setCheckingZip] = useState(false);
  const [saving, setSaving] = useState(false);

  const validate = () =>
    streetAddress1 && city && state && zipcode && zipcode.toString().length === 5;

  const afterZip = () => streetAddress1 && zipcode && zipcode.toString().length === 5;

  const saveInfo = () => {
    if (validate()) {
      setSaving(true);
      editUser({
        variables: {
          mailingStreetAddress1: streetAddress1,
          mailingStreetAddress2: streetAddress2,
          mailingZipcode: zipcode,
          mailingCity: city,
          mailingState: state,
          mailingSkipAddressVerify: true,
          skipAddressVerify: true,
        },
        callback: () => {
          setSaving(false);
          navigate('/auth/invited/personal/ssn');
        },
        onError: err => {
          setSaving(false);
          err?.mailingStreetAddress1 && setInvalid(true);
        },
      });
    }
  };

  const lookUpZip = () => {
    if (afterZip()) {
      setCheckingZip(true);
      zipLookup({
        variables: { zipcode, addressPrefix: streetAddress1 },
        callback: zipInfo => {
          setCity(zipInfo.city);
          setState(zipInfo.stateAbbr);
          setCheckingZip(false);
          setValidZip(true);
        },
      });
    }
  };

  return (
    <Layout>
      <H1>What is your mailing address?</H1>
      <Box h={10} />
      {invalid ? (
        <ErrorBox>Hmm, we couldn't verify that address.</ErrorBox>
      ) : (
        <H3>This is where we will ship your Zeta Joint Cards.</H3>
      )}
      <Box h={15} />
      <TextInput
        id="street"
        label="Street"
        autoComplete="address-line1"
        value={streetAddress1}
        onChange={setStreet1}
        placeholder="1234 Happy Ave"
      />
      <Box h={15} />
      <TextInput
        id="apt"
        label="Apt/Suite"
        autoComplete="address-line2"
        value={streetAddress2}
        onChange={setStreet2}
        placeholder="L"
      />
      <Box h={15} />
      <HStack spacing={4}>
        <TextInput
          id="zipCode"
          label="Zip"
          maxLength={5}
          autoComplete="postal-code"
          value={zipcode}
          cWidth="unset"
          pattern="[0-9]*"
          onChange={val => setZip(val.replace(/\D/g, '').substring(0, 5))}
          onEnter={lookUpZip}
          onBlur={lookUpZip}
          placeholder="10001"
        />
        {checkingZip && (
          <Box pt={0}>
            <PuffLoader size={30} color="#98D3B6" loading />
          </Box>
        )}
      </HStack>
      <Box h={15} />
      {validZip ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          <TextInput
            id="city"
            label="City"
            autoComplete="address-level2"
            value={city}
            onChange={setCity}
          />
          <Box h={15} />
          <Select
            id="state"
            label="State"
            autoComplete="address-level1"
            options={STATES}
            value={state}
            onChange={setState}
          />
        </motion.div>
      ) : (
        <Box h={45} />
      )}
      <Box h={15} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB isLoading={saving} isDisabled={!validate()} onClick={saveInfo} />
        </Flex>
      </Box>
    </Layout>
  );
}

export default MailingForm;
