import { gql } from '@apollo/client';

import { userFields } from 'auth/graphql/UserFields';

export default gql`
  mutation editPartner($input: EditPartnerInput!) {
    editPartner(input: $input) {
      user {
        ${userFields}
      }
      errors {
        message
        path
      }
    }
  }
`;
