import React from 'react';
import { PropTypes } from 'prop-types';
import { Box } from '@chakra-ui/react';

import { H3 } from 'shared/typography';

const ShowError = ({ error, ...rest }) => (
  <Box bg="lightDanger" borderRadius="md" width="fit-content" p={2}>
    <H3 textAlign="center" color="danger" {...rest}>
      {error[Object.keys(error)[0]]}
    </H3>
  </Box>
);

ShowError.propTypes = { error: PropTypes.object.isRequired };

export default ShowError;
