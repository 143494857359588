import React from 'react';
import { Routes } from 'react-router-dom';

import AuthRoute from 'auth/components/AuthRoute';
import MobileLinkCounterparty from 'plaid/pages/MobileLinkCounterparty';
import WebLinkCounterparty from 'plaid/pages/WebLinkCounterparty';

const LinkRouter = () => {
  return (
    <Routes>
      <AuthRoute path="mobile">
        <AuthRoute path="/" element={<MobileLinkCounterparty />} />
        <AuthRoute path="/counterparty" element={<MobileLinkCounterparty />} />
      </AuthRoute>
      <AuthRoute path="/init" element={<WebLinkCounterparty />} />
    </Routes>
  );
};

export default LinkRouter;
