import { gql } from '@apollo/client';

import { CounterpartyFields } from 'plaid/graphql/CounterpartyFields';

export default gql`
  query counterparties ($context: String) {
    counterparties (context: $context) {
      ${CounterpartyFields}
    }
  }
`;
