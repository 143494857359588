import appModel from 'app/model/appModel';
import authModel from 'auth/model/authModel';
import plaidModel from 'plaid/model/PlaidModel';
import cardModel from 'cards/model/CardModel';
import transferModel from 'transfers/model/transfersModel';
import accountsModel from 'accounts/model/accountsModel';

export const storeModel = {
  accounts: accountsModel,
  app: appModel,
  auth: authModel,
  cards: cardModel,
  plaid: plaidModel,
  transfers: transferModel,
};
