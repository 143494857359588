/* eslint-disable no-shadow */
import { action, thunk } from 'easy-peasy';

import GET_COUNTERPARTIES from 'plaid/graphql/CounterpartiesQuery';
import CREATE_EXTERNAL_ACCOUNT from 'plaid/graphql/CreateExternalAccount';
import CREATE_COUNTERPARTIES from 'plaid/graphql/CreateCounterparties';
import RELINK_PLAID from 'plaid/graphql/RelinkPlaidItem';
import CREATE_LINK_LOG from 'plaid/graphql/CreateLinkLog';
import DELETE_COUNTERPARTY from 'plaid/graphql/DeleteCounterparty';
import EDIT_ACCOUNT_NICKNAME from 'plaid/graphql/EditCounterparty';
import LINK_TOKEN_QUERY from 'plaid/graphql/LinkTokenQuery';
import { deepOmitTypeName } from 'shared/lib/parseGraphql';

const plaidModel = {
  loadingCounterparties: false,
  loadingPublicToken: false,
  counterparties: [],
  selectedCounterparty: {},
  extCreditCard: {},

  setLoadingCounterparties: action((state, payload) => {
    state.loadingCounterparties = payload;
  }),

  setLoadingPublicToken: action((state, payload) => {
    state.loadingPublicToken = payload;
  }),

  setSelectedCounterparty: action((state, payload) => {
    state.selectedCounterparty = payload;
  }),

  setCounterparties: action((state, payload) => {
    state.counterparties = payload;
  }),

  setExternalCreditCard: action((state, payload) => {
    state.extCreditCard = payload;
  }),

  addCounterparties: action((state, payload) => {
    state.counterparties = [...state.counterparties, ...payload];
  }),

  updateSelectedCounterparty: action((state, payload) => {
    state.counterparties = state.counterparties.map(selectedCounterparty =>
      selectedCounterparty.id === payload.id ? payload : selectedCounterparty,
    );
  }),

  removeCounterparty: action((state, payload) => {
    state.counterparties = state.counterparties.filter(c => c.id !== payload.id);
  }),

  getCounterparties: thunk(async (action, payload, { injections }) => {
    action.setLoadingCounterparties(true);
    const { client } = injections;

    const response = await client.query({ query: GET_COUNTERPARTIES });

    const cleanResponse = deepOmitTypeName(response);

    action.setCounterparties(cleanResponse.data.counterparties);
    action.setLoadingCounterparties(false);
  }),

  getCounterparty: thunk(async (action, payload, { injections }) => {
    action.setLoadingCounterparties(true);
    const { client } = injections;

    const response = await client.query({ query: GET_COUNTERPARTIES });

    const cleanResponse = deepOmitTypeName(response);

    const counterparty = (cleanResponse.data.counterparties || []).filter(
      cp => cp.id === payload.variables.id,
    )[0];
    action.setLoadingCounterparties(false);
    action.setSelectedCounterparty(counterparty);
    payload.callback && payload.callback(counterparty);
  }),

  createLinkLog: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    await client.mutate({
      mutation: CREATE_LINK_LOG,
      variables: { input: { ...payload.variables } },
    });
  }),

  createCounterparties: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: CREATE_COUNTERPARTIES,
      variables: { input: { ...payload.variables } },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.createCounterparties?.errors.length === 0) {
      action.addCounterparties(cleanResponse.data.createCounterparties.counterparties);
      action.setSelectedCounterparty(
        cleanResponse.data.createCounterparties.counterparties[0],
      );
    }
    payload.callback &&
      payload.callback(
        (cleanResponse?.data?.createCounterparties?.counterparties || [{}])[0],
      );
  }),

  relinkPlaid: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: RELINK_PLAID,
      variables: { input: { ...payload.variables } },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.relinkCounterparty?.errors.length === 0) {
      action.getCounterparties();
    }
    payload.callback && payload.callback();
  }),

  editCounterparty: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: EDIT_ACCOUNT_NICKNAME,
      variables: { input: { ...payload.variables } },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.editCounterparty?.errors.length === 0) {
      action.updateSelectedCounterparty(
        cleanResponse.data?.editCounterparty.counterparty,
      );
      payload.callback &&
        payload?.callback(cleanResponse.data?.editCounterparty.counterparty);
    } else {
      payload?.onError && payload.onError();
    }
  }),

  deleteCounterparty: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: DELETE_COUNTERPARTY,
      variables: { input: { ...payload.variables } },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data.deleteCounterparty?.ok) {
      action.removeCounterparty({ id: payload.variables.id });
    }
    payload.callback && payload.callback();
  }),

  getLinkToken: thunk(async (action, payload, { injections }) => {
    const { client } = injections;
    action.setExternalCreditCard(null);

    const response = await client.query({
      query: LINK_TOKEN_QUERY,
      variables: payload?.variables,
    });

    const cleanResponse = deepOmitTypeName(response);
    payload.callback(cleanResponse.data.linkToken);
  }),

  createExternalAccount: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: CREATE_EXTERNAL_ACCOUNT,
      variables: { input: payload?.variables },
    });
    const cleanResponse = deepOmitTypeName(response);

    payload?.callback &&
      payload?.callback(cleanResponse.data?.createExternalAccount.externalAccount);
  }),
};

export default plaidModel;
