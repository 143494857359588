import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box } from '@chakra-ui/react';
import moment from 'moment';

import { Button } from 'shared/buttons';
import { DateInput } from 'shared/inputs';

function ReviewDOBForm() {
  const navigate = useNavigate();
  const { user } = useStoreState(state => state.auth);
  const { editUser } = useStoreActions(actions => actions.auth);

  const [dateOfBirth, setDateOfBirth] = useState(
    user?.profile?.dateOfBirth ? moment(user.profile.dateOfBirth).toDate() : '',
  );

  const [errors, setErrors] = useState({});

  const handleEvent = e => {
    e.preventDefault();
  };

  const saveInfo = () => {
    editUser({
      variables: { dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD') },
      callback: () => navigate(-1),
      onError: err => {
        setErrors(err);
      },
    });
  };

  return (
    <form onSubmit={handleEvent}>
      <DateInput
        id="dob"
        label="Date of Birth"
        autoComplete="bday"
        error={errors.dateOfBirth}
        selected={dateOfBirth ?? ''}
        onChange={setDateOfBirth}
      />
      <Box h={15} />
      <Button maxW={67} isDisabled={!dateOfBirth} onClick={saveInfo}>
        Save changes
      </Button>
    </form>
  );
}

export default ReviewDOBForm;
