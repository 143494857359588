import React, { useState, useEffect } from 'react';
import querystring from 'query-string';
import { useStoreActions } from 'easy-peasy';
import { useLocation } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';

import LinkLoader from 'plaid/components/LinkLoader';
import {
  logEvent,
  onExit,
  CC_EXIT_URL,
  EXIT_URL,
  retrieveLinkToken,
  storeLinkToken,
  clearLinkToken,
} from 'plaid/lib/utils';
import { H1, H2, Paragraph } from 'shared/typography';

const MobileLinkCounterparty = () => {
  const {
    createExternalAccount,
    createLinkLog,
    createCounterparties,
    relinkPlaid,
    getLinkToken,
  } = useStoreActions(actions => actions.plaid);
  const location = useLocation();

  const [linkToken, setLinkToken] = useState(retrieveLinkToken());
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!linkToken) {
      const isMobile = window.location.href.includes('link/mobile');
      const { objId, objType } = querystring.parse(location.search);

      getLinkToken({
        variables: {
          objType: objType ?? 'Counterparty',
          objId,
          isMobile,
          oauthRedirectUri: window.location.href,
        },
        callback: token => {
          storeLinkToken(token);
          setLinkToken(token);
        },
      });
    }
  }, [linkToken, getLinkToken, location.search]);

  const onSuccess = (_token, metadata) => {
    setSaving(true);
    const metadataJson = JSON.stringify(metadata);
    const { objId, objType, path, extra, skipAccountDetails } = querystring.parse(
      location.search,
    );

    if (objId) {
      relinkPlaid({
        variables: {
          objId,
          objType: objType ?? 'Counterparty',
          metadataJson,
        },
        callback: linkedAccount => {
          clearLinkToken();
          window.location.href = `${
            objType === 'ExternalAccount' ? CC_EXIT_URL : EXIT_URL
          }?id=${linkedAccount?.id}${path ? `&path=${path}` : ''}${
            extra ? `&extra=${extra}` : ''
          }`;
        },
      });
    } else if (objType === 'ExternalAccount') {
      createExternalAccount({
        variables: { metadataJson },
        callback: linkedAccount => {
          clearLinkToken();
          window.location.href = `${CC_EXIT_URL}?id=${linkedAccount?.id}${
            path ? `&path=${path}` : ''
          }`;
        },
      });
    } else {
      createCounterparties({
        variables: { metadataJson },
        callback: linkedAccount => {
          clearLinkToken();
          window.location.href = `${EXIT_URL}?id=${linkedAccount?.id}${
            path ? `&path=${path}` : ''
          }${skipAccountDetails ? '&skip_account_details=true' : ''}`;
        },
      });
    }
  };

  const callback = () => {
    const { path } = querystring.parse(location.search);
    window.location.href = `${EXIT_URL}${path ? `&path=${path}` : ''}`;
  };

  const onEvent = ({ action = '', eventName = '', error = '', metadata = {} }) => {
    const status = action.substr(action.lastIndexOf(':') + 1).toUpperCase();

    switch (status) {
      case 'CONNECTED':
        return onSuccess({ metadata });
      case 'EVENT':
        return logEvent({ logger: createLinkLog, eventName, metadata });
      case 'EXIT':
        return onExit({
          error,
          metadata,
          callback,
        });
      default:
        return null;
    }
  };

  const config = {
    token: linkToken,
    onSuccess,
    onExit: () => {
      onExit({
        error: null,
        metadata: {},
        callback,
      });
    },
    onEvent,
  };

  return (
    <Flex flex={1} justifyContent="center" alignItems="center" height="100%">
      {saving && (
        <Flex justify="center" direction="column" height="100%">
          <Flex justify="center" direction="column" mt={10} px={4}>
            <H1 fontSize={48} align="center">
              ⏲
            </H1>
            <H2 color="primary" align="center" mt={6}>
              Give us a minute.
            </H2>
            <Box h={4} />
            <Paragraph fontSize={['md', 'sm']} align="center">
              We're setting up your account.
            </Paragraph>
          </Flex>
        </Flex>
      )}
      {!saving && linkToken && <LinkLoader config={config} />}
    </Flex>
  );
};

export default MobileLinkCounterparty;
