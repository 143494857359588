import { gql } from '@apollo/client';

import { AccountFields } from 'accounts/graphql/AccountFields';

export default gql`
  query accounts($forceRefresh: Boolean) {
    accounts(forceRefresh: $forceRefresh) {
      ${AccountFields}
    }
  }
`;
