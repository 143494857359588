import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import { FAB } from 'shared/buttons';
import useAnalytics from 'shared/components/useAnalytics';
import { validEmail } from 'shared/lib/inputHelpers';
import isPresent from 'shared/lib/isPresent';
import { TextInput } from 'shared/inputs';
import { H1, H3, H5, Legal, ExternalLink } from 'shared/typography';

import ReCAPTCHA from 'react-google-recaptcha';

function CheckEmailForm() {
  const navigate = useNavigate();
  useAnalytics({ name: 'create-account' });

  const { checkEmail } = useStoreActions(actions => actions.auth);

  const [email, setEmail] = useState('');
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState('');
  const requireCaptcha = process.env.REACT_APP_CAPTCHA_KEY?.length > 0;
  const [captchaValid, setCaptchaValid] = useState(false);

  const handleEmailChange = value => {
    setEmail(value.toLowerCase());
    if (value.length > 50) {
      setError('Sorry, your email address cannot be longer than 50 characters.');
    } else {
      setError('');
    }
  };

  const checkEmailValidity = () => {
    if (!validEmail(email)) {
      setError('Sorry, your email must be a valid email address.');
    }
  };

  const checkInvitation = () => {
    if (requireCaptcha && !captchaValid) {
      setError('Please confirm the captcha below.');
      return;
    }
    checkEmailValidity();
    if (validEmail(email)) {
      setSaving(true);
      checkEmail({
        variables: { email },
        onInvited: token => {
          setSaving(false);
          navigate(`/auth/invited/steps/intro-steps?claimtoken=${token}`);
        },
        onInvitedAdditionalOwner: token => {
          setSaving(false);
          window.location.href = `${process.env.REACT_APP_NEXT_URL}/welcome/additional?token=${token}`;
        },
        onNewUser: () => {
          setSaving(false);
          navigate('/auth/welcome/create');
        },
        onPfmUser: () => {
          setSaving(false);
          navigate('/auth/welcome/pfm');
        },
        onExistingUser: () => {
          setSaving(false);
          setError('Sorry, that email address is already in use.');
        },
      });
    }
  };

  const onCaptcha = val => {
    if (val === null) {
      // means the captcha expired. it displays its own error.
      setCaptchaValid(false);
    } else {
      setCaptchaValid(true);
    }
  };

  const onCaptchaError = () => {
    setCaptchaValid(false);
  };

  const buttonDisabled = () => {
    if (error) {
      return true;
    }

    if (requireCaptcha) {
      return !captchaValid;
    }
    return !validEmail(email);
  };

  return (
    <Layout>
      <H1>Let's create an account</H1>
      <Box h={10} />
      <H3>Create a personal login, your partner will get their own.</H3>
      <Box h={15} />
      <TextInput
        id="userEmail"
        type="email"
        label="Email"
        error={error}
        autoComplete="email"
        value={email}
        onChange={handleEmailChange}
        onEnter={checkInvitation}
        placeholder="chrissy@cooks.com"
        onBlur={checkEmailValidity}
      />
      {requireCaptcha && (
        <>
          <Box h={10} />
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            onChange={onCaptcha}
            onError={onCaptchaError}
          />
        </>
      )}
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB
            isLoading={saving}
            isDisabled={buttonDisabled()}
            onClick={checkInvitation}
          />
        </Flex>
        {isPresent(error) && (
          <>
            <Box h={10} />
            <Flex justify="flex-end">
              <H5 as={Link} to="/auth/login" color="primary">
                Already have an account? Login
              </H5>
            </Flex>
          </>
        )}
        <Box h={8} />
        <Flex justify="flex-end">
          <Legal>
            By creating an account, you agree to Zeta’s{' '}
            <ExternalLink as="i" href="https://www.askzeta.com/privacy-policy">
              Privacy Policy
            </ExternalLink>{' '}
            &{' '}
            <ExternalLink as="i" href="https://www.askzeta.com/terms-of-use">
              Terms of Use
            </ExternalLink>
            .
          </Legal>
        </Flex>
      </Box>
      <Box h={20} />
    </Layout>
  );
}

export default CheckEmailForm;
