import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import { useStoreActions } from 'easy-peasy';

import Layout from 'auth/components/Layout';
import { FAB } from 'shared/buttons';
import { H1, Legal, H3 } from 'shared/typography';

function AddMoney() {
  const { trackAnalytics } = useStoreActions(actions => actions.app);
  const navigate = useNavigate();
  const location = useLocation();

  const moveForward = () => {
    trackAnalytics({
      name: 'fund-account-interaction',
      data: { interaction: 'start-fund-account', device: 'web' },
    });
    navigate('/link/init', {
      state: {
        returnTo: '/auth/finalize-transfer',
        exitTo: location.pathname,
      },
    });
  };
  const goToFinal = () => navigate('/auth/final');

  return (
    <Layout>
      <H1>Add money to your new account</H1>
      <Box h={10} />
      <H3>
        Make a deposit to get your virtual debit card as soon as your download the app.
      </H3>

      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB onClick={moveForward} />
        </Flex>
        <Box h={10} />
        <Flex justify="flex-end">
          <button type="button" style={buttonStyle} onClick={goToFinal}>
            <Legal>I’ll do this in the app</Legal>
          </button>
        </Flex>
      </Box>
      <Box h={20} />
    </Layout>
  );
}

const buttonStyle = { outline: 'none' };

export default AddMoney;
