import React from 'react';
import { PropTypes } from 'prop-types';
import { Box } from '@chakra-ui/react';

import ZetaLogoLight from 'shared/svg/ZetaLogoLight';
import ZetaLogoDark from 'shared/svg/ZetaLogoDark';

const ImageLayout = ({ children, dark, ...rest }) => {
  return (
    <Box {...rest}>
      <Box position="absolute" top={36} left={50}>
        {dark ? <ZetaLogoLight /> : <ZetaLogoDark />}
      </Box>
      {children}
    </Box>
  );
};

ImageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  dark: PropTypes.bool,
};
ImageLayout.defaultProps = { dark: true };

export default ImageLayout;
