import React from 'react';
import { PropTypes } from 'prop-types';

import { Text } from '@chakra-ui/react';

const H2 = ({ children, as, lineHeight, fontWeight, ...rest }) => {
  return (
    <Text
      my={0}
      as={as}
      fontSize={['lg', 'xl']} // 20px
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      {...rest}
    >
      {children}
    </Text>
  );
};

H2.propTypes = {
  children: PropTypes.node,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
};
H2.defaultProps = {
  children: null,
  as: 'h2',
  lineHeight: '7',
  fontWeight: 'semibold',
};

export default H2;
