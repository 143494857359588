import React from 'react';
import { PropTypes } from 'prop-types';
import { IconButton } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { RightArrowIcon } from 'shared/icons';

const FAB = ({ darkmode, ...rest }) => {
  return (
    <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      <IconButton
        isRound
        bg={darkmode ? 'iceberg' : 'greenPea'}
        color={darkmode ? 'greenPea' : 'iceberg'}
        aria-label="Continue"
        icon={<RightArrowIcon />}
        size="lg"
        {...rest}
      />
    </motion.div>
  );
};

FAB.propTypes = { darkmode: PropTypes.bool };
FAB.defaultProps = { darkmode: false };

export default FAB;
