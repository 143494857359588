import React from 'react';
import { Icon } from '@chakra-ui/react';

const Progress3Icon = props => (
  <Icon viewBox="0 0 40 40" w="36px" h="36px" {...props}>
    <g fill="transparent">
      <path
        d="M23.4453 17.2031C23.4453 17.9271 23.2344 18.5312 22.8125 19.0156C22.3906 19.4948 21.7969 19.8177 21.0312 19.9844V20.0469C21.9479 20.1615 22.6354 20.4479 23.0938 20.9062C23.5521 21.3594 23.7812 21.9635 23.7812 22.7188C23.7812 23.8177 23.3932 24.6667 22.6172 25.2656C21.8411 25.8594 20.737 26.1562 19.3047 26.1562C18.0391 26.1562 16.9714 25.9505 16.1016 25.5391V23.9062C16.5859 24.1458 17.099 24.3307 17.6406 24.4609C18.1823 24.5911 18.7031 24.6562 19.2031 24.6562C20.0885 24.6562 20.75 24.4922 21.1875 24.1641C21.625 23.8359 21.8438 23.3281 21.8438 22.6406C21.8438 22.0312 21.6016 21.5833 21.1172 21.2969C20.6328 21.0104 19.8724 20.8672 18.8359 20.8672H17.8438V19.375H18.8516C20.6745 19.375 21.5859 18.7448 21.5859 17.4844C21.5859 16.9948 21.4271 16.6172 21.1094 16.3516C20.7917 16.0859 20.3229 15.9531 19.7031 15.9531C19.2708 15.9531 18.8542 16.0156 18.4531 16.1406C18.0521 16.2604 17.5781 16.4974 17.0312 16.8516L16.1328 15.5703C17.1797 14.7995 18.3958 14.4141 19.7812 14.4141C20.9323 14.4141 21.8307 14.6615 22.4766 15.1562C23.1224 15.651 23.4453 16.3333 23.4453 17.2031Z"
        fill="#EBF7F1"
      />
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
        stroke="#98D3B6"
        strokeWidth="3"
      />
      <path
        d="M20.2132 2.01496C17.4242 1.89795 14.6485 2.46884 12.1177 3.67996C9.58698 4.89108 7.37459 6.7073 5.66525 8.97701C3.9559 11.2467 2.7992 13.9041 2.29169 16.7272C1.78418 19.5504 1.94059 22.4575 2.74786 25.2058C3.55514 27.9542 4.98985 30.464 6.93225 32.5259C8.87465 34.5877 11.2684 36.1417 13.9136 37.0582C16.5588 37.9747 19.3788 38.2271 22.1382 37.7943C24.8976 37.3615 27.5163 36.256 29.7757 34.5702"
        stroke="#EBF7F1"
        strokeWidth="2"
      />
    </g>
  </Icon>
);

export default Progress3Icon;
