import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Route, Navigate } from 'react-router-dom';

import { getLoginToken } from 'auth/lib/authentication';
import { getRoute } from 'auth/lib/utils';

const AuthRoute = ({ checkUser, checkPartner, checkInvited, ...props }) => {
  const { user } = useStoreState(state => state.auth);
  const { getUser } = useStoreActions(state => state.auth);

  const [userApplied, setUserApplied] = useState(false);
  const [partnerApplied, setPartnerApplied] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    getUser({ callback: () => setReady(true) });
  }, [getUser]);

  useEffect(() => {
    setUserApplied(!!user?.tpId);
    setPartnerApplied(!!user?.partner?.tpId);
  }, [user]);

  if (!ready) return null;
  const route = getRoute(user);

  if (route?.includes('https://')) {
    window.location.href = route;
    return true;
  }

  if (checkUser && userApplied) return <Navigate to={route} />;
  if (checkPartner && partnerApplied) return <Navigate to={route} />;
  if (checkInvited && userApplied) return <Navigate to={route} />;

  return getLoginToken() ? <Route {...props} /> : <Navigate to="/auth/login" />;
};

AuthRoute.propTypes = {
  checkUser: PropTypes.bool,
  checkPartner: PropTypes.bool,
  checkInvited: PropTypes.bool,
};
AuthRoute.defaultProps = { checkUser: false, checkPartner: false, checkInvited: false };

export default AuthRoute;
