import { gql } from '@apollo/client';

export default gql`
  query checkEmailStatus($email: String!) {
    checkEmailStatus(email: $email) {
      authenticatable
      pendingInvitationToken
      pendingInvitationType
      registered
      firstName
      partnerFirstName
    }
  }
`;
