import React from 'react';
import { PropTypes } from 'prop-types';

import { Text } from '@chakra-ui/react';

const Caption = ({ children, as, fontSize, lineHeight, fontWeight, ...rest }) => {
  return (
    <Text
      my={0}
      as={as}
      fontSize={['xxs', 'xs']} // 12px
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      {...rest}
    >
      {children}
    </Text>
  );
};

Caption.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
};
Caption.defaultProps = {
  as: 'p',
  fontSize: 'xs', // 12px
  lineHeight: '4',
  fontWeight: 'normal',
};

export default Caption;
