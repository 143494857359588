import { gql } from '@apollo/client';

import { userFields } from 'auth/graphql/UserFields';

export default gql`
  mutation verifyPasswordReset($input: VerifyPasswordResetInput!) {
    verifyPasswordReset(input: $input) {
      tempPass
      authToken
      user {
        ${userFields}
      }
      email
      errors {
        message
        path
      }
    }
  }
`;
