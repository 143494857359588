import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';

import GoToMobile from 'app/pages/GoToMobile';
import AppStoreRedirect from 'auth/pages/AppStoreRedirect';
import AuthRouter from 'auth/AuthRouter';
import useMagicLink from 'auth/hooks/useMagicLink';
import Welcome from 'auth/pages/Welcome';
import LinkRouter from 'plaid/LinkRouter';
import CardRouter from 'cards/CardRouter';

const MainRouter = () => {
  useMagicLink();
  const { getKeys } = useStoreActions(actions => actions.app);

  useEffect(() => {
    getKeys();
  }, [getKeys]);

  return (
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="activate/*" element={<GoToMobile />} />
      <Route path="auth/*" element={<AuthRouter />} />
      <Route path="link/*" element={<LinkRouter />} />
      <Route path="cards/*" element={<CardRouter />} />
      <Route path="appstore/*" element={<AppStoreRedirect />} />

      <Route path="chat/*" element={<GoToMobile />} />
      <Route path="messages/*" element={<GoToMobile />} />
      <Route path="goals/*" element={<GoToMobile />} />
      <Route path="settings/*" element={<GoToMobile />} />
      <Route path="opt-in-reminder/*" element={<GoToMobile />} />
      <Route path="overview/*" element={<GoToMobile />} />
      <Route path="check_deposits/*" element={<GoToMobile />} />
      <Route path="transfers/*" element={<GoToMobile />} />
      <Route path="go-mobile" element={<GoToMobile />} />

      <Route path="*" element={<GoToMobile />} />
    </Routes>
  );
};

export default MainRouter;
