export const cardFields = `
id
accountId
activatedAt
bankClient
color
secondaryColor
modality
estimatedArrivesOn
lastFour
name
status
utilization{
    limitAmountInCents
    percUtilized
    remainingVelocity
    velocity
}
`;
