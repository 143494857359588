import { gql } from '@apollo/client';

import { TeamFields } from 'accounts/graphql/TeamFields';

export default gql`
  query teams {
    teams {
      ${TeamFields}
    }
  }
`;
