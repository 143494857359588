export const ExternalAccountFields = `
id
institutionName
accountType
mask
nextPaymentDueDate
aprPercentageX100
balanceInCents
lastStatementBalanceInCents
lastStatementIssueDate
minimumPaymentAmountInCents
linked
`;
