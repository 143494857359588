import React from 'react';
import { Icon } from '@chakra-ui/react';

const RightArrowIcon = props => (
  <Icon viewBox="-1 -1 26 20" {...props}>
    <path
      d="M1 10C1 10.7463 1.60895 11.3521 2.35927 11.3521L20.1254 11.3521L14.8741 16.5754C14.3426 17.1041 14.3426 17.96 14.8741 18.4873L14.9909 18.6035C15.5224 19.1322 16.3829 19.1322 16.913 18.6035L24.6014 10.9559C25.1329 10.4273 25.1329 9.57136 24.6014 9.04405L16.913 1.39649C16.3815 0.867836 15.521 0.867836 14.9909 1.39649L14.8741 1.51268C14.3426 2.04134 14.3426 2.89727 14.8741 3.42457L20.1254 8.64794L2.35927 8.64794C1.60895 8.64794 1 9.25366 1 10Z"
      fill="currentColor"
    />
  </Icon>
);

export default RightArrowIcon;
