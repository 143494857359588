export default {
  px: '1px',
  0: '0',
  1: '0.25rem', // 4px
  2: '0.5rem', // 8px
  3: '0.75rem', // 12px
  4: '1rem', // 16px
  5: '1.25rem', // 20px
  6: '1.5rem', // 24px
  7: '1.75rem', // 28px
  8: '2rem', // 32px
  10: '2.5rem', // 40px
  12: '3rem', // 48px
  15: '3.75rem', // 60px
  16: '4rem', // 64px
  18: '4.5rem', // 72px
  20: '5rem', // 80px
  24: '6rem', // 96px
  26: '7.5rem', // 120px
  32: '8rem', // 128px
  36: '8.75rem', // 140px
  40: '10rem', // 160px
  45: '11.5rem', // 184px
  48: '12rem', // 192px
  55: '13.75rem', // 220px
  56: '14rem', // 224px
  64: '16rem', // 256px
  66: '18rem', // 288px
  67: '19rem', // 304px
  68: '20rem', // 320px
  69: '22rem',
  70: '31.25rem', // 500px
  74: '33.125rem', // 530px
  78: '37rem', // 530px
  80: '40rem', // 640px
};
