import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';
import Layout from 'auth/components/Layout';
import ErrorBox from 'shared/components/ErrorBox';

import { FAB } from 'shared/buttons';
import { MoneyInput, TextInput } from 'shared/inputs';
import useSeenFlag from 'shared/hooks/useSeenFlag';
import { H1, H3, Paragraph } from 'shared/typography';

const FinalizeTransfer = () => {
  const { markSeen } = useSeenFlag();
  const navigate = useNavigate();
  const location = useLocation();

  const { transferSource, transferTarget } = useStoreState(state => state.transfers);
  const { getAccounts } = useStoreActions(actions => actions.accounts);
  const { createTransfer, setTransferTarget, setTransferSource } = useStoreActions(
    actions => actions.transfers,
  );
  const { getCounterparty } = useStoreActions(actions => actions.plaid);
  const { getEstimatedCompletion } = useStoreActions(actions => actions.transfers);

  const search = location.search || '';
  const { obj_id: counterpartyId } = querystring.parse(search);

  const [amount, setAmount] = useState(10000);
  const [disabled, setDisabled] = useState(false);
  const [processOn, setProcessOn] = useState(null);
  const [error, setError] = useState({});
  const [amountError, setAmountError] = useState({});
  const { trackAnalytics } = useStoreActions(actions => actions.app);

  useEffect(() => {
    if (sessionStorage.getItem('funded')) {
      navigate('/auth/final');
    }
  }, [navigate]);
  useEffect(() => {
    if (counterpartyId && !transferSource) {
      getCounterparty({
        variables: { id: counterpartyId },
        callback: counterparty => {
          setTransferSource(counterparty);
        },
      });
    }
    if (!transferTarget) {
      getAccounts({
        callback: accounts => {
          setTransferTarget(accounts[0]);
        },
      });
    }
  }, [
    counterpartyId,
    getCounterparty,
    setTransferSource,
    setTransferTarget,
    transferSource,
    transferTarget,
    getAccounts,
  ]);

  useEffect(() => {
    if (transferSource?.id && transferTarget?.id) {
      const date = moment().format('YYYY-MM-DD');
      getEstimatedCompletion({
        variables: {
          sendOn: date,
          fromType: transferSource?.transferrableType,
          fromId: transferSource?.id,
          toId: transferTarget?.id,
          toType: 'Account',
        },
        callback: estimate => {
          if (estimate?.adjustedSendOn) {
            setProcessOn(moment(estimate.adjustedSendOn).format('YYYY-MM-DD'));
          } else {
            setProcessOn(date);
          }
        },
      });
    }
  }, [
    transferSource?.id,
    transferTarget?.id,
    getEstimatedCompletion,
    transferSource?.transferrableType,
  ]);

  const goToCongrats = () => {
    sessionStorage.setItem('funded', true);
    navigate('/auth/congrats-two');
  };
  const isGreaterthanTen = () => {
    if (amount < 1000) {
      setError({ amountInCents: 'Our minimum deposit is $10.00' });
      setDisabled(true);
      return false;
    }
    return true;
  };
  const submitSingleTransfer = () => {
    setDisabled(true);
    if (setTransferTarget && isGreaterthanTen()) {
      createTransfer({
        variables: {
          amountInCents: amount,
          fromType: transferSource?.transferrableType,
          fromId: transferSource?.id,
          toType: transferTarget?.transferrableType,
          toId: transferTarget?.id,
          processOn,
        },
        callback: () => {
          trackAnalytics({
            name: 'fund-account-interaction',
            data: { interaction: 'fund-transfer-details', device: 'web' },
          });
          markSeen('funding');
          goToCongrats();
        },
        onError: errors => {
          setError(errors);
          setDisabled(false);
        },
      });
    }
  };

  const handleEvent = e => e.preventDefault();

  const handleAmountChange = currentAmount => {
    setAmount(currentAmount);
    setError({});
    setAmountError({});
    setDisabled(false);
  };
  const handleAmountBlur = () => {
    if (amount < 1000) {
      setAmountError({ amountInCents: 'Our minimum deposit is $10.00' });
      setDisabled(true);
    }
  };
  return (
    <Layout h="6xl">
      <H1>How much do you want to transfer?</H1>
      <Box h={10} />
      {error.amountInCents ? (
        <ErrorBox>{error.amountInCents}</ErrorBox>
      ) : (
        <H3>We suggest $100 to get started. </H3>
      )}
      <Box h={15} />
      <form onSubmit={handleEvent}>
        <MoneyInput
          label="Amount"
          value={amount}
          onChange={handleAmountChange}
          onBlur={handleAmountBlur}
        />
        <Box h={3} />
        {amountError.amountInCents ? (
          <Paragraph color="danger"> Our minimum deposit is $10.00</Paragraph>
        ) : (
          <Paragraph>
            Transfer from {transferSource?.plaidInstitutionName} {transferSource?.mask}
          </Paragraph>
        )}
        <Box h={10} />
        {processOn && (
          <>
            <TextInput
              label="Date"
              isReadOnly
              value={moment(processOn).format('MMM DD')}
              onChange={() => {}}
            />
            <Box h={3} />
            <Paragraph>
              {' '}
              Your transfer will proccess on this date. Funds may be held for 3 business
              days.
            </Paragraph>
          </>
        )}
        <Box h={15} />
        <Box h={15} />
      </form>
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB isLoading={false} isDisabled={disabled} onClick={submitSingleTransfer} />
        </Flex>
        <Box h={10} />
        <Flex justify="flex-end">
          <Paragraph marginRight={1} fontStyle="italic">
            By continuing you agree to our
          </Paragraph>
          <Paragraph color="greenPea" fontStyle="italic" textDecoration="underline">
            <a href="https://www.askzeta.com/zeta-piermont-account-agreement">
              Electronic Funds Authorization Agreement.
            </a>
          </Paragraph>
        </Flex>
      </Box>
    </Layout>
  );
};

export default FinalizeTransfer;
