import React from 'react';

export default () => (
  <svg
    width="131"
    height="45"
    viewBox="0 0 131 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000216361 10.0608L10.061 0V10.0608H0.000216361ZM21.6105 11.7131V21.6081H11.7155L21.6105 11.7131ZM0.20753 11.0209L10.0605 20.8739V11.0209H0.20753ZM21.6104 32.4228L11.7553 22.5677H21.6104V32.4228ZM11.0219 11.0204V20.9448L20.9462 11.0204H11.0219ZM21.0785 10.061H11.0219V0.0033167L21.0785 10.061ZM22.5687 21.6081V11.7131L32.4626 21.6081H22.5687ZM34.1174 10.0608V0L44.1782 10.0608H34.1174ZM43.9704 11.0204L34.1174 20.8744V11.0204H43.9704ZM22.5696 32.4228L32.4237 22.5677H22.5696V32.4228ZM33.1572 11.0204V20.9448L23.2339 11.0204H33.1572ZM33.157 10.061H23.0994L33.157 0.0033167V10.061ZM0 22.568L10.0608 32.6288V22.568H0ZM9.43831 21.6081H0V12.0228L9.43831 21.6081ZM20.9882 33.1576L11.0219 23.1913V33.1576H20.9882ZM21.6093 44.1774L11.5506 34.1177H21.6093V44.1774ZM34.1174 22.568V32.6298L44.1782 22.568H34.1174ZM44.1782 21.6081H34.741L44.1782 12.0228V21.6081ZM23.1913 33.1576L33.1576 23.1913V33.1576H23.1913ZM22.5687 44.1774V34.1177H32.6285L22.5687 44.1774Z"
        fill="#CB76E8"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="45"
        height="45"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.000216361 10.0608L10.061 0V10.0608H0.000216361ZM21.6105 11.7131V21.6081H11.7155L21.6105 11.7131ZM0.20753 11.0209L10.0605 20.8739V11.0209H0.20753ZM21.6104 32.4228L11.7553 22.5677H21.6104V32.4228ZM11.0219 11.0204V20.9448L20.9462 11.0204H11.0219ZM21.0785 10.061H11.0219V0.0033167L21.0785 10.061ZM22.5687 21.6081V11.7131L32.4626 21.6081H22.5687ZM34.1174 10.0608V0L44.1782 10.0608H34.1174ZM43.9704 11.0204L34.1174 20.8744V11.0204H43.9704ZM22.5696 32.4228L32.4237 22.5677H22.5696V32.4228ZM33.1572 11.0204V20.9448L23.2339 11.0204H33.1572ZM33.157 10.061H23.0994L33.157 0.0033167V10.061ZM0 22.568L10.0608 32.6288V22.568H0ZM9.43831 21.6081H0V12.0228L9.43831 21.6081ZM20.9882 33.1576L11.0219 23.1913V33.1576H20.9882ZM21.6093 44.1774L11.5506 34.1177H21.6093V44.1774ZM34.1174 22.568V32.6298L44.1782 22.568H34.1174ZM44.1782 21.6081H34.741L44.1782 12.0228V21.6081ZM23.1913 33.1576L33.1576 23.1913V33.1576H23.1913ZM22.5687 44.1774V34.1177H32.6285L22.5687 44.1774Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.0191 35.4261L54.0979 1.50488L21.9322 33.6706L-4.64935 7.08901L-31.0964 33.5361L2.82476 67.4573L24.3908 45.8912L50.9724 72.4728L88.0191 35.4261Z"
          fill="#0D342D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.9319 -19.5596L48.379 6.88751L21.9319 33.3346L-4.5152 6.88751L21.9319 -19.5596Z"
          fill="#3EB579"
        />
      </g>
      <path
        d="M71.0494 33.4854H54.5531V30.4378L65.1817 15.1999H54.8412V11.3185H70.7613V14.3509L60.1479 29.6039H71.0494V33.4854ZM87.9955 33.4854H75.2291V11.3185H87.9955V15.1696H79.9293V20.0366H87.4345V23.8878H79.9293V29.6039H87.9955V33.4854ZM101.833 33.4854H97.1331V15.2303H91.1138V11.3185H107.853V15.2303H101.833V33.4854ZM125.481 33.4854L123.874 28.209H115.793L114.185 33.4854H109.121L116.945 11.2275H122.691L130.545 33.4854H125.481ZM122.752 24.2668C121.266 19.4857 120.427 16.7818 120.235 16.1551C120.053 15.5284 119.922 15.0332 119.841 14.6693C119.507 15.9631 118.552 19.1623 116.975 24.2668H122.752Z"
        fill="#0D342D"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="131" height="44.637" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
