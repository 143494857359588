/* eslint-disable no-shadow */
import { action, actionOn, thunk } from 'easy-peasy';

import CHECK_EMAIL from 'auth/graphql/CheckEmailStatusQuery';
import GET_INVITATION from 'auth/graphql/PendingInvitationQuery';
import GET_SENT_INVITATIONS from 'auth/graphql/UserSentInvitationQuery';
import CREATE_USER from 'auth/graphql/CreateUserMutation';
import EDIT_USER from 'auth/graphql/EditUserMutation';
import CREATE_INVITATION from 'auth/graphql/CreateInvitationMutation';
import EDIT_INVITATION from 'auth/graphql/EditInvitationMutation';
import SUBMIT_APPLICATION from 'auth/graphql/SubmitAccountApplicationMutation';
import GET_ACCOUNT_APPLICATION from 'auth/graphql/AccountApplicationQuery';
import LOGIN_USER from 'auth/graphql/LoginMutation';
import SEND_PASSWORD_RESET from 'auth/graphql/SendPasswordResetMutation';
import SEND_CODE_MUTATION from 'auth/graphql/SendInvitationCodeMutation';
import SEND_LOVE_NOTE from 'auth/graphql/SendLoveNoteMutation';
import SEND_REMINDER from 'auth/graphql/SendInvitationReminderMutation';
import CLAIM_INVITATION_MUTATION from 'auth/graphql/ClaimInvitationMutation';
import EDIT_PASSWORD from 'auth/graphql/EditPasswordMutation';
import RESET_PASSWORD from 'auth/graphql/ResetPasswordMutation';
import CURRENT_USER from 'auth/graphql/CurrentUserQuery';
import VERIFY_PASSWORD_RESET from 'auth/graphql/VerifyPasswordResetMutation';
import ZIP_LOOKUP from 'auth/graphql/ZipcodeAutocompleteQuery';
import ABANDON_PASS_PHONE from 'auth/graphql/AbandonPassPhoneMutation';
import EDIT_PARTNER from 'auth/graphql/EditPartnerMutation';
import PASS_PHONE from 'auth/graphql/InitiatePassPhoneMutation';
import RESET_PASS_PHONE from 'auth/graphql/ResetPartnerPassDeviceMutation';
import SUBMIT_USER from 'auth/graphql/SubmitPersonApplicationMutation';
import SEND_SMS from 'auth/graphql/SendSmsMutation';
import SUBSCRIBE_TO_LIST from 'auth/graphql/SubscribeToListMutation';

import { signOut, getDuuid } from 'auth/lib/authentication';
import { deepOmitTypeName, parseMutationErrors } from 'shared/lib/parseGraphql';
import branch from 'branch-sdk';

export const TOKEN_ID_IDEMPOTENCY_ERROR = 'TokenAlreadyExists';

const authModel = {
  appId: '',
  email: '',
  checkingEmail: false,
  creatingUser: false,
  partnerName: '',
  firstName: '',
  claimToken: '',
  partnerFlow: false,
  shortCircuit: false,
  onboardingDone: false,
  user: {},
  partner: {},
  lastFour: '',
  partnerLastFour: '',
  loginPin: '',
  rememberMe: null,
  invitedPartner: {},
  applicationStatus: '',
  pfmUser: false,
  checkingInvite: true,
  invite: {},
  tempPass: '',
  firstVisit: false,
  sentInvitations: [],
  inviteSent: false,
  loggedIn: false,
  checkingSentInvitations: false,
  seenFTE: false,
  loginEmail: '',
  MFACode: '',

  setLoggedIn: action((state, payload) => {
    state.loggedIn = payload;
  }),

  setMFACode: action((state, payload) => {
    state.MFACode = payload;
  }),

  updateSeenFTE: action((state, payload) => {
    state.seenFTE = payload;
  }),

  setAppId: action((state, payload) => {
    state.appId = payload;
  }),

  setFirstVisit: action((state, payload) => {
    state.firstVisit = payload;
  }),

  setTempPass: action((state, payload) => {
    state.tempPass = payload;
  }),

  setInvite: action((state, payload) => {
    state.invite = payload;
  }),

  setInviteSent: action((state, payload) => {
    state.inviteSent = payload;
  }),

  setSentInvitations: action((state, payload) => {
    state.sentInvitations = payload;
  }),

  setPfmUser: action((state, payload) => {
    state.pfmUser = payload;
  }),

  setApplicationStatus: action((state, payload) => {
    state.applicationStatus = payload;
  }),

  setInvitedPartner: action((state, payload) => {
    state.invitedPartner = payload;
  }),

  setPartnerFlow: action((state, payload) => {
    state.partnerFlow = payload;
  }),

  setShortCircuit: action((state, payload) => {
    state.shortCircuit = payload;
  }),

  setOnboardingDone: action((state, payload) => {
    state.onboardingDone = payload;
  }),

  setToken: action((state, payload) => {
    state.claimToken = payload;
  }),

  setPin: action((state, payload) => {
    state.loginPin = payload;
  }),

  setRememberMe: action((state, payload) => {
    state.rememberMe = payload;
  }),

  setLastFour: action((state, payload) => {
    state.lastFour = payload;
  }),

  setPartnerLastFour: action((state, payload) => {
    state.partnerLastFour = payload;
  }),

  setPartnerName: action((state, payload) => {
    state.partnerName = payload;
  }),

  getPartnerName: actionOn(
    actions => [actions.setUser],

    state => {
      state.partnerName = state.user?.partner?.firstName
        ? state.user?.partner?.firstName
        : state.user?.pendingPartnerFirstName;
    },
  ),

  setFirstName: action((state, payload) => {
    state.firstName = payload;
  }),

  setCheckingEmail: action((state, payload) => {
    state.checkingEmail = payload;
  }),

  setEmail: action((state, payload) => {
    state.email = payload;
  }),

  setLoginEmail: action((state, payload) => {
    state.loginEmail = payload;
  }),

  setCreatingUser: action((state, payload) => {
    state.creatingUser = payload;
  }),

  setUser: action((state, payload) => {
    state.user = payload;

    if (payload?.email) {
      state.email = payload?.email;
      // Sentry.setUser({ email: payload?.email });
    }
  }),

  setPartner: action((state, payload) => {
    state.partner = payload;
  }),

  setCheckingInvite: action((state, payload) => {
    state.checkingInvite = payload;
  }),

  setCheckingSentInvitations: action((state, payload) => {
    state.checkingSentInvitations = payload;
  }),

  updatePendingPartner: action((state, payload) => {
    state.user = {
      ...state.user,
      pendingPartnerFirstName: payload.pendingPartnerFirstName,
      pendingPartnerLastName: payload.pendingPartnerLastName,
      pendingRelationshipStatus: payload.pendingRelationshipStatus,
    };
  }),

  login: thunk(async (action, payload, { injections }) => {
    const { client } = injections;
    const duuid = getDuuid();
    const response = await client.mutate({
      mutation: LOGIN_USER,
      variables: { input: { ...payload.variables, duuid } },
    });

    const cleanResponse = deepOmitTypeName(response);
    if (cleanResponse.data?.login?.errors.length === 0) {
      const {
        newUser,
        user,
        token,
        pendingInvitationToken,
        requires2fa,
        pendingInvitationType,
      } = cleanResponse.data?.login;

      if (user) {
        payload?.partner ? action.setPartner(user) : action.setUser(user);
      }

      if (!newUser && pendingInvitationToken !== null && token !== null) {
        if (pendingInvitationType === 'additional_owner') {
          payload.onInvitedAdditionalOwner(pendingInvitationToken, token);
        } else {
          action.setToken(pendingInvitationToken);
          action.setLoggedIn(true);
          action.setPfmUser(true);
          action.setPartnerFlow(true);
          payload.onInvited(pendingInvitationToken);
        }
      } else if ((!user || newUser) && token) {
        payload?.needsOnboarding(token);
      } else if (requires2fa) {
        payload?.newDevice();
      } else {
        payload?.callback && payload?.callback(token, user);
      }
    } else {
      payload.onError && payload.onError(parseMutationErrors(cleanResponse));
    }
  }),

  checkEmail: thunk(async (action, payload, { injections }) => {
    action.setCheckingEmail(true);
    const { client } = injections;

    const response = await client.query({
      query: CHECK_EMAIL,
      variables: { ...payload.variables },
    });

    action.setEmail(payload.variables.email);

    const cleanResponse = deepOmitTypeName(response);

    const {
      firstName,
      partnerFirstName,
      authenticatable,
      pendingInvitationToken,
      pendingInvitationType,
      registered,
    } = cleanResponse.data.checkEmailStatus;

    action.setCheckingEmail(false);
    if (pendingInvitationType === 'additional_owner') {
      payload.onInvitedAdditionalOwner(pendingInvitationToken);
    } else if (pendingInvitationToken !== null && authenticatable && !registered) {
      action.setToken(pendingInvitationToken);
      action.setFirstName(firstName);
      action.setPartnerName(partnerFirstName);
      action.setPfmUser(true);
      action.setPartnerFlow(true);
      payload.onInvited && payload.onInvited(pendingInvitationToken);
    } else if (pendingInvitationToken !== null && !authenticatable && !registered) {
      action.setToken(pendingInvitationToken);
      action.setFirstName(firstName);
      action.setPartnerName(partnerFirstName);
      action.setPfmUser(false);
      action.setPartnerFlow(true);
      payload.onInvited && payload.onInvited(pendingInvitationToken);
    } else if (pendingInvitationToken === null && !authenticatable && !registered) {
      action.setPartnerFlow(false);
      payload.onNewUser && payload.onNewUser();
    } else if (pendingInvitationToken === null && authenticatable && !registered) {
      action.setFirstName(firstName);
      action.setPartnerFlow(false);
      payload.onPfmUser && payload.onPfmUser();
    } else {
      payload.onExistingUser && payload.onExistingUser();
    }
  }),

  createUser: thunk(async (action, payload, { getState, injections }) => {
    const { client } = injections;
    action.setCreatingUser(true);
    const duuid = getDuuid();

    const { email } = getState();

    const response = await client.mutate({
      mutation: CREATE_USER,
      variables: { input: { ...payload.variables, email, duuid } },
    });

    const cleanResponse = deepOmitTypeName(response);

    action.setCreatingUser(false);

    if (cleanResponse.data?.createUser?.errors.length === 0) {
      action.setUser(cleanResponse.data.createUser.user);
      if (cleanResponse.data.createUser.user?.globalId) {
        branch.setIdentity(cleanResponse.data.createUser.user.globalId);
      }

      payload.callback && payload.callback(cleanResponse.data.createUser.authToken);
    } else {
      payload.onError && payload.onError();
    }
  }),

  editUser: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: EDIT_USER,
      variables: { input: payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.editUser?.errors.length === 0) {
      action.setUser(cleanResponse.data.editUser.user);
      if (cleanResponse.data.editUser.user.partner) {
        action.setPartner(cleanResponse.data.editUser.user.partner);
      }
      payload.callback && payload.callback();
    } else {
      payload.onError && payload.onError(parseMutationErrors(cleanResponse));
    }
  }),

  getToken: thunk(async (action, payload, { injections, getStoreState }) => {
    const { axios, errorLog } = injections;
    const {
      app,
      auth: { user, partner },
    } = getStoreState();
    const ikey = payload.forPartner ? partner.universalId : user.universalId;
    axios
      .post(
        process.env.REACT_APP_TOKENID_URL,
        { ...payload.variables },
        {
          headers: {
            'x-api-key': app?.appKeys?.tokenIdKey,
            'Idempotency-Key': ikey,
          },
          timeout: 15000,
        },
      )
      .then(response => {
        if (response.status === 200) {
          payload?.callback && payload?.callback(response?.data?.id);
        } else {
          errorLog(response.status, JSON.stringify(response));
          payload?.onError && payload.onError();
        }
      })
      .catch(error => {
        const { data } = error.response || {};

        if (data?.error_code === TOKEN_ID_IDEMPOTENCY_ERROR) {
          errorLog(
            error,
            JSON.stringify({
              message: `User ${user.id} attempted to submit an application for existing idempotency key`,
              conflictingId: data?.existing_idempotency_key,
              response: error.response,
            }),
          );
          payload?.onError &&
            payload.onError({
              error: 'This SSN cannot be used to open an account. Please try again.',
            });
        } else {
          errorLog(
            error,
            JSON.stringify({
              message: `Encountered an error generating a token for user ${user.id}`,
              response: error.response,
            }),
          );
          payload?.onError &&
            payload.onError({ error: 'Something went wrong, please try again.' });
        }
      });
  }),

  editPartner: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: EDIT_PARTNER,
      variables: { input: payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.editPartner?.errors.length === 0) {
      action.setPartner(cleanResponse.data.editPartner.user);
      payload.callback && payload.callback();
    } else {
      payload.onError && payload.onError(parseMutationErrors(cleanResponse));
    }
  }),

  invitePartner: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: CREATE_INVITATION,
      variables: { input: payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.createInvitation?.errors.length === 0) {
      if (cleanResponse.data?.createInvitation?.pendingInvitationToken !== null) {
        action.setToken(cleanResponse.data?.createInvitation?.pendingInvitationToken);
        action.setInvitedPartner(cleanResponse.data?.createInvitation?.invitation);
        action.setShortCircuit(true);
        payload.onInvited && payload.onInvited();
      } else {
        action.setInvitedPartner(cleanResponse.data?.createInvitation?.invitation);
        action.updatePendingPartner(payload.variables);
        action.setShortCircuit(false);
        payload.callback && payload.callback();
      }
    } else {
      payload?.onError && payload.onError(parseMutationErrors(cleanResponse));
    }
  }),

  editInvitation: thunk(async (action, payload, { injections, getState }) => {
    const { client } = injections;

    const { invitedPartner, user } = getState();
    const invitationId = invitedPartner?.id ?? user?.invitations[0]?.id;

    const response = await client.mutate({
      mutation: EDIT_INVITATION,
      variables: { input: { id: invitationId, ...payload.variables } },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.editInvitation?.errors.length === 0) {
      action.setInvitedPartner(cleanResponse.data?.editInvitation?.invitation);
      action.updatePendingPartner(payload.variables);
      payload.callback && payload.callback();
    } else {
      payload?.onError && payload.onError(parseMutationErrors(cleanResponse));
    }
  }),

  sendPasswordReset: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: SEND_PASSWORD_RESET,
      variables: { input: payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.sendPasswordReset?.ok) {
      payload.callback && payload.callback(cleanResponse.data?.sendPasswordReset);
    } else {
      payload?.onError && payload.onError();
    }
  }),

  editPassword: thunk(async (action, payload, { getState, injections }) => {
    const { client } = injections;
    const { tempPass } = getState();

    const response = await client.mutate({
      mutation: EDIT_PASSWORD,
      variables: tempPass
        ? { input: { ...payload.variables, password: tempPass } }
        : { input: { ...payload.variables } },
    });

    const cleanResponse = deepOmitTypeName(response);
    if (cleanResponse.data?.editPassword?.errors.length === 0) {
      payload.callback && payload.callback(cleanResponse.data?.editPassword);
    } else {
      payload?.onError && payload.onError();
    }
  }),

  resetPassword: thunk(async (action, payload, { getState, injections }) => {
    const { client } = injections;
    const { tempPass } = getState();

    const response = await client.mutate({
      mutation: RESET_PASSWORD,
      variables: tempPass
        ? { input: { ...payload.variables, password: tempPass } }
        : { input: { ...payload.variables } },
    });

    const cleanResponse = deepOmitTypeName(response);
    if (cleanResponse.data?.resetForgotPassword?.errors.length === 0) {
      payload.callback && payload.callback(cleanResponse.data?.resetForgotPassword);
    } else {
      payload?.onError && payload.onError();
    }
  }),

  getInvite: thunk(async (action, payload, { injections, getState }) => {
    action.setCheckingInvite(true);
    const { client } = injections;

    const { claimToken } = getState();

    const response = await client.query({
      query: GET_INVITATION,
      variables: { claimToken },
    });

    const cleanResponse = deepOmitTypeName(response);
    action.setCheckingInvite(false);
    if (cleanResponse.data.pendingInvitation) {
      action.setInvite(cleanResponse.data.pendingInvitation);
    }
    payload?.callback && payload.callback(cleanResponse.data.pendingInvitation);
  }),

  getSentInvitations: thunk(async (action, payload, { injections }) => {
    action.setCheckingSentInvitations(true);
    const { client } = injections;
    const response = await client.query({
      query: GET_SENT_INVITATIONS,
      fetchPolicy: 'network-only',
    });

    const cleanResponse = deepOmitTypeName(response);
    action.setCheckingSentInvitations(false);
    action.setSentInvitations(cleanResponse.data.currentUser.invitations);
    payload.callback && payload.callback();
  }),

  sendCode: thunk(async (action, payload, { getState, injections }) => {
    const { client } = injections;

    const { claimToken } = getState();

    await client.mutate({
      mutation: SEND_CODE_MUTATION,
      variables: { input: { claimToken } },
    });
  }),

  validateCode: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: CLAIM_INVITATION_MUTATION,
      variables: { input: payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);
    if (cleanResponse.data.claimInvitation.errors.length > 0) {
      payload.onError && payload.onError(parseMutationErrors(cleanResponse));
    } else {
      action.setTempPass(cleanResponse.data.claimInvitation.tempPass);
      payload.callback &&
        payload.callback({
          authToken: cleanResponse.data.claimInvitation.authToken,
          tempPass: cleanResponse.data.claimInvitation.tempPass,
        });
    }
  }),

  passPhone: thunk(async (action, payload, { getState, injections }) => {
    const { client } = injections;

    const { user } = getState();

    const emailResponse = await client.query({
      query: CHECK_EMAIL,
      variables: { email: user?.invitations[0]?.email },
    });

    const cleanEmailResponse = deepOmitTypeName(emailResponse);

    const { pendingInvitationToken } = cleanEmailResponse.data.checkEmailStatus;

    const response = await client.mutate({
      mutation: PASS_PHONE,
      variables: { input: { claimToken: pendingInvitationToken } },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.initiatePassPhone?.errors.length === 0) {
      action.setPartner(cleanResponse.data?.initiatePassPhone?.partner);
      action.setPfmUser(cleanResponse.data?.initiatePassPhone?.existingPfm);
      action.setTempPass(cleanResponse.data?.initiatePassPhone?.tempPass);
      payload.callback && payload.callback();
    } else {
      payload?.onError && payload.onError();
    }
  }),

  resetPartnerPassDevice: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: RESET_PASS_PHONE,
      variables: { input: {} },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.resetPartnerPassDevice?.errors.length === 0) {
      action.setPartner(cleanResponse.data?.resetPartnerPassDevice?.partner);
      action.setPfmUser(cleanResponse.data?.resetPartnerPassDevice?.existingPfm);
      action.setTempPass(cleanResponse.data?.resetPartnerPassDevice?.tempPass);
      payload?.callback(cleanResponse.data?.resetPartnerPassDevice?.existingPfm);
    } else {
      payload?.onError && payload.onError();
    }
  }),

  submitIndividualApp: thunk(async (action, payload, { injections }) => {
    const { client, errorLog } = injections;

    const response = await client.mutate({
      mutation: SUBMIT_USER,
      variables: { input: {} },
    });

    const cleanResponse = deepOmitTypeName(response);
    const submitResp = cleanResponse?.data?.submitPersonApplication;
    if (submitResp?.errors.length === 0 && submitResp?.user?.tpId) {
      action.setUser(submitResp?.user);
      if (submitResp?.user?.partner?.tpId) {
        payload.onComplete && payload.onComplete();
      } else {
        payload.onPartnerNeeded && payload.onPartnerNeeded();
      }
    } else {
      payload.onError && payload.onError(parseMutationErrors(cleanResponse));
      errorLog(JSON.stringify(submitResp?.errors));
    }
  }),

  submitPartnerApp: thunk(async (action, payload, { injections }) => {
    const { client, errorLog } = injections;

    const response = await client.mutate({
      mutation: SUBMIT_USER,
      variables: { input: { sendPartner: true } },
    });

    const cleanResponse = deepOmitTypeName(response);
    const submitResp = cleanResponse?.data?.submitPersonApplication;
    if (submitResp?.errors.length === 0 && submitResp?.user?.tpId) {
      action.setPartner(submitResp?.user);
      if (submitResp?.user?.partner?.tpId) {
        payload.onComplete && payload.onComplete();
      } else {
        payload.onPartnerNeeded && payload.onPartnerNeeded();
      }
    } else {
      payload?.onError?.(submitResp);
      errorLog(JSON.stringify(submitResp?.errors));
    }
  }),

  submitApplication: thunk(async (action, payload, { injections }) => {
    const { client, errorLog } = injections;

    const response = await client.mutate({
      mutation: SUBMIT_APPLICATION,
      variables: { input: {} },
    });

    const cleanResponse = deepOmitTypeName(response);
    const submitResp = cleanResponse?.data?.submitAccountApplication;
    if (submitResp?.errors.length === 0) {
      if (submitResp?.onboardingStatus === 'completed') {
        action.setAppId(submitResp?.accountApplication?.id);
        payload.onComplete && payload.onComplete(submitResp?.accountApplication?.id);
      }
    } else {
      payload.onError && payload.onError(parseMutationErrors(cleanResponse));
      errorLog(JSON.stringify(submitResp?.errors));
    }
  }),

  getAccountApplicationStatus: thunk(async (action, payload, { injections }) => {
    const { client } = injections;
    const response = await client.query({
      query: GET_ACCOUNT_APPLICATION,
      fetchPolicy: 'network-only',
      variables: { ...payload.variables },
    });
    const cleanResponse = deepOmitTypeName(response);
    payload.callback && payload.callback(cleanResponse.data?.accountApplication?.status);
  }),

  getUser: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.query({
      query: CURRENT_USER,
      fetchPolicy: 'network-only',
    });
    const cleanResponse = deepOmitTypeName(response);

    action.setUser(cleanResponse.data?.currentUser);
    if (cleanResponse.data?.currentUser?.partner) {
      action.setPartner(cleanResponse.data?.currentUser?.partner);
    }
    payload && payload.callback && payload.callback(cleanResponse.data?.currentUser);
  }),

  zipLookup: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.query({
      query: ZIP_LOOKUP,
      variables: { ...payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);

    payload.callback && payload.callback(cleanResponse?.data?.zipcodeAutocomplete);
  }),

  markSeen: thunk(async (action, payload, { injections, getState }) => {
    const { client } = injections;
    const { user } = getState();

    const newArray = user?.seenFtes?.includes(payload.variables)
      ? [...user?.seenFtes]
      : [...user?.seenFtes, payload.variables];

    const response = await client.mutate({
      mutation: EDIT_USER,
      variables: { input: { seenFtes: newArray } },
    });
    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.editUser?.errors.length === 0) {
      action.setUser(cleanResponse.data.editUser.user);
      payload.callback && payload.callback();
    } else {
      payload.onError && payload.onError(parseMutationErrors(cleanResponse));
    }
  }),

  sendNote: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: SEND_LOVE_NOTE,
      variables: { input: payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.sendLoveNote?.ok) {
      payload.callback && payload.callback();
    }
  }),

  sendReminder: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: SEND_REMINDER,
      variables: { input: payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.sendInvitationReminder?.ok) {
      payload.callback &&
        payload.callback(cleanResponse.data?.sendInvitationReminder.invitation);
    }
  }),

  verifyPasswordReset: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: VERIFY_PASSWORD_RESET,
      variables: { input: payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.verifyPasswordReset?.errors.length === 0) {
      action.setUser(cleanResponse.data?.verifyPasswordReset.user);
      action.setEmail(cleanResponse.data?.verifyPasswordReset.email);
      payload.callback && payload.callback(cleanResponse.data?.verifyPasswordReset);
    } else {
      payload?.onError && payload.onError(parseMutationErrors(cleanResponse));
    }
  }),

  abandonPassPhone: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: ABANDON_PASS_PHONE,
      variables: { input: {} },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.abandonPassPhone?.ok) {
      payload.callback && payload.callback();
    } else {
      payload?.onError && payload.onError();
    }
  }),

  subscribeToList: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: SUBSCRIBE_TO_LIST,
      variables: { input: payload.variables },
    });

    const cleanResponse = deepOmitTypeName(response);
    if (cleanResponse.data?.subscribeToList?.ok) {
      payload?.callback && payload.callback(cleanResponse.data?.subscribeToList);
    } else {
      payload?.onError && payload.onError();
    }
  }),

  logOut: thunk(async (action, payload, { injections, getStoreActions }) => {
    const { client } = injections;
    signOut({ path: '/' });

    // reset apollo and easy peasy states
    client.stop();
    client.clearStore();
    getStoreActions().reset();

    payload.callback && payload.callback();
  }),

  sendSms: thunk(async (action, payload, { injections }) => {
    const { client } = injections;

    const response = await client.mutate({
      mutation: SEND_SMS,
      variables: { input: { messageType: 'applink' } },
    });

    const cleanResponse = deepOmitTypeName(response);

    if (cleanResponse.data?.sendSms?.errors.length === 0) {
      payload && payload.callback && payload.callback();
    } else {
      payload && payload.onError && payload.onError(parseMutationErrors(cleanResponse));
    }
  }),
};

export default authModel;
