import React from 'react';
import { Icon } from '@chakra-ui/react';

const Progress1Icon = props => (
  <Icon viewBox="0 0 40 40" w="36px" h="36px" {...props}>
    <g fill="transparent">
      <path
        d="M21.5234 26H19.6875V18.625C19.6875 17.7448 19.7083 17.0469 19.75 16.5312C19.6302 16.6562 19.4818 16.7943 19.3047 16.9453C19.1328 17.0964 18.5495 17.5755 17.5547 18.3828L16.6328 17.2188L19.9922 14.5781H21.5234V26Z"
        fill="#EBF7F1"
      />
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
        stroke="#98D3B6"
        strokeWidth="3"
      />
      <path
        d="M20.2132 2.01537C16.6299 1.86087 13.0883 2.87388 10.0676 4.91731C7.04684 6.96073 4.69291 9.93591 3.32436 13.4402"
        stroke="#EBF7F1"
        strokeWidth="2"
      />
    </g>
  </Icon>
);

export default Progress1Icon;
