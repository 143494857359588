import { gql } from '@apollo/client';

export default gql`
  mutation resetForgotPassword($input: ResetForgotPasswordInput!) {
    resetForgotPassword(input: $input) {
      status
      errors {
        message
        path
      }
    }
  }
`;
