import React from 'react';
import { Icon } from '@chakra-ui/react';

const LockIcon = props => (
  <Icon viewBox="0 0 12 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8333 5.83333H11.6667C12.5833 5.83333 13.3333 6.58333 13.3333 7.5V15.8333C13.3333 16.75 12.5833 17.5 11.6667 17.5H1.66667C0.75 17.5 0 16.75 0 15.8333V7.5C0 6.58333 0.75 5.83333 1.66667 5.83333H2.5V4.16667C2.5 1.86667 4.36667 0 6.66667 0C8.96667 0 10.8333 1.86667 10.8333 4.16667V5.83333ZM6.66667 1.66667C5.28333 1.66667 4.16667 2.78333 4.16667 4.16667V5.83333H9.16667V4.16667C9.16667 2.78333 8.05 1.66667 6.66667 1.66667ZM1.66667 15.8333V7.5H11.6667V15.8333H1.66667ZM8.33333 11.6667C8.33333 12.5833 7.58333 13.3333 6.66667 13.3333C5.75 13.3333 5 12.5833 5 11.6667C5 10.75 5.75 10 6.66667 10C7.58333 10 8.33333 10.75 8.33333 11.6667Z"
      fill="#0D342D"
    />
  </Icon>
);

export default LockIcon;
