import { createBreakpoints } from '@chakra-ui/theme-tools';

export default createBreakpoints({
  sm: '30em',
  md: '48em',
  lg: '62em',
  xl: '80em',
  '2xl': '96em',
});
// [('40em', '52em')];
