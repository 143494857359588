import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';
import moment from 'moment';

import { Button } from 'shared/buttons';
import { DateInput } from 'shared/inputs';
import { invalidAgeMessage, isValidAdultAge } from 'auth/lib/utils';

function ReviewDOBForm() {
  const navigate = useNavigate();

  const { user } = useStoreState(state => state.auth);
  const { editUser } = useStoreActions(actions => actions.auth);

  const [dateOfBirth, setDateOfBirth] = useState(
    user?.profile?.dateOfBirth ? moment(user?.profile?.dateOfBirth) : '',
  );
  const [errors, setErrors] = useState({});

  const handleEvent = e => {
    e.preventDefault();
  };
  const validate = value => {
    if (!isValidAdultAge({ dateOfBirth: value })) {
      setErrors({ dateOfBirth: invalidAgeMessage({ dateOfBirth: value }) });
    } else {
      setErrors({});
    }
  };
  const saveInfo = () => {
    if (isValidAdultAge({ dateOfBirth }))
      editUser({
        variables: { dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD') },
        callback: () => navigate(-1),
      });
  };

  return (
    <form onSubmit={handleEvent}>
      <DateInput
        dark
        id="dob"
        label="Date of Birth"
        autoComplete="bday"
        error={errors.dateOfBirth}
        selected={dateOfBirth}
        onChange={value => {
          setDateOfBirth(value);
          validate(value);
        }}
        onSelect={validate}
        onBlur={() => validate(dateOfBirth)}
        onEnter={saveInfo}
      />
      <Box h={15} />
      <Flex justify="center">
        <Button
          light
          maxW={67}
          isDisabled={!isValidAdultAge({ dateOfBirth })}
          onClick={saveInfo}
        >
          Save changes
        </Button>
      </Flex>
    </form>
  );
}

export default ReviewDOBForm;
