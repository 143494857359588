export const CounterpartyFields = `
  id
  mask
  balances {
    availableBalance
  }
  accountType
  name
  scope
  plaidInstitutionName
  plaidVerificationStatus
  logoBase64
  brandColor
  linked
  transferrableType
`;
