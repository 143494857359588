import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

import useAnalytics from 'shared/components/useAnalytics';
import { H3 } from 'shared/typography';

const SentInvite = () => {
  useAnalytics({ name: 'waitlist-added' });

  return (
    <Flex
      direction="column"
      position="fixed"
      h="100%"
      w="100%"
      bg="blue"
      justify="center"
      align="center"
    >
      <Text fontSize="8xl">
        <span role="img" aria-label="finish flag">
          ✌🏽
        </span>
      </Text>
      <Box h={10} />
      <Text fontSize={['4xl', '8xl']}>You’re on the list!</Text>
      <Box h={10} />
      <H3 textAlign="center" maxW={70}>
        Expect an invite and access to a free onboarding session very soon.
      </H3>
      <Box h={40} />
    </Flex>
  );
};

export default SentInvite;
