import { gql } from '@apollo/client';

import { AccountFields } from 'accounts/graphql/AccountFields';

export default gql`
  query account($id: ID!, $forceRefresh: Boolean) {
    account(id: $id, forceRefresh: $forceRefresh) {
      ${AccountFields}
    }
  }
`;
