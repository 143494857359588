const omitTypename = (key, value) => (key === '__typename' ? undefined : value);

const parseErrors = errors => {
  return (errors || []).reduce((obj, { message, path }) => {
    const field = path[1];
    // eslint-disable-next-line no-param-reassign
    obj[field] = message;
    return obj;
  }, {});
};

export const parseMutationErrors = resp => {
  return Object.keys(resp.data || {}).reduce((errors, key) => {
    const errorKey = resp.data[key];
    return { ...errors, ...parseErrors(errorKey.errors) };
  }, {});
};

export const deepOmitTypeName = data => JSON.parse(JSON.stringify(data), omitTypename);
