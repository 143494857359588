import React from 'react';
import { Icon } from '@chakra-ui/react';

const InvalidIcon = props => (
  <Icon viewBox="0 0 18 18" {...props}>
    <g fill="transparent">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18Z"
        fill="#EE5C3D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8464 5.35355C12.0417 5.15829 12.3583 5.15829 12.5536 5.35355L12.6464 5.44645C12.8417 5.64171 12.8417 5.95829 12.6464 6.15355L6.15355 12.6464C5.95829 12.8417 5.64171 12.8417 5.44645 12.6464L5.35355 12.5536C5.15829 12.3583 5.15829 12.0417 5.35355 11.8464L11.8464 5.35355Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.15355 5.35355C5.95829 5.15829 5.64171 5.15829 5.44645 5.35355L5.35355 5.44645C5.15829 5.64171 5.15829 5.95829 5.35355 6.15355L11.8464 12.6464C12.0417 12.8417 12.3583 12.8417 12.5536 12.6464L12.6464 12.5536C12.8417 12.3583 12.8417 12.0417 12.6464 11.8464L6.15355 5.35355Z"
        fill="white"
      />
    </g>
  </Icon>
);

export default InvalidIcon;
