import React from 'react';
import { PropTypes } from 'prop-types';
import DatePicker from 'react-datepicker';
import { Input, FormLabel, FormControl } from '@chakra-ui/react';

import { Paragraph } from 'shared/typography';

const DateInput = ({
  id,
  label,
  autoComplete,
  error,
  dark,
  cWidth,
  onEnter,
  ...rest
}) => {
  const handleEnter = event => {
    event.stopPropagation();
    event.preventDefault();
    if (event.key === 'Enter') {
      onEnter && onEnter();
    }
  };

  return (
    <FormControl w={cWidth}>
      <FormLabel
        htmlFor={id}
        fontSize="sm"
        fontWeight="semibold"
        color={dark ? 'greenLight' : 'gray'}
        pb={0}
      >
        {label}
      </FormLabel>
      <DatePicker
        preventOpenOnFocus
        onKeyUp={handleEnter}
        placeholderText="mm/dd/yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        customInput={
          <Input
            borderColor={error ? 'danger' : 'boulderWTrans'}
            borderBottom="1px solid"
            variant="flushed"
            color={dark ? 'iceberg' : 'black'}
            autoComplete="off"
            w={67}
            _placeholder={dark ? placeholderDark : placeholderColor}
          />
        }
        {...rest}
      />
      {error && <Paragraph color="danger">{error}</Paragraph>}
    </FormControl>
  );
};

const placeholderDark = { color: 'icebergWTrans' };
const placeholderColor = { color: 'gray' };

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  dark: PropTypes.bool,
  cWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onEnter: PropTypes.func,
  error: PropTypes.string,
};
DateInput.defaultProps = {
  error: '',
  autoComplete: 'off',
  onEnter: null,
  cWidth: '100%',
  dark: false,
};

export default DateInput;
