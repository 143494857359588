import React from 'react';
import { PropTypes } from 'prop-types';
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';

import { useBreakpoint } from 'shared/components/breakpoint';
import ZetaLogoLight from 'shared/svg/ZetaLogoLight';
import ZetaLogoDark from 'shared/svg/ZetaLogoDark';

const Layout = ({ children, dark, ...rest }) => {
  const bp = useBreakpoint();

  return (
    <Box
      bg={dark ? 'primary' : 'white'}
      py={[18, 18, 32]}
      px={[10, 18, 40]}
      width="100%"
      h="100vh"
      overflow="auto"
      zIndex="2"
      {...rest}
    >
      <Box
        maxWidth={['lg', 'xl', '3xl']}
        minWidth={['xs', 'lg', 'lg']}
        position="relative"
      >
        <motion.div initial={initial} animate={animate} transition={transition}>
          {!bp.large && (dark ? <ZetaLogoLight /> : <ZetaLogoDark />)}
          {!bp.large && <Box h={15} />}
          {children}
        </motion.div>
      </Box>
    </Box>
  );
};

const initial = { opacity: 0 };
const animate = { opacity: 1 };
const transition = { duration: 1 };

Layout.propTypes = { children: PropTypes.node.isRequired, dark: PropTypes.bool };
Layout.defaultProps = { dark: false };

export default Layout;
