import { gql } from '@apollo/client';

export default gql`
  mutation editInvitation($input: EditInvitationInput!) {
    editInvitation(input: $input) {
      invitation {
        id
        email
        phoneNumber
      }
      errors {
        message
        path
      }
    }
  }
`;
