import React from 'react';
import { PropTypes } from 'prop-types';

import { Text } from '@chakra-ui/react';

const H5 = ({ children, as, fontSize, lineHeight, fontWeight, ...rest }) => {
  return (
    <Text
      my={0}
      as={as}
      fontSize={['sm', 'md']} // 16px
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      {...rest}
    >
      {children}
    </Text>
  );
};

H5.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
};
H5.defaultProps = {
  as: 'h5',
  fontSize: 'md', // 16px
  lineHeight: '5',
  fontWeight: 'semibold',
};

export default H5;
