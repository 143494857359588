import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import Step from 'auth/components/Step';
import { Progress1Icon } from 'auth/icons';
import { FAB } from 'shared/buttons';
import { useBreakpoint } from 'shared/components/breakpoint';
import useAnalytics from 'shared/components/useAnalytics';
import { H1, H3 } from 'shared/typography';

const PartnerAccountSecured = () => {
  const bp = useBreakpoint();
  const navigate = useNavigate();
  useAnalytics({ name: 'account-secured-partner' });

  const { user, partnerName } = useStoreState(state => state.auth);
  const { getUser } = useStoreActions(actions => actions.auth);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const moveForward = () => {
    user?.profile?.dateOfBirth
      ? navigate('/auth/invited/personal/address')
      : navigate('/auth/invited/personal/dob');
  };

  return (
    <Layout dark height="100vh">
      {!bp.large && <Progress1Icon position="absolute" right={0} top={3} />}
      <Flex justify="space-between" align="center">
        <H1 color="shared">You’ve secured your account.</H1>
        {bp.large && <Progress1Icon />}
      </Flex>

      <Box h={10} />
      <H3 color="iceberg">It takes less than 10 minutes to get setup with your cards.</H3>
      <Box h={15} />
      <Step checked>{`${partnerName}'s information`}</Step>
      <Box h={10} />
      <Step checked>Claim your invite</Step>
      <Box h={10} />
      <Step checked>Secure your account</Step>
      <Box h={10} />
      <Step>Confirm your information</Step>
      <Box h={10} />
      <Step>Submit your application</Step>
      <Box h={10} />
      <Step>Add funds</Step>
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB onClick={moveForward} autoFocus />
        </Flex>
      </Box>
      <Box h={10} />
    </Layout>
  );
};

export default PartnerAccountSecured;
