import { gql } from '@apollo/client';

export default gql`
  mutation createInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      invitation {
        id
        email
        phoneNumber
      }
      pendingInvitationToken
      errors {
        message
        path
      }
    }
  }
`;
