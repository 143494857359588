import { gql } from '@apollo/client';

import { AccountFields } from 'accounts/graphql/AccountFields';

export default gql`
  subscription account($accountId: ID!) {
    account(accountId: $accountId) {
      ${AccountFields}
    }
  }
`;
