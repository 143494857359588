import React from 'react';
import { PropTypes } from 'prop-types';
import { Link, Text } from '@chakra-ui/react';

const ExternalLink = ({
  children,
  href,
  fontSize,
  lineHeight,
  fontWeight,
  color,
  ...rest
}) => {
  return (
    <Link href={href} isExternal>
      <Text
        as="span"
        my={0}
        fontSize={fontSize}
        lineHeight={lineHeight}
        fontWeight={fontWeight}
        color={color}
        textDecoration="underline"
        {...rest}
      >
        {children}
      </Text>
    </Link>
  );
};

ExternalLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};
ExternalLink.defaultProps = {
  href: '',
  fontSize: 'xxs', // 10px
  lineHeight: '4',
  fontWeight: 'normal',
  color: 'greenPea',
};

export default ExternalLink;
