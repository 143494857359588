import React from 'react';
import { PropTypes } from 'prop-types';
import { ControlBox, Flex, VisuallyHidden } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const Checkbox = ({ children, id, borderColor, color, value, onChange }) => {
  const handleClick = e => onChange && onChange(e.target.checked);

  return (
    <label htmlFor={id}>
      <Flex>
        <VisuallyHidden
          name={id}
          id={id}
          type="checkbox"
          as="input"
          onChange={handleClick}
          checked={value}
        />

        <ControlBox
          w="18px"
          h="18px"
          bg="transparent"
          border="2px"
          rounded="full"
          type="checkbox"
          opacity="0.5"
          mr={['1.25rem', '0.65rem']}
          borderColor={borderColor}
          _focus={{ boxShadow: 'none' }}
          _disabled={{ opacity: '40%' }}
          _checked={{ bg: color, borderColor: color, opacity: 1 }}
        >
          <CheckIcon w="12px" h="12px" color="white" />
        </ControlBox>
        {children}
      </Flex>
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = { borderColor: 'greenPea', color: 'shared' };

export default Checkbox;
