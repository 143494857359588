import React from 'react';
import { PropTypes } from 'prop-types';
import { Box } from '@chakra-ui/react';

import { H3 } from 'shared/typography';

const ErrorBox = ({ children }) => {
  return (
    <Box bg="lightDanger" borderRadius="md" width="fit-content" p={2}>
      <H3 textAlign="center" color="danger">
        {children}
      </H3>
    </Box>
  );
};

ErrorBox.propTypes = { children: PropTypes.node.isRequired };

export default ErrorBox;
