import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import { FAB } from 'shared/buttons';
import { TextInput } from 'shared/inputs';
import { validEmail } from 'shared/lib/inputHelpers';
import { H1, H3, H5 } from 'shared/typography';

const ForgotPasswordForm = () => {
  const navigate = useNavigate();

  const { sendPasswordReset, setLoginEmail } = useStoreActions(actions => actions.auth);
  const { loginEmail } = useStoreState(state => state.auth);

  const [email, setEmail] = useState(loginEmail);
  const [submitting, setSubmitting] = useState(false);

  const onChangeEmail = value => {
    setEmail(value.toLowerCase());
  };

  const onSubmit = () => {
    if (validEmail(email)) {
      setSubmitting(true);
      setLoginEmail(email.toLowerCase());
      sendPasswordReset({
        variables: { email },
        callback: () => {
          setSubmitting(false);
          navigate(`confirm-forgot-password?email=${encodeURIComponent(email)}`);
        },
      });
    }
  };
  return (
    <>
      <H1>Forgot your password?</H1>
      <Box h={10} />
      <H3>Enter your email below and we'll send you reset instructions.</H3>
      <Box h={15} />
      <TextInput
        id="userEmail"
        type="email"
        label="Email"
        autoComplete="username"
        value={email}
        onChange={onChangeEmail}
        onEnter={onSubmit}
      />
      <Box h={26} />
      <Box position="absolute" right={0} top={[80, 78]}>
        <Flex justify="flex-end">
          <FAB
            isLoading={submitting}
            isDisabled={!validEmail(email)}
            onClick={onSubmit}
          />
        </Flex>
        <Box h={10} />
        <Flex justify="flex-end">
          <H5 as={Link} to="/auth/login" color="iceberg">
            I want to login instead
          </H5>
        </Flex>
      </Box>
    </>
  );
};

export default ForgotPasswordForm;
