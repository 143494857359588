import { gql } from '@apollo/client';

import { userFields } from 'auth/graphql/UserFields';

export default gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      newUser
      pendingInvitationToken
      pendingInvitationType
      requires2fa
      loginUnlocksAt
      user {
        ${userFields}
      }
      errors {
        message
        path
      }
    }
  }
`;
