import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import querystring from 'query-string';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Box, Flex } from '@chakra-ui/react';

import Layout from 'auth/components/Layout';
import Step from 'auth/components/Step';
import { Progress1Icon } from 'auth/icons';
import { FAB } from 'shared/buttons';
import { useBreakpoint } from 'shared/components/breakpoint';
import useAnalytics from 'shared/components/useAnalytics';
import isPresent from 'shared/lib/isPresent';
import { H1, H3, H5 } from 'shared/typography';

const PartnerIntro = () => {
  const bp = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();
  useAnalytics({ name: 'welcome-to-zeta-partner' });

  const { invite } = useStoreState(state => state.auth);
  const { getInvite, setToken } = useStoreActions(actions => actions.auth);

  useEffect(() => {
    const { claimtoken } = querystring.parse(location.search ?? '');
    if (!isPresent(invite) && claimtoken) {
      setToken(claimtoken);
      getInvite();
    }
  }, [invite, location, setToken, getInvite]);

  const saveInfo = () => {
    const { claimtoken } = querystring.parse(location.search ?? '');
    navigate(`/auth/invited/welcome/confirm-invite?claimToken=${claimtoken}`);
  };

  return (
    <Layout dark height="100vh">
      {!bp.large && <Progress1Icon position="absolute" right={0} top={3} />}
      <Flex justify="space-between" align="center">
        <H1 color="shared">Welcome to Zeta.</H1>
        {bp.large && <Progress1Icon />}
      </Flex>

      <Box h={10} />
      <H3 color="iceberg">It takes less than 10 minutes to get setup with your cards.</H3>
      <Box h={15} />
      {/* {checkingInvite && ( */}
      <>
        <Step checked>{`${invite?.senderFirstName}'s information`}</Step>
        <Box h={10} />
        <Step checked>Claim your invite</Step>
        <Box h={10} />
        <Step>Secure your account</Step>
        <Box h={10} />
        <Step>Confirm your information</Step>
        <Box h={10} />
        <Step>Submit your application</Step>
        <Box h={10} />
        <Step>Add funds</Step>
        <Box h={26} />
        <Box position="absolute" right={0} top={[80, 78]}>
          <Flex justify="flex-end">
            <FAB onClick={saveInfo} autoFocus />
          </Flex>
          <Box h={10} />
          <Flex justify="flex-end">
            <H5 as={Link} to="/home" color="iceberg">
              Already have an account? Login
            </H5>
          </Flex>
        </Box>
      </>
      {/* )} */}
    </Layout>
  );
};

export default PartnerIntro;
