import React from 'react';
import { PropTypes } from 'prop-types';

import { formatToPhone } from 'shared/lib/inputHelpers';
import TextInput from 'shared/inputs/TextInput';

const PhoneInput = ({ value, onChange, ...rest }) => {
  const handleOnChange = number => onChange(formatToPhone(number));

  return (
    <TextInput
      type="tel"
      autoComplete="tel-national"
      value={formatToPhone(value)}
      onChange={handleOnChange}
      {...rest}
    />
  );
};

PhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PhoneInput;
