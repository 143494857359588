import React from 'react';
import { PropTypes } from 'prop-types';

import { Text } from '@chakra-ui/react';

const Paragraph = ({
  children,
  as,
  fontSize,
  lineHeight,
  fontWeight,
  color,
  ...rest
}) => {
  return (
    <Text
      my={0}
      as={as}
      fontSize={fontSize} // 14px
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={color}
      {...rest}
    >
      {children}
    </Text>
  );
};

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};
Paragraph.defaultProps = {
  as: 'p',
  fontSize: ['xs', 'sm'],
  lineHeight: '5',
  fontWeight: 'normal',
  color: 'black',
};

export default Paragraph;
