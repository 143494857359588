import React from 'react';
import { PropTypes } from 'prop-types';

import { Text } from '@chakra-ui/react';

const H3 = ({ children, as, fontSize, lineHeight, fontWeight, ...rest }) => {
  return (
    <Text
      my={0}
      as={as}
      fontSize={['md', 'lg']} // 18px
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      {...rest}
    >
      {children}
    </Text>
  );
};

H3.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
};
H3.defaultProps = {
  as: 'h3',
  fontSize: 'lg', // 18px
  lineHeight: '6',
  fontWeight: 'normal',
};

export default H3;
