import React from 'react';
import { PropTypes } from 'prop-types';

import { Text } from '@chakra-ui/react';

const Label = ({ children, as, color, fontSize, lineHeight, fontWeight, ...rest }) => {
  return (
    <Text
      my={0}
      as={as}
      fontSize={['md', 'lg']} // 18px
      lineHeight={lineHeight}
      fontWeight={fontWeight}
      color={color}
      {...rest}
    >
      {children}
    </Text>
  );
};

Label.propTypes = {
  children: PropTypes.node.isRequired,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  fontWeight: PropTypes.string,
  color: PropTypes.string,
};
Label.defaultProps = {
  as: 'h2',
  fontSize: 'lg', // 18px
  lineHeight: '6',
  fontWeight: 'semibold',
  color: 'greenPea',
};

export default Label;
