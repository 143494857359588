import gql from 'graphql-tag';

export default gql`
  query accountApplication($id: ID!) {
    accountApplication(id: $id) {
      id
      status
    }
  }
`;
