import React from 'react';
import { Icon } from '@chakra-ui/react';

const Progress4Icon = props => (
  <Icon viewBox="0 0 40 40" w="36px" h="36px" {...props}>
    <g fill="transparent">
      <path
        d="M24.2812 23.5078H22.7422V26H20.9531V23.5078H15.7344V22.0938L20.9531 14.5469H22.7422V21.9844H24.2812V23.5078ZM20.9531 21.9844V19.1172C20.9531 18.0964 20.9792 17.2604 21.0312 16.6094H20.9688C20.8229 16.9531 20.5938 17.3698 20.2812 17.8594L17.4453 21.9844H20.9531Z"
        fill="#EBF7F1"
      />
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
        stroke="#98D3B6"
        strokeWidth="3"
      />
      <path
        d="M20.7336 2.01496C16.9462 1.86048 13.2067 2.90559 10.0482 5.00131C6.88965 7.09702 4.47335 10.1364 3.14372 13.686C1.8141 17.2357 1.63901 21.1146 2.64342 24.7696C3.64784 28.4247 5.78048 31.6693 8.73734 34.0411C11.6942 36.4129 15.3243 37.7906 19.1102 37.978C22.8961 38.1654 26.6446 37.1528 29.8212 35.0846C32.9978 33.0164 35.4404 29.9982 36.8008 26.4602C38.1612 22.9221 38.37 19.045 37.3974 15.3813"
        stroke="#EBF7F1"
        strokeWidth="2"
      />
    </g>
  </Icon>
);

export default Progress4Icon;
