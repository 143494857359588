import React from 'react';
import { Icon } from '@chakra-ui/react';

const Progress2Icon = props => (
  <Icon viewBox="0 0 40 40" w="36px" h="36px" {...props}>
    <g fill="transparent">
      <path
        d="M23.875 26H16.1328V24.6094L19.0781 21.6484C19.9479 20.7578 20.5234 20.1276 20.8047 19.7578C21.0911 19.3828 21.2995 19.0312 21.4297 18.7031C21.5599 18.375 21.625 18.0234 21.625 17.6484C21.625 17.1328 21.4688 16.7266 21.1562 16.4297C20.849 16.1328 20.4219 15.9844 19.875 15.9844C19.4375 15.9844 19.013 16.0651 18.6016 16.2266C18.1953 16.388 17.724 16.6797 17.1875 17.1016L16.1953 15.8906C16.8307 15.3542 17.4479 14.974 18.0469 14.75C18.6458 14.526 19.2839 14.4141 19.9609 14.4141C21.0234 14.4141 21.875 14.6927 22.5156 15.25C23.1562 15.8021 23.4766 16.5469 23.4766 17.4844C23.4766 18 23.3828 18.4896 23.1953 18.9531C23.013 19.4167 22.7292 19.8958 22.3438 20.3906C21.9635 20.8802 21.3281 21.5443 20.4375 22.3828L18.4531 24.3047V24.3828H23.875V26Z"
        fill="#EBF7F1"
      />
      <path
        opacity="0.25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z"
        stroke="#98D3B6"
        strokeWidth="3"
      />
      <path
        d="M20.2132 2.01496C16.7549 1.86987 13.3324 2.78285 10.3786 4.63844C7.42475 6.49403 5.07221 9.20889 3.61846 12.4397C2.16471 15.6705 1.67504 19.2722 2.21138 22.7892C2.74772 26.3063 4.28597 29.5808 6.63162 32.1986"
        stroke="#EBF7F1"
        strokeWidth="2"
      />
    </g>
  </Icon>
);

export default Progress2Icon;
