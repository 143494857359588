import React from 'react';
import { PropTypes } from 'prop-types';
import { FormLabel, FormControl, Input } from '@chakra-ui/react';

import { Paragraph } from 'shared/typography';

const TextInput = ({
  id,
  label,
  error,
  dark,
  cWidth,
  onChange,
  onEnter,
  maxLength,
  ...rest
}) => {
  const handleChange = event => {
    event.stopPropagation();
    event.preventDefault();
    if (maxLength && event.target.value.length > maxLength) {
      onChange(event.target.value.slice(0, maxLength));
    } else {
      onChange(event.target.value);
    }
  };
  const handleEnter = event => {
    event.stopPropagation();
    event.preventDefault();
    if (event.key === 'Enter') {
      onEnter && onEnter();
    }
  };

  return (
    <FormControl w={cWidth}>
      <FormLabel
        htmlFor={id}
        fontSize="sm"
        fontWeight="semibold"
        color={dark ? 'greenLight' : 'gray'}
        pb={0}
      >
        {label}
      </FormLabel>
      <Input
        id={id}
        name={id}
        borderColor={error ? 'danger' : 'boulderWTrans'}
        borderBottom="1px solid"
        variant="flushed"
        color={dark ? 'iceberg' : 'black'}
        w={67}
        onChange={handleChange}
        onKeyUp={handleEnter}
        _placeholder={dark ? placeholderDark : placeholderColor}
        {...rest}
      />
      {error && <Paragraph color="danger">{error}</Paragraph>}
    </FormControl>
  );
};

const placeholderDark = { color: 'icebergWTrans' };
const placeholderColor = { color: 'gray' };

TextInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  cWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  error: PropTypes.string,
  maxLength: PropTypes.number,
};
TextInput.defaultProps = {
  error: '',
  onEnter: null,
  cWidth: '100%',
  dark: false,
  maxLength: null,
};

export default TextInput;
